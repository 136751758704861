<template>
  <v-container class="table-template-wrap">
    <v-row class="table-container">
      <v-col align="center">
        <div v-if="tableName" class="table-title">{{ $t(tableName) }}</div>
        <v-data-table
          :headers="headers"
          :items="tableData"
          class="elevation-1"
          hide-default-footer
        >
          <template #[`item.action`]="{ item }">
            <v-icon small @click="handleAction(item)">
              fa-edit
            </v-icon>
          </template>
        </v-data-table>
        <add-item-btn :onClick="handleAdd" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AddItemBtn from '../table/add-item-btn'

export default {
  name: 'ActionTable',
  components: {
    AddItemBtn
  },
  props: {
    headers: Array,
    tableData: Array,
    tableName: String,
    handleAction: Function,
    handleAdd: Function
  }
}
</script>
