<template>
  <v-dialog class="lighten-5" id="container" v-model="dialog" max-width="600">
    <v-card class="px-8 py-4">
      <v-btn class="modal-close-btn icon-with-hover" icon @click="closeModal">
        <v-icon>fa-times</v-icon>
      </v-btn>
      <div align="center" class="form-container ma-0">
        <v-row>
          <v-col align="center">
            <v-text-field
              autofocus v-model="searchTerm" class="mx-md-4 search" solo hide-details="auto" append-icon="fa-search"
              height="38px" :label="$t('Search')" :placeholder="$t('Search') " @click:append="searchData" @keyup.enter="searchData"
            />
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'SearchModal',
  props: {
    title: {},
    text: {},
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: false,
      searchTerm: '',
    }
  },
  methods: {
    openModal() {
      this.dialog = true
      this.searchTerm = '';
    },
    closeModal() {
      this.dialog = false
      this.searchTerm = '';
    },
    searchData() {
      if (this.searchTerm.trim() !== '' && (!this.$route.query.q || this.$route.query.q !== this.searchTerm.trim().toLowerCase())) {
        this.$router.push({ name: 'search', query: { q: this.searchTerm.trim().toLowerCase() } });
      }
      this.closeModal();
    }
  }
}
</script>

<style scoped lang="scss">
.btn {
  width: 140px;
}
</style>
