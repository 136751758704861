import de from '@/locales/de.json'
import en from '@/locales/en.json'
import override from '@/locales-customer/translations-customer.json'
import _ from 'lodash';
import { i18n } from '@/main';

/**
 * get a formatted date/time string by language. If the translation doesn't exist, return the value of the default language
 * @param {any} obj - The object to translate, i.e. 'article'
 * @param {string} field - The field to translate, i.e. 'name'
 * @param {string} lang - The currently active language in 2-digit code i.e. 'en'
 * @return {<any>} - Translated text
 */
export const getTranslatedText = (obj: any, field: string, lang: string) => {
  let langTarget
  if (typeof obj[`${field}_i18n`] === 'string' && obj[`${field}_i18n`].length > 0) {
    langTarget = JSON.parse(obj[`${field}_i18n`])
  } else {
    langTarget = obj[`${field}_i18n`]
  }
  if (langTarget && Object.keys(langTarget).length > 0 && langTarget[lang]) {
    return langTarget[lang]
  }
  return obj[field]
}

export const getTranslatedValueName = (obj: any, field: string, lang: string, languages: any) => {
  let langTarget
  if (typeof obj[`${field}_i18n`] === 'string' && obj[`${field}_i18n`].length > 0) {
    langTarget = JSON.parse(obj[`${field}_i18n`])
  } else {
    langTarget = obj[`${field}_i18n`]
  }
  if (langTarget && Object.keys(langTarget).length > 0 && langTarget[lang]) {
    return langTarget
  }
  return field
}

/**
 * Format a given string (price).
 * @param {string} value - The input string
 * @param {string} ratio - The ratio
 * @return {string} - The converted string
 */
export function convertedValue(value: string, ratio: string): string {
  let parseNumber = +value * +ratio
  let forceFixed = parseNumber.toLocaleString('en-US', { minimumFractionDigits: 2 })
  return forceFixed.replace(/[,.]/g, function (x) { return x === ',' ? '.' : ','; })
}

/**
 * Merge the builtin translations with the customer overrides
 * @return {Object} - The merged translations
 */
export function mergeTranslations(): any {
  const base = {
    de,
    en
  }

  let result = {};

  const languages = Object.keys(base);
  for (const language of languages) {
    result[language] = _.merge(base[language], override[language])
  }

  for (const overrideLanguage of Object.keys(override)) {
    if (base[overrideLanguage] === undefined) {
      result[overrideLanguage] = override[overrideLanguage];
    }
  }
  return result;
}

/**
 * translate a validation message if needed (if its a validation error)
 * @param {string} message - The message
 * @return {string} - The translated message
 */
export function translateValidationIfNeeded(message: string): string {
  if (message.startsWith('validation.')) {
    return i18n.t(message) as string;
  } else {
    return message;
  }
}

/**
 * get a formatted date and time string by language
 * @param {any} date - The date string
 * @param {string} lang - The currently active language in 2-digit code i.e. 'en'
 * @param {boolean} showSeconds - Flag to control if the seconds should be returned as well
 * @param {boolean} onlyDate - Flag to control if only the date should be returned
 * @return {<any>} - Formatted date string
 */
export const getFormattedDate = (date: any, lang: string, showSeconds: boolean, onlyDate: boolean) => {
  if (!date) return
  const d = new Date(date);
  const ye = d.getFullYear()
  const mo = d.toLocaleString(lang || 'de', { month: 'long', timeZone: 'UTC' });
  const da = d.getDate()
  const h = d.getHours()
  const m = d.getMinutes()
  const dayPrefix = da === 1 ? 'st' : da === 2 ? 'nd' : da === 3 ? 'rd' : 'th'
  if (showSeconds) {
    const s = d.getSeconds()
    return lang === 'en' ? `${mo} ${da}${dayPrefix}, ${ye} ${h}:${m <= 9 ? '0' + m : m}:${s <= 9 ? '0' + s : s}` : `${da}. ${mo}, ${ye} ${h}:${m <= 9 ? '0' + m : m}:${s <= 9 ? '0' + s : s}`
  }
  if (onlyDate) {
    const s = d.getSeconds()
    return lang === 'en' ? `${mo} ${da}${dayPrefix}, ${ye}` : `${da}. ${mo}, ${ye}`
  }
  return lang === 'en' ? `${mo} ${da}${dayPrefix}, ${ye} ${h}:${m <= 9 ? '0' + m : m}` : `${da}. ${mo}, ${ye} ${h}:${m <= 9 ? '0' + m : m}`
}

/**
 * Format a given string (price).
 * @param {any} nbr - Number to convert
 * @return {string} - The formatted string
 */
export function euNumbers(nbr: any): string {
  let parseNumber = parseFloat(nbr)
  let forceFixed = parseNumber.toLocaleString('en-US', { minimumFractionDigits: 0 })
  let euNumber = forceFixed.replace(/[,.]/g, function (x) { return x === ',' ? '.' : ','; })
  return euNumber
}
