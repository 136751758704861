<template>
  <div :key="this.disconnectedTime" class="app-status">
    <div v-if="socketConnectionStatus === SocketConnectionStatusText.connected && !isOffline" class="socket-label socket-connected">
      {{socketConnectionStatus}}
    </div>
    <div v-else class="socket-label socket-not-connected">
      {{disconnectedTime}} {{socketConnectionStatus}}
    </div>
  </div>
</template>

<script>

import { mapState } from 'pinia';
import { SocketConnectionStatus as SocketConnectionStatusEnum } from '@/utils/constants';
import useRootStore from '@/stores/rootStore';

export default {
  name: 'SocketStatusBar',
  data: () => ({
    connectedTime: 0,
    connectionTimer: null,
    disconnectionTimer: null,
  }),
  computed: {
    ...mapState(useRootStore, ['socketConnectionStatus', 'disconnectedTime', 'isOffline']),
    SocketConnectionStatusText() {
      return SocketConnectionStatusEnum
    }
  },
  watch: {
    async isOffline(newVal, oldVal) {
      const rootStore = useRootStore();
      if (!oldVal && newVal) {
        this.connectedTime = 0
        clearTimeout(this.connectionTimer)
        this.disconnectedTimer()
      } else {
        this.connectedTime = 0
        rootStore.SET_DISCONNECTED_TIME(0)
        clearTimeout(this.connectionTimer)
        clearTimeout(this.disconnectionTimer)
        this.connectedTimer()
      }
    },
  },
  methods: {
    connectedTimer() {
      this.connectionTimer = setTimeout(() => {
        this.connectedTime += 1
        this.connectedTimer()
      }, 1000)
    },
    disconnectedTimer() {
      const rootStore = useRootStore();
      this.disconnectionTimer = setTimeout(() => {
        rootStore.SET_DISCONNECTED_TIME(rootStore.disconnectedTime + 1)
        this.disconnectedTimer()
      }, 1000)
    }
  }
}
</script>
<style lang="scss" scoped>
    .app-status {
        position: relative;
        right: 0;
        z-index: 100;
        font-size: 15px;
    }
    .socket-label {
        display:inline-block;
        padding:8px 15px;
        color:white;
        border-radius: 20px;
    }
    .socket-connected {
        background-color:green;
    }
    .socket-not-connected {
        background-color:red;
    }
</style>
