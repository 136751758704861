<template>
  <v-dialog
    v-model="dialog"
    content-class="users-modal"
    @keydown.esc="closeModal"
    persistent
  >
    <v-card class="pl-6 pr-12">
      <v-btn class="modal-close-btn icon-with-hover" icon @click="closeModal"><v-icon>fa-times</v-icon></v-btn>
      <v-btn class="modal-sync-btn icon-with-hover" icon @click="syncData" :disabled="loading">
        <v-icon class="close-modal-icon">fa-sync</v-icon>
      </v-btn>
      <v-btn
        class="modal-save-btn px-0"
        :disabled="!dataIsChanged || !validation"
        @click="saveData"
        :loading="loading || sendLoading"
      >
        <v-icon>fa-save</v-icon>
      </v-btn>
      <h2 class="pt-2 text-center">{{ modalTitle }}</h2>
      <v-text-field
        v-model="search"
        append-icon="fa-search"
        :label="$t('Search')"
        hide-details
        clearable
      />
      <v-progress-circular v-if="loading" class="loading-icon mx-auto d-block mt-4" indeterminate />
      <v-form ref="form" v-model="isFormValid" v-else>
        <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page="itemPerPage"
          must-sort
          sort-by="user_id"
          class="users-table"
          :search="search"
          :key="tableKey"
          dense
          :footer-props="{
            'items-per-page-all-text': $t('All'),
            'items-per-page-text': $t('Rows per page'),
            'page-text': `{0} - {1} ${$t('of')} {2} ${$t('items')}`,
            'items-per-page-options': []
          }"
        >
          <template #no-results>
            <span>{{ $t('No data available') }}</span>
          </template>
          <template #[`item.id`]="{ item }">
            <span class="text-light d-flex align-center" @click="clickUser(item)">
              {{ item.id }}
              <v-btn class="ml-1" small icon><v-icon x-small>fa-external-link-alt</v-icon></v-btn>
            </span>
          </template>
          <template #[`item.bidder_number`]="{ item }">
            <v-text-field
              class="input-field mt-0 pt-0 mb-0"
              type="number"
              :rules="[validationRequired(), validationPositiveNumber()]"
              v-model="item.userToAuctionUser[0].bidder_number"
              hide-details
              @input="bidderNumberHandler(item)"
              @keydown="inputNumberHandler"
            >
              <template #message="{ message }">
                {{ $t(message) }}
              </template>
            </v-text-field>
          </template>
          <template #[`item.require_bid_limit_on_signup`]="{ item }">
            <span class="text-light" v-if="require_bid_limit_on_signup && item.userToAuctionUser[0].user_bid_limit">{{ euNumbers(item.userToAuctionUser[0].user_bid_limit) }}</span>
          </template>
          <template #[`item.bid_limit`]="{ item }">
            <v-text-field
              class="input-field mt-0 pt-0 mb-0"
              type="number"
              :rules="[validationPositiveNumber()]"
              v-model="item.userToAuctionUser[0].bid_limit"
              hide-details
              @input="bidLimitHandler(item)"
              @keydown="inputNumberHandler"
            >
              <template #message="{ message }">
                {{ $t(message) }}
              </template>
            </v-text-field>
          </template>
          <template #[`item.user_status`]="{ item }">
            <v-select
              class="input-field mt-0 pt-0 mb-0"
              :rules="[e => rules.requiredIfFilledLimit(e, item)]"
              :items="userStatusItems"
              hide-details
              v-model="item.userToAuctionUser[0].user_status"
              @input="statusHandler"
            >
              <template #message="{ message }">
                {{ $t(message) }}
              </template>
            </v-select>
          </template>
        </v-data-table>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import apiRequests from '@/api/apiRequests'
import {mapActions, mapState} from 'pinia'
import frontendValidations from '@/utils/rules';
import _ from 'lodash'
import useRootStore from '@/stores/rootStore';
import { euNumbers } from '@/services/i18n';
import dispatcher from '@/api/dispatch';

export default {
  name: 'ViewUsersModal',
  data() {
    return {
      dialog: false,
      itemPerPage: 20,
      start: 0,
      end: 0,
      page: 0,
      items: [],
      state: null,
      search: '',
      rules: {
        requiredIfFilledLimit: (value, user) => user.userToAuctionUser[0].bid_limit ? value !== 'pending' || this.$t('Change status') : true
      },
      auctionID: null,
      require_bid_limit_on_signup: false,
      loading: false,
      tableKey: 0,
      sendLoading: false,
      validation: false,
      usersPresaved: [],
      userStatusItems: [
        {
          text: this.$t('pending'),
          value: 'pending',
        },
        {
          text: this.$t('locked'),
          value: 'locked',
        },
        {
          text: this.$t('unlocked'),
          value: 'unlocked'
        }
      ],
      isFormValid: false
    }
  },
  computed: {
    ...mapState(useRootStore, ['userProfile']),
    modalTitle() {
      return this.state === 'pending' ? this.$t('Pending registrations') : this.state === 'unlocked' ? this.$t('Accepted registrations') : this.$t('Declined registrations')
    },
    headers() {
      const headers = [
        {
          text: this.$t('ID'),
          value: 'id',
          sortable: true,
          filterable: false,
          width: 90
        },
        {
          text: this.$t('Customer number'),
          value: 'customer_number',
          sortable: true,
          width: 170
        },
        {
          text: this.$t('Bidder number'),
          value: 'bidder_number',
          sortable: true,
          width: 150
        },
        {
          text: this.$t('Bid limit'),
          value: 'bid_limit',
          sortable: true,
          width: 170
        },
        {
          text: this.$t('User Status'),
          value: 'user_status',
          sortable: true,
          width: 150
        },
        {
          text: this.$t('First name'),
          value: 'first_name',
          sortable: true,
          width: 120
        },
        {
          text: this.$t('Last Name'),
          value: 'last_name',
          sortable: true,
          width: 125
        },
        {
          text: this.$t('Email'),
          value: 'email',
          sortable: true
        },
      ]
      if (this.require_bid_limit_on_signup) {
        headers.splice(3, 0, {
          text: this.$t('Requested bid limit'),
          value: 'require_bid_limit_on_signup',
          sortable: true,
          width: 180
        })
      }
      return headers
    },
    dataIsChanged() {
      return !!_.differenceWith(this.usersPresaved, this.items, _.isEqual).length
    }
  },
  methods: {
    ...mapActions(useRootStore, ['SET_TEMP_ALERT']),
    validationRequired: frontendValidations.required,
    validationPositiveNumber: frontendValidations.positiveNumber,
    euNumbers,
    openModal(id, requireBidLimitOnSignup, state) {
      this.dialog = true
      this.auctionID = id
      this.state = state
      this.page = 0
      this.require_bid_limit_on_signup = requireBidLimitOnSignup
      this.getUsersData()
    },
    closeModal() {
      this.dialog = false
      this.auctionID = null
      this.state = null
      this.require_bid_limit_on_signup = false
    },
    clickUser(user) {
      const routeData = this.$router.resolve({ name: 'AdminUserProfile', params: { id: user.id } });
      window.open(routeData.href, '_blank');
    },
    changePage (el) {
      this.page = el
    },
    async getUsersData() {
      try {
        this.loading = true
        const result = await dispatcher.getAuctionBidderNumbersWithState(this.auctionID, this.state)
        this.items = JSON.parse(JSON.stringify(result.filter(el => el.userToAuctionUser[0].type === 'live')))
        this.usersPresaved = JSON.parse(JSON.stringify(result.filter(el => el.userToAuctionUser[0].type === 'live')))
        this.loading = false
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error getting the users data') })
      }
    },
    async syncData() {
      await this.getUsersData()
    },
    async saveData() {
      try {
        this.sendLoading = true
        const allUsers = []
        this.items.forEach(user => {
          if (this.usersPresaved.find(el => el.id === user.id && (el.userToAuctionUser[0].user_status !== user.userToAuctionUser[0].user_status || el.userToAuctionUser[0].bid_limit !== user.userToAuctionUser[0].bid_limit))) {
            allUsers.push({
              id: user.userToAuctionUser[0].id,
              auction_id: user.userToAuctionUser[0].auction_id,
              type: user.userToAuctionUser[0].type,
              user_status: user.userToAuctionUser[0].user_status,
              bid_limit: user.userToAuctionUser[0].bid_limit ? +user.userToAuctionUser[0].bid_limit : null,
              bidder_number: user.userToAuctionUser[0].bidder_number ? +user.userToAuctionUser[0].bidder_number : null
            })
          }
        })
        await dispatcher.updateUserBidderNumbers({ userToAuctionUser: allUsers })
        this.$emit('updateTable')
        await this.syncData()
        this.sendLoading = false
        this.SET_TEMP_ALERT({ flavor: 'success', content: this.$t('Values changed successfully') })
      } catch (e) {
        this.sendLoading = false
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error changing values') })
      }
    },
    bidLimitHandler(item) {
      if (item.userToAuctionUser[0].bid_limit === '') {
        item.userToAuctionUser[0].bid_limit = null
      } else {
        item.userToAuctionUser[0].bid_limit = +item.userToAuctionUser[0].bid_limit
      }
      this.validation = this.$refs.form.validate()
    },
    bidderNumberHandler(item) {
      if (item.userToAuctionUser[0].bidder_number === '') {
        item.userToAuctionUser[0].bidder_number = null
      } else {
        item.userToAuctionUser[0].bidder_number = +item.userToAuctionUser[0].bidder_number
      }
      this.validation = this.$refs.form.validate()
    },
    statusHandler() {
      this.validation = this.$refs.form.validate()
    },
    inputNumberHandler (e) {
      const expr = /^[0-9]*$/
      // allow the following keys to be able to still use the application properly
      if (!expr.test(e.key) && e.key !== 'Tab' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight' && e.key !== 'Backspace' && e.key !== 'Delete') {
        e.preventDefault()
      }
    }
  }
}
</script>
