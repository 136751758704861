<template>
  <div class="multiple-dates">
    <h4>{{ $t(label) }}</h4>
    <v-btn icon @click="addDate" class="icon-with-hover">
      <v-icon>fa-plus-circle</v-icon>
    </v-btn>
    <div
      class="multiple-dates__field"
      v-for="(date, i) in dates"
      :key="i"
    >
      <vc-date-picker v-model="dates[i]" mode="dateTime" is24hr :min-date="isRange ? false : new Date()" :is-range="isRange">
        <template #default="{ inputValue, togglePopover }" v-if="isRange">
          <v-row>
            <v-col cols="6">
              <v-text-field
                :label="$t('Start date')"
                class="input-field mb-2"
                :rules="[validationRequired()]"
                :value="inputValue.start"
                @focus="togglePopover"
              >
                <template #message="{ message }">
                  {{ $t(message) }}
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                :label="$t('End date')"
                class="input-field mb-2"
                :rules="[validationRequired()]"
                :value="inputValue.end"
                @focus="togglePopover"
              >
                <template #message="{ message }">
                  {{ $t(message) }}
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </template>
        <template #default="{ inputValue, togglePopover }" v-else>
          <v-text-field
            class="input-field mb-2"
            :rules="[validationRequired()]"
            :value="inputValue"
            @focus="togglePopover"
          >
            <template #message="{ message }">
              {{ $t(message) }}
            </template>
          </v-text-field>
        </template>
      </vc-date-picker>
      <v-btn icon @click="dates.splice(i, 1)" v-if="i !== 0" class="icon-with-hover">
        <v-icon>fa-minus-circle</v-icon>
      </v-btn>
      <div style="width:36px;" v-else />
    </div>
  </div>
</template>

<script>
import frontendValidations from '@/utils/rules';

export default {
  name: 'MultipleDates',
  props: {
    value: {},
    label: String,
    isRange: Boolean
  },
  data() {
    return {
      dates: []
    }
  },
  mounted() {
    if (this.isRange) {
      this.dates = this.value && this.value.dates && this.value.dates.length ? this.value.dates.map(el => { return { start: el.starts_at, end: el.ends_at } }) : []
    } else {
      this.dates = this.value && this.value.dates && this.value.dates.length ? [...this.value.dates] : []
    }
  },
  watch: {
    dates(val, oldVal) {
      if (oldVal.length > 0) {
        let value = this.isRange ? val.map(el => { return { starts_at: el.start, ends_at: el.end } }) : val
        this.$emit('input', { dates: value })
        setTimeout(() => {
          this.$emit('inputHandler')
        }, 0)
      }
    }
  },
  methods: {
    validationRequired: frontendValidations.required,
    addDate() {
      const newDate = this.isRange ? { start: new Date(), end: new Date() } : new Date()
      // if (!this.value || !this.value.length) this.$emit('input', newDate)
      this.dates.push(newDate)
    }
  }
}
</script>

<style lang="scss" scoped>
.multiple-dates {
  &__field {
    display: flex;
    align-items: center;
    > span {
      flex: 100%;
    }
  }
}
</style>
