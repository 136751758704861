<template>
  <v-dialog
    v-model="dialog"
    width="700"
  >
    <v-card class="pa-5">
      <v-btn class="modal-close-btn icon-with-hover" icon @click="dialog = false"><v-icon>fa-times</v-icon></v-btn>
      <v-row align="center" class="form-container justify-center versions">
        <v-col sm="12" class="full-width">
          <h2 class="page-title text-center mt-0">{{ $t('About') }}</h2>
          <h3 class="mb-6">{{ $t('Current version of Bid4It') }}</h3>
          <table v-if="versions">
            <tr v-if="versions.version_frontend">
              <td class="pr-5">{{ $t('Frontend') }}:</td>
              <td>{{ versions.version_frontend }}</td>
            </tr>
            <tr v-if="versions.version_backend">
              <td class="pr-5">{{ $t('Backend (EBP)') }}:</td>
              <td>{{ versions.version_backend }}</td>
            </tr>
            <tr v-if="versions.version_config">
              <td class="pr-5">{{ $t('Config') }}:</td>
              <td>{{ versions.version_config }}</td>
            </tr>
            <tr v-if="versions.version_database">
              <td class="pr-5">{{ $t('Database') }}:</td>
              <td>{{ versions.version_database }}</td>
            </tr>
          </table>
          <p class="mt-14 mb-1">{{ $t('Bid4It is a product from Stather IT Consulting | Heidelberg | Germany') }}</p>
          <p class="mt-0">&#169; 2020-{{ new Date().getFullYear() }}, {{ $t('All rights reserved') }}</p>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AboutAdminModal',
  props: {
    versions: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      dialog: false
    }
  },
  methods: {
    open() {
      this.dialog = true
    }
  }
}
</script>

<style scoped>

</style>
