<template>
  <apexchart
    type="donut"
    :options="options"
    :series="chartdata.series"
  />
</template>
<script>
export default {
  name: 'ChartView',
  props: {
    chartdata: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
    }
  }
};
</script>

<style lang="scss">
</style>
