import axios, { AxiosRequestConfig } from 'axios';
import _ from 'lodash'
import { INTERNAL_SERVER_ERROR, UNAUTHORIZED_ERROR } from '@/utils/constants';
import router from '@/router';
import { i18n } from '@/main';
import useRootStore from '@/stores/rootStore';
import { checkIfTokenIsValid, getAuthToken, parseJwt } from '@/utils/token';

const httpClient = {
  init(baseURL: string = process.env.VUE_APP_SERVER, lang = 'en') {
    axios.defaults.baseURL = baseURL + '/api/v2/'
    axios.defaults.headers.common.lang = lang
  },
  setHeaderLang(lang = 'en') {
    axios.defaults.headers.common.lang = lang;
  },
  removeHeader(name: string) {
    axios.defaults.headers.common[name] = '';
  },
  get(ressource: string, params: any = null) {
    axios.defaults.headers.common.lang = i18n.locale
    axios.defaults.responseType = 'json'
    return axios.get(ressource, { params });
  },
  getFile(ressource: string, params: any = null) {
    axios.defaults.headers.common.lang = i18n.locale
    axios.defaults.responseType = 'blob'
    return axios.get(ressource, { params });
  },
  postFile(ressource: string, data: any = null) {
    axios.defaults.headers.common.contentType = 'multipart/form-data'
    axios.defaults.headers.common.lang = i18n.locale
    axios.defaults.responseType = 'json'
    return axios.post(ressource, data)
  },
  post(ressource: string, data: any = null, headers: any = null, timeout: number = null) {
    if (headers) axios.defaults.headers = headers
    axios.defaults.headers.common.lang = i18n.locale
    axios.defaults.responseType = 'json'
    let config = {} as AxiosRequestConfig;
    if (timeout !== null) {
      config.timeout = timeout;
      console.log(`setting custom timeout of ${timeout}`)
    }
    return axios.post(ressource, data)
  },
  put(ressource: string, data: any = null) {
    axios.defaults.headers.common.lang = i18n.locale
    axios.defaults.responseType = 'json'
    return axios.put(ressource, data)
  },
  delete(ressource: string, data: any = null) {
    axios.defaults.headers.common.lang = i18n.locale
    axios.defaults.responseType = 'json'
    return axios.delete(ressource, data)
  },
  head(url: any, conf = {}) {
    axios.defaults.headers.common.lang = i18n.locale
    return axios.head(url, conf)
  },
  options(url: any, conf = {}) {
    axios.defaults.headers.common.lang = i18n.locale
    axios.defaults.responseType = 'json'
    return axios.options(url, conf)
  },
  patch(url: any, data = {}) {
    axios.defaults.headers.common.lang = i18n.locale
    axios.defaults.responseType = 'json'
    return axios.patch(url, data)
  },
  customRequest(data: object) {
    return axios(data)
  },
  interceptors() {
    const rootStore = useRootStore()
    // Interceptors for requests, add jwt as http authorization header if available
    axios.interceptors.request.use(
      (config) => {
        // Check if Auth header is not available
        if (!config.headers.Authorization) {
          const token = getAuthToken();
          // Token is available
          if (token) {
            // Add to request header
            if (config !== undefined) {
              config.headers.Authorization = `Bearer ${token}`;
            }
          }
        }

        return config;
      },
      error => Promise.reject(error)
    );

    // Interceptors for responses
    axios.interceptors.response.use(
      response => response,
      (error) => {
        const rootStore = useRootStore()
        if (error && error.response) {
          const {status} = error.response;

          // Interceptor for 500 error
          if (status === INTERNAL_SERVER_ERROR) {
            // Server error
            console.log('Interceptors: http 500 received')
            router.push({ name: 'error-unexpected' })
            rootStore.SET_UNEXPECTED_ERROR(error.response.data.data)
            // Interceptor for 401 error
          } else if (status === UNAUTHORIZED_ERROR) {
            if (getAuthToken()) {
              const token = getAuthToken()
              if (!checkIfTokenIsValid(token)) {
                // token expired
                console.log('http 401 received while user is logged in, token is expired, resetting frontend')
                rootStore.logout()
                rootStore.SET_TEMP_ALERT({
                  flavor: 'error',
                  content: i18n.t('Your session has expired. Please login again')
                })
              } else {
                // token is still valid (client side), either the user got kicked out or the session doesn't exist on the backend anymore
                if (!_.isEmpty(rootStore.userProfile)) {
                  console.log('http 401 received while user is logged in, token is still valid, resetting frontend')
                  rootStore.kickOut();
                  rootStore.SET_TEMP_ALERT({
                    flavor: 'error',
                    content: i18n.t('An unexpected error occured and you have been logged out. Please login again.\nIf this persists, contact the auction house for more information')
                  })
                }
              }
            } else {
              // no token exists, this happens if a guest tries to run protected endpoints
              console.log('http 401 received while user has no token, resetting frontend')
              if (router.currentRoute.name !== 'login') rootStore.logout()
            }
          }
        }
        return Promise.reject(error);
      }
    );
  }
}
export default httpClient;
