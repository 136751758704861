<template>
  <div class="article-list mr-3" :class="{ active: showArticlesList }">
    <template v-if="isMobile">
      <v-btn class="close-btn icon-with-hover" @click="toggleSlider">
        <v-icon>fa-times</v-icon>
      </v-btn>

      <span class="article-overview-text text-center d-block mb-3">
        <span>{{ $t('Article Overview') }}</span> ({{ articles.length }})
      </span>
    </template>
    <p class="article-group__toggle" v-if="!isMobile" :class="{ active: !showArticlesList }"><v-icon @click="toggleSlider" class="icon-with-hover mr-2">fa-arrow-right</v-icon>{{ $t('Article Overview') }} ({{ articles.length }})</p>
    <p class="article-group__title" v-if="!isMobile">{{ $t('Article Overview') }} ({{ articles.length }}) <v-icon @click="toggleSlider">fa-times</v-icon></p>
    <v-progress-circular v-if="articlesLoading" class="d-block mx-auto mt-6" indeterminate />
    <v-virtual-scroll
      class="article-group"
      v-else-if="articles.length"
      :bench="0"
      :items="articles"
      :height="sliderHeight"
      :item-height="itemHeight"
      ref="scrollContainer"
    >
      <template #default="{ item }">
        <v-card
          class="mx-auto ma-4 article-card"
          :class="[ item.id === ongoingArticle.id ? 'border-blue':'' ]"
          elevation="0"
          @click="openArticleDetailedModal(item)"
        >
          <v-img :src="item.image" width="115px" style="margin: 0 auto;" :contain="true" />
          <v-icon class="icon-search-circle icon-with-hover article-card__open" small>fa-search</v-icon>

          <div class="article-card__content">
            <v-card-subtitle class="article-title pt-0 px-2">
              {{ articleTitle(item) }}
            </v-card-subtitle>

            <v-card-subtitle class="article-description px-2">
              <div class="article-desc-block">
                <v-row>
                  <v-col>
                    <div>
                      <span>{{ $t('Article:') }}</span> {{ getArticleNumber(item) }}
                    </div>
                    <div v-if="item.limit && item.limit !== 0">
                      <span>{{ $t('Limit:') }}</span> {{ currency }}{{ euNumbers(item.limit) }}
                    </div>
                    <div v-if="item.estimated_price && item.estimated_price !== 0">
                      <span>{{ $t('Estimated Price:') }}</span> {{ currency }}{{ item.estimated_price }}
                    </div>
                  </v-col>
                  <v-col class="pb-0" v-if="item.sold_price">
                    <div v-if="item.sold_price && item.sold_price !== 0" class="text-bold">
                      <span>{{ $t('Knockdown Price:') }}</span> {{ currency }}{{ euNumbers(item.sold_price) }}<br>
                    </div>
                    <div class="slider-sold-out" v-if="item.sold_price">
                      <span v-if="!isGuest && (((userProfile.id === item.bidder_id || userBidderNumber === item.bidder_number) && (item.live_bidder || item.type === 'live')) || (item.id === ongoingArticle.id && isArticleSoldOut && currentHighestBid && userProfile.id === currentHighestBid.id))">{{ $t('This item is sold out to you') }}</span>
                      <span class="black--text" v-else>{{ $t('This item is sold out') }}</span>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-card-subtitle>
          </div>
        </v-card>
      </template>
    </v-virtual-scroll>
    <div class="article-group" v-else>
      <h4 class="text-center px-5">{{ $t('No articles found') }}</h4>
    </div>
    <div class="article-list__footer" v-if="!articlesLoading">
      <v-text-field
        append-icon="fa-magnifying-glass"
        :label="$t('Search')"
        hide-details
        @input="searchHandler"
      />
      <v-btn class="lock-btn icon-with-hover mt-4" icon @click="locked = !locked">
        <v-icon v-if="locked">fa-lock</v-icon>
        <v-icon v-else>fa-unlock</v-icon>
      </v-btn>
    </div>
    <article-details-modal
      ref="articleDetailsModal"
      :article="selectedDetailedArticle"
      :selected-auction-data="currentAuction"
      :prev-article="prevArticle"
      :next-article="nextArticle"
      @openArticleDetailedModal="openArticleDetailedModal"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import _ from 'lodash'
import articleDetailsModal from '@/components/modals/article-details-modal'
import articleDetails from '@/mixins/article-details'
import useRootStore from '@/stores/rootStore';
import useAuctionStore from '@/stores/auctionStore';
import { searchArticles } from '@/services/article';
import { euNumbers, getTranslatedText } from '@/services/i18n';

export default {
  name: 'ArticlesListDesktop',
  components: {
    articleDetailsModal
  },
  props: {
    isArticleSoldOut: {
      type: Boolean,
      default: false
    },
    bidderNumbers: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loading: false,
      prevId: null,
      articlesLoading: false,
      search: '',
      locked: true,
      isGuest: false
    }
  },
  mixins: [
    articleDetails
  ],
  async mounted() {
    if (_.isEmpty(this.userProfile)) this.isGuest = true
    this.articles = [...this.currentAuctionArticles]
    this.$nextTick(() => {
      this.scrollToActiveArticle()
    })
  },
  watch: {
    currentAuctionArticles: {
      deep: true,
      handler(val) {
        this.articles = [...val]
      }
    },
    async ongoingArticle(val) {
      const index = this.articles.findIndex(el => el.id === val.id)
      this.articles.splice(index, 1, {
        ...this.articles[index],
        ...val
      })
      const scrollTo = index * this.itemHeight
      if (this.$refs.scrollContainer.$el.scrollTop !== scrollTo && this.locked) this.$refs.scrollContainer.$el.scrollTop = scrollTo
    }
  },
  computed: {
    ...mapState(useAuctionStore, ['currentAuction', 'currentAuctionArticles', 'footerCrawler', 'ongoingArticle', 'currentHighestBid']),
    ...mapState(useRootStore, ['appLoading', 'userProfile', 'appLocalization', 'appStoreLang', 'isMobile', 'showArticlesList']),
    itemHeight() {
      return this.isMobile ? 115 : 129
    },
    currency () {
      return this.appLocalization.currency
    },
    sliderHeight() {
      return this.isMobile ? window.innerHeight - 175 : window.innerHeight - 208
    },
    userBidderNumber() {
      const target = this.bidderNumbers.find(el => el.auction_id === this.currentAuction.id)
      return target ? target.bidder_number : null
    }
  },
  methods: {
    ...mapActions(useAuctionStore, ['getAuctionArticles', 'TOGGLE_CRAWLER']),
    ...mapActions(useRootStore, ['UPDATE_GLOBAL_STATE']),
    euNumbers,
    getArticleNumber(itm) {
      return itm.number_optional || `#${_.padStart(itm.number, 4, '0')}`
    },
    articleTitle (article) {
      return getTranslatedText(article, 'name', this.appStoreLang)
    },
    articleDescription (article) {
      return getTranslatedText(article, 'description', this.appStoreLang)
    },
    searchArticles,
    searchHandler: _.debounce(function (e) {
      if (e) {
        this.articles = this.searchArticles(e, this.currentAuctionArticles, this.appStoreLang, this.appLocalization.i18n.defaultLanguage.lang)
      } else {
        this.articles = [...this.currentAuctionArticles]
        setTimeout(() => {
          this.scrollToActiveArticle()
        }, 500)
      }
    }, 500),
    toggleSlider() {
      this.UPDATE_GLOBAL_STATE({ key: 'showArticlesList', value: !this.showArticlesList })
    },
    scrollToActiveArticle() {
      const index = this.articles.findIndex(el => el.id === this.ongoingArticle.id)
      const scrollTo = index * this.itemHeight
      if (this.$refs.scrollContainer.$el.scrollTop !== scrollTo && this.locked) this.$refs.scrollContainer.$el.scrollTop = scrollTo
    }
  }
}
</script>

<style scoped lang="scss">
.articles-length {
  position: absolute;
  margin: 0;
  top: 5px;
  right: -5px;
  transform: translateX(100%);
}
.article-group__toggle {
  position: absolute;
  left: 0;
  top: -11px;
  min-width: 380px;
  display: flex;
  align-items: center;
  padding: 0 15px 0 5px;
  margin-bottom: 0;
  transform: translateX(-100%);
  font-size: 0.875rem;
  max-height: 0;
  transition: all .3s ease;
  transition-delay: 0s;
  &.active {
    transform: translateX(100%);
    max-height: 100%;
    transition-delay: .3s;
    @media(max-width: 968px) {
      transition-delay: 0s;
    }
  }
}
.auction-container {
  .article-list {
    position: relative;
    max-width: 380px;
    margin-left: -80px;
    min-width: 380px;
    border-right: 1px solid #E5E5E5;
    padding-right: 4px;
    transform: translateX(-100%);
    transition: all .3s ease;
    transition-delay: 0s;
    &.active {
      transform: translateX(0);
      transition-delay: .3s;
      @media(max-width: 968px) {
        transition-delay: 0s;
      }
    }
    @media screen and (max-width: 1300px) {
      position: fixed;
      left: 0;
      top: 100px;
      margin-left: 0;
      max-height: calc(100vh - 100px);
    }
    @media screen and (max-width: 700px) {
      min-width: 100%;
      padding: 10px;
      border-right: none;
      top: 80px;
      max-height: calc(100vh - 80px);
    }
    .article-group {
      max-height: calc(100% - 125px);
      @media screen and (max-width: 700px) {
        max-height: calc(100vh - 125px);
      }
      &__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
        margin-bottom: 4px;
      }
      &::-webkit-scrollbar {
        width: 6px;
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        width: 6px;
        background: #7f7f7f;
        border-radius: 4px;
      }
      .article-card {
        display: flex;
        width: 100% !important;
        min-height: initial !important;
        margin: 0 0 5px!important;
        padding: 5px 0;
        height: 110px;
        &__open {
          position: absolute;
          top: 5px;
          right: 5px;
        }
        &__content {
          width: calc(100% - 100px);
          overflow: hidden;
        }
        .article-desc-block {
          min-height: initial !important;
        }
        .article-title {
          margin-bottom: 8px;
          padding-bottom: 0;
          padding-right: 35px !important;
          white-space: normal;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          word-break: break-word;
          -webkit-line-clamp: 2;
        }
      }
    }
    &__footer {
      display: flex;
      align-items: center;
      padding: 10px;
    }
  }
}
</style>
