<template>
  <div v-if="item.type === 'uploadFile'">
    <v-row align="center" class="upload-outer-wrap">
      <v-col class="upload-wrap">
        <div class="upload-image-label">{{ item.label }}</div>
        <div class="upload-area-wrap">
          <v-img
            v-if="value"
            :src="value"
            class="preview-image"
            contain height="40px"
          />
          <a class="upload-btn underline" @click="onClickBtn()">{{ item.uploadBtn }}</a>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import apiRequests from '@/api/apiRequests'
import dispatcher from '@/api/dispatch';

export default {
  name: 'UploadInput',
  props: ['value', 'item'],
  methods: {
    onClickBtn() {
      this.inputElem.click();
    },
    async onChange(e) {
      e.preventDefault();
      e.stopPropagation();
      this.loading = true;

      let formData = new FormData();
      formData.append('file', e.target.files[0]);

      const result = await dispatcher.uploadImage(formData, { headers: { 'Content-Type': 'multipart/form-data' }, onUploadProgress: (e) => console.log(e) }
      );
      this.loading = false;
      this.$emit('input', result);
      this.inputElem.value = '';
    }
  },
  mounted () {
    this.inputElem = document.createElement('INPUT');
    this.inputElem.type = 'file';
    this.inputElem.accept = this.acceptFile;
    this.inputElem.style.height = '0px';
    this.inputElem.style.width = '0px';
    this.inputElem.style.position = 'fixed';
    this.inputElem.style.left = '-999999px';

    this.inputElem.addEventListener('change', this.onChange.bind(this))
    document.body.append(this.inputElem);
  },
  destroyed () {
    this.inputElem.remove();
  },
}
</script>
