<template>
  <v-container class="form-template-wrap pb-0">
    <v-row class="form-container">
      <v-col align="center">
        <div v-if="formName" class="form-title mb-5">{{ $t(formName) }}</div>
        <v-row>
          <v-col
            v-for="(item, index) in formData"
            :key="index"
            cols="6"
            class="py-0"
          >
            <v-text-field
              v-if="item.type === 'text' || item.type === 'password' || item.type === 'url' || item.type === 'email'"
              :hide-details="item.option === true"
              :type="item.type"
              v-model="mutableValue[item.key]"
              :label="item.label"
              :placeholder="' '"
              class="input-field"
              :rules="[validationItemRequired(item, value)]"
              :class="{ 'mb-7': item.option === true }"
              @input="$emit('changeData')"
            >
              <template v-if="item.option" #append-outer>
                <span>{{ $t('Optional') }}</span>
              </template>
              <template #message="{ message }">
                {{ $t(message) }}
              </template>
            </v-text-field>

            <v-text-field
              v-if="item.type === 'number'"
              :hide-details="item.option === true"
              :type="item.type"
              v-model.number="mutableValue[item.key]"
              :label="item.label"
              placeholder=" "
              :rules="[validationItemRequired(item, value)]"
              class="input-field"
              @input="$emit('changeData')"
            >
              <template v-if="item.option" #append-outer>
                <span>{{ $t('Optional') }}</span>
              </template>
              <template #message="{ message }">
                {{ $t(message) }}
              </template>
            </v-text-field>

            <v-select
              v-if="item.type === 'select'"
              :items="item.options"
              :label="item.label"
              placeholder=" "
              v-model="mutableValue[item.key]"
              class="select-input profile-select-countries"
              append-icon="fa-angle-down"
              :item-text="_item => _.capitalize(_item)"
              :menu-props="{ bottom: true, offsetY: true }"
              :rules="[validationItemRequired(item, value)]"
              @input="$emit('changeData')"
            >
              <template #message="{ message }">
                {{ $t(message) }}
              </template>
            </v-select>

            <v-select
              v-if="item.type === 'select-value'"
              :items="item.options"
              :label="item.label"
              placeholder=" "
              v-model="mutableValue[item.key]"
              class="select-input profile-select-countries"
              append-icon="fa-angle-down"
              :item-text="_item => _.capitalize(_item.title)"
              item-value="value"
              :menu-props="{ bottom: true, offsetY: true }"
              @input="$emit('changeData')"
            />

            <v-autocomplete
              v-if="item.type === 'autocomplete'"
              v-model="mutableValue[item.key]"
              :items="item.options"
              solo
              :label="item.label"
              class="search-box"
              :item-value="(item) => item"
              :item-text="(item) => item"
              @input="$emit('changeData')"
            />

            <div v-if="item.type === 'btnList'" class="input-field">
              <div class="v-input__control">
                <v-row class="vertical-align-center no-gutters">
                  <v-col>
                    <div class="btn-label">
                      {{ $t(item.label) }}
                    </div>
                  </v-col>
                  <v-col>
                    <div class="btn-list">
                      <v-btn
                        v-for="(option, index) in item.options" :key="index"
                        class="option-btn no-border-radius-right"
                        :class="{ inactive: value[item.key] !== option.value }"
                        @click="mutableValue[item.key] = option.value"
                      >
                        <span>{{ $t(option.title) }}</span>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>

            <upload-input
              :item="item"
              v-model="mutableValue[item.key]"
            />

            <template v-if="item.type === 'button'">
              <slot />
            </template>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UploadInput from './form-item/upload-input'
import frontendValidations from '@/utils/rules';
import _ from 'lodash';

export default {
  name: 'FormTemplate',
  computed: {
    _() {
      return _
    }
  },
  components: {
    UploadInput,
  },
  data() {
    return {
      mutableValue: this.value
    }
  },
  props: ['formName', 'formData', 'value'],
  emits: ['changeData'],
  methods: {
    validationItemRequired: frontendValidations.itemRequired
  },
  watch: {
    value(val) {
      this.mutableValue = val;
    }
  }
}
</script>
