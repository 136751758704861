import useRootStore from '@/stores/rootStore';
import { searchableArticleKeys, translateableArticleKeys } from '@/utils/constants';
import MiniSearch from 'minisearch';
import _ from 'lodash';

const ARTICLES_SEARCH_OPTIONS = {
  combineWith: "AND",
  prefix: true
}

export const createArticleSearchIndex = (articles: any[]): any => {
  const rootStore = useRootStore();
  console.log(`article index created for ${articles.length} items`);
  let fields = [...searchableArticleKeys]
  for (let key of translateableArticleKeys) {
    for (let lang of rootStore.appLocalization.i18n.availableLanguages) {
      fields.push(key + "_" + lang)
    }
  }

  const miniSearch = new MiniSearch({
    fields: fields,
    searchOptions: ARTICLES_SEARCH_OPTIONS,
    extractField: (document, fieldName) => {
      if (translateableArticleKeys.some(e => fieldName.startsWith(e + "_"))) {
        let fieldParts = fieldName.split("_");
        if (document[fieldParts[0] + "_i18n"] && document[fieldParts[0] + "_i18n"][fieldParts[1]]) {
          return document[fieldParts[0] + "_i18n"][fieldParts[1]];
        } else {
          return document[fieldParts[0]];
        }
      } else {
        return document[fieldName];
      }
    }
  });

  miniSearch.addAll(articles);

  return miniSearch;
}

/**
 * filter articles by name, description or lot number. If the language is set to a different one then the default language, search is done only in name_* and descriptin_* of that language
 * @param {any} miniSearch - The article search index
 * @param {string} search - The search string
 * @param {any} articles - The articles array
 * @param {string[]} searchKeys - The keys to search i.e. 'name' or 'description'
 * @param {string} lang - The currently active language in 2-digit code i.e. 'en'
 * @return {<any>} - Filtered articles
 */
export const searchArticles = (miniSearch: any, search: string, articles: any[], searchKeys: string[], lang: string) => {
  let searchResults = [];

  if (!_.isNumber(search.trim())) {
    let n = parseInt(search.trim());
    for (let article of articles) {
      if (parseInt(article.number_optional) === n) {
        searchResults.push(article);
      } else if (article.number === n) {
        searchResults.push(article);
      }
    }
  }

  const searchResult = miniSearch.search(search, {fields: searchKeys});
  searchResult.sort((e1, e2) => e1.id - e2.id);

  if (searchResults.length === 0) {
    for (let resultItem of searchResult) {
      const article = articles.find(e => e.id === resultItem.id);
      searchResults.push(article);
    }
  } else {
    for (let resultItem of searchResult) {
      const article = articles.find(e => e.id === resultItem.id);
      if (!searchResults.some(e => e.id === article.id)) {
        searchResults.push(article);
      }
    }
  }

  return searchResults;
}
