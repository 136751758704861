<template>
  <div class="flashphoner-embed-page" :class="{ inactive: !streamWorking }" v-if="!_.isEmpty(appSettings)">
    <div class="desktop-flashphoner-only-audio" v-if="isDesktop && onlyAudio && !isFlashPhonerDisabled">
      <span>{{ $t('Start audio broadcast') }}</span>
      <div id="autoVideo" class="iframeWrapper" style="display:none;" v-if="browser.name !== 'safari' && browser.name !== 'ios' && browser.os !== 'iOS'" />
      <iframe v-else-if="componentMounted && hasIframeData" id="autoVideo" :src="`${appSettings.url_stream_http}/embed_player?urlServer=${appSettings.url_stream}&amp;streamName=${appSettings.url_stream_name}&amp;mediaProviders=WebRTC&amp;audioOnly=true`" marginwidth="0" marginheight="0" scrolling="no" width="480" height="50" frameborder="0" />
    </div>

    <div class="mobile-flashphoner-only-audio" v-if="!isDesktop && !isFlashPhonerDisabled">
      <div id="autoVideo" class="iframeWrapper" style="display:none;" v-if="browser.name !== 'safari' && browser.name !== 'ios' && browser.os !== 'iOS'" />
      <iframe v-else-if="componentMounted && hasIframeData" id="autoVideo" :src="`${appSettings.url_stream_http}/embed_player?urlServer=${appSettings.url_stream}&amp;streamName=${appSettings.url_stream_name}&amp;mediaProviders=WebRTC&amp;audioOnly=true`" marginwidth="0" marginheight="0" scrolling="no" width="280" height="36" frameborder="0" />
    </div>

    <div class="embed-settings">
      <div class="flashphoner-buttons">
        <template v-if="browser.name !== 'safari' && browser.name !== 'ios' && browser.os !== 'iOS'">
          <p class="flashphoner-text">{{ $t('Live Stream') }}</p>
          <v-btn color="green" class="btn flashphoner-btn icon-with-hover input-field-bigger-icon" plain :loading="btnLoading" @click="btnPlayClicked()" v-if="(isPaused || !streamWorking) && !isFlashPhonerDisabled">
            <v-icon>fa-play</v-icon>
          </v-btn>
          <v-btn class="btn flashphoner-btn icon-with-hover input-field-bigger-icon" plain @click="pauseStream()" v-if="!isPaused && streamWorking && !isFlashPhonerDisabled">
            <v-icon>fa-pause</v-icon>
          </v-btn>
        </template>
        <v-btn v-if="!isDesktop" class="btn crawler-btn icon-with-hover input-field-bigger-icon" plain @click="useRootStore().UPDATE_GLOBAL_STATE({ key: 'showArticlesList', value: true })">
          <v-icon>fa-list</v-icon>
        </v-btn>

        <!--        <button class="btn btn-primary" @click="unmuteVideo()" v-if="isMuted">-->
        <!--          <v-icon>volume_off</v-icon>-->
        <!--        </button>-->
        <!--        <button class="btn btn-primary" @click="muteVideo()" v-if="!isMuted">-->
        <!--                    <span>-->
        <!--                        <svg style="width:24px;height:24px" viewBox="0 0 24 24">-->
        <!--                            <path fill="currentColor"-->
        <!--                                  d="M14,3.23V5.29C16.89,6.15 19,8.83 19,12C19,15.17 16.89,17.84 14,18.7V20.77C18,19.86 21,16.28 21,12C21,7.72 18,4.14 14,3.23M16.5,12C16.5,10.23 15.5,8.71 14,7.97V16C15.5,15.29 16.5,13.76 16.5,12M3,9V15H7L12,20V4L7,9H3Z"/>-->
        <!--                        </svg>-->
        <!--                    </span>-->
        <!--        </button>-->
      </div>
    </div>

    <div v-if="isDesktop && !onlyAudio">
      <div id="autoVideo" class="iframeWrapper videoWrapper" v-show="!isPaused" v-if="browser.name !== 'safari' && browser.name !== 'ios' && browser.os !== 'iOS'">
        <span v-if="isDesktop" class="player-video-icon">
          <v-icon>videocam_off</v-icon>
        </span>
      </div>
      <iframe v-else-if="componentMounted && hasIframeData" id="autoVideo" :src="`${appSettings.url_stream_http}/embed_player?urlServer=${appSettings.url_stream}&amp;streamName=${appSettings.url_stream_name}&amp;mediaProviders=WebRTC&amp`" marginwidth="0" marginheight="0" scrolling="no" width="480" height="250" frameborder="0" />
    </div>
  </div>
</template>
<script>
import {mapActions, mapState} from 'pinia'
import Flashphoner from '@flashphoner/websdk';
import { detect } from 'detect-browser'
import useRootStore from '@/stores/rootStore';

export default {
  name: 'FlashPhoner',
  data: () => ({
    SESSION_STATUS: {},
    STREAM_STATUS: {},
    LOCAL_SESSION: null,
    LOCAL_STREAM: null,
    PRELOADER_URL: require('@/assets/preloader.mp4'),
    isPaused: true,
    isMuted: false,
    onlyAudio: true,
    isFlashPhonerConnected: false,
    timeout: 5000,
    retryMaxTimes: 1000,
    retryCount: 0,
    streamWorking: false,
    btnLoading: false,
    browser: detect(),
    componentMounted: false
  }),
  props: {
    type: {
      type: String,
      default: 'desktop'
    },
    selected: {
      type: String,
      default: ''
    },
    synced: {
      type: Boolean,
      default: false
    },
    isFlashPhonerDisabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(useRootStore, ['appSettings']),
    isDesktop() {
      return this.type === 'desktop'
    },
    hasIframeData() {
      return this.appSettings && this.appSettings.url_stream_http && this.appSettings.url_stream && this.appSettings.url_stream_name
    }
  },
  created() {
    if (this.appSettings.url_stream_type === 'stream_video') {
      this.onlyAudio = false
    }
  },
  mounted() {
    console.log('browser detected: %s | version: %s | os: %s', this.browser.name, this.browser.version, this.browser.os)
    this.componentMounted = true
  },
  beforeDestroy() {
    if (this.LOCAL_STREAM !== null) this.LOCAL_STREAM.stop()
    // important to wait since otherwise the status is still PLAYING (if the user didn't click on stop). In this case the status will be FAILED instead of STOPPED
    if (this.LOCAL_SESSION) {
      setTimeout(() => {
        this.LOCAL_SESSION.disconnect()
      }, 1000);
    }
  },
  methods: {
    ...mapActions(useRootStore, ['CLEAR_ALERT', 'SET_TEMP_ALERT']),
    useRootStore,
    async loadVideo() {
      this.btnLoading = true
      this.SESSION_STATUS = Flashphoner.constants.SESSION_STATUS
      this.STREAM_STATUS = Flashphoner.constants.STREAM_STATUS
      await Flashphoner.init({})
      this.LOCAL_SESSION = Flashphoner.createSession({ urlServer: this.appSettings.url_stream, timeout: this.timeout })
        .on(this.SESSION_STATUS.ESTABLISHED, () => {
          this.isFlashPhonerConnected = true
          if (!this.isDesktop || this.browser.name === 'safari') {
            Flashphoner.playFirstVideo(document.getElementById('autoVideo'), true, this.PRELOADER_URL).then(() => {
              this.playStream()
            }).catch((e) => {
              console.log('error occured: %s', e);
            })
          } else {
            this.playStream()
          }
          // get rid of the "Autoplay detected! Trying to play a video with a muted sound..." message and muted audio with iOS Safari browsers
          // console.log('browser detected: %s | version: %s | os: %s',this.browser.name,this.browser.version,this.browser.os)
          // if (this.browser.name == 'safari') {
          //  this.LOCAL_STREAM.unmuteRemoteAudio()
          //  console.log('audio unmuted')
          // }
          this.btnLoading = false
        }).on(this.SESSION_STATUS.DISCONNECTED, () => {
          console.log('Stream Disconnected')
          this.btnLoading = false
        }).on(this.SESSION_STATUS.FAILED, () => {
          console.log('Stream Failed')
          this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('Unable to connect to the live-stream. Please try again later.') })
          this.btnLoading = false
        });
    },
    btnPlayClicked() {
      this.loadVideo()
    },
    playStream() {
      this.isPaused = false
      if (this.appSettings.url_stream_type === 'stream_video') {
        this.onlyAudio = false
        document.getElementById('app-user-auction-container').classList.add('auction-live-height')
      }
      let options = {
        name: this.appSettings.url_stream_name,
        display: document.getElementById('autoVideo'),
        // receiveAudio: true,
      }
      if (this.isDesktop && this.appSettings.url_stream_type === 'stream_video') {
        options = {
          ...options,
          constraints: {
            video: true,
            audio: true
          }
        }
      } else {
        this.onlyAudio = true
        options = {
          ...options,
          constraints: {
            video: false,
            audio: true
          }
        }
      }
      this.LOCAL_STREAM = this.LOCAL_SESSION.createStream(options)
      this.LOCAL_STREAM.on(this.STREAM_STATUS.PLAYING, () => {
        this.CLEAR_ALERT()
        this.streamWorking = true
        console.log('media provider: %s', Flashphoner.getMediaProviders()[0])
        console.log('Stream playing')
      // }).on(this.STREAM_STATUS.PENDING, () => {
      //  console.log('unmuting')
      //  this.LOCAL_STREAM.unmuteRemoteAudio()
      }).on(this.STREAM_STATUS.STOPPED, () => {
        console.log('Stream stopped')
      }).on(this.STREAM_STATUS.FAILED, () => {
        if (!this.streamWorking) {
          this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('Currently there is no live-stream available. Please try again later.') })
        } else {
          this.retryToRestartStream()
          console.log('state: disconnected, trying to reconnect')
        }
      }).play()
    },
    retryToRestartStream() {
      if (this.retryCount < this.retryMaxTimes) {
        setTimeout(() => {
          if (this.LOCAL_STREAM && (this.LOCAL_STREAM.status() !== this.STREAM_STATUS.PLAYING)) {
            this.playStream()
            this.retryCount++
          }
        }, this.timeout);
      }
    },
    pauseStream() {
      this.isPaused = true
      this.LOCAL_STREAM.stop()
      document.getElementById('app-user-auction-container').classList.remove('auction-live-height')
    },
    muteVideo() {
      this.isMuted = true
      this.LOCAL_STREAM.muteRemoteAudio()
    },
    unmuteVideo() {
      this.isMuted = false
      this.LOCAL_STREAM.unmuteRemoteAudio()
    }
  }
}
</script>
<style lang="scss">
.flashphoner-embed-page {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  &.inactive {
    .videoWrapper {
      display: none;
    }
  }
  > div {
    width: 100%;
  }
  .embed-settings {
    width: 100%;
    flex: 100%;
  }
}

.iframeWrapper {
  position: relative;
  margin: 10px auto;
  @media(max-width: 968px) {
    margin: 10px 0;
  }
}

.player-audio-icon, .player-video-icon {
  // display: none;
  width: 15px;
  margin: 0 auto;
  text-align: center;
  line-height: 18px;
}

.audioWrapper {
  height: 20px;
  width: 100%;
  border-radius: 15px;

  .player-audio-icon {
    display: block;
  }
}

.videoWrapper {
  border: 1px solid black;
  position: relative;
  // min-height: 195px;
  width: 33vh !important;
  height: 25vh !important;
  overflow: hidden;
  margin: 10px 0;

  video {
    // width:217px !important;
    // height:163px !important;
    width: 33vh !important;
    height: 25vh !important;
    position: relative;
    vertical-align: bottom;
  }

  .player-video-icon {
    display: block;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

.slider-flashphoner {
  //   max-width: 350px;
  width: 100%;
  margin: 0;
}

.flashphoner-buttons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 25px;

  svg {
    vertical-align: text-top;
    color: rgba(0, 0, 0, .54);
  }

  .flashphoner-text {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.02em;
    margin: 0 8px 0 0;
    @media (max-width: 968px) {
      display: none;
    }
  }
}

.desktop-flashphoner-only-audio {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 10px;
}

.mobile-flashphoner {
  position: absolute;
  top: 65px;
  right: 10px;
  width: calc(100% - 20px);
  @media (max-width: 968px) {
    top: 85px;
  }
  &.safari {
    position: relative;
    width: 100%;
    top: 0;
    left: 0;
    .mobile-flashphoner-only-audio {
      @media (max-width: 968px) {
        padding-left: 10px;
        margin-top: 10px;
      }
    }
    .flashphoner-buttons {
      button.crawler-btn {
        position: absolute;
        right: 15px;
        margin-right: 0;
        top: 0;
      }
    }
  }

  .flashphoner-buttons {
    @media (max-width: 968px) {
      flex-direction: column;
      align-items: end;
      margin-top: 10px;
    }
    button {
      &.flashphoner-btn {
        border-radius: 10px;
        font-size: 16px;
        width: 36px;
        height: 36px;
      }
      &.crawler-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        padding: 0 !important;
        min-width: initial !important;
        font-size: 16px;
        width: 36px;
        height: 36px;
        top: 0;
        border: 1px solid transparent;
        transition: all .3s ease;
        @media (max-width: 968px) {
          margin-top: 10px;
        }

        &:hover {
          border-color: #262626;
        }
      }

      @media(max-width: 968px) {
        z-index: 201;
      }
    }
  }
}
</style>
