<template>
  <v-dialog
    v-model="isOpen"
    class="lighten-5"
    id="container"
    width="80%"
    scrollable
    @keydown.esc="close()"
    persistent
  >
    <v-card class="article-modal" flat>
      <v-btn class="modal-close-btn icon-with-hover" icon @click="close()"><v-icon>fa-times</v-icon></v-btn>
      <v-card-title class="justify-center">
        <div v-if="!currentArticle.id" class="form-title a1">{{ $t('Add new lot') }}</div>
        <div v-if="currentArticle.id" class="form-title a2">{{ $t('Update lot') }}</div>
      </v-card-title>
      <v-card-text class="main-card pa-0">
        <v-container class="pa-0">
          <v-row align="start" class="form-container">
            <v-col md="4" align="center">
              <v-form ref="articleFormLeft" v-model="isFormLeftValid">
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      type="number"
                      v-model.number="currentArticle.number"
                      :label="$t('Article #')"
                      class="input-field"
                      placeholder=" "
                      @input="isDuplicateError = ''"
                      :rules="[validationRequiredRule()]"
                      :error-messages="isDuplicate ? isDuplicateError : ''"
                    >
                      <template #message="{ message }">
                        {{ $t(message) }}
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      type="text"
                      v-model="currentArticle.number_optional"
                      :label="$t('Alphanumeric Article number')"
                      placeholder=" "
                      class="input-field"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" class="pt-0">
                    <v-text-field
                      type="number"
                      v-model.number="currentArticle.limit"
                      :label="$t('Limit')"
                      required
                      class="input-field"
                      placeholder=" "
                      :rules="[validationRequiredRule()]"
                    >
                      <template #message="{ message }">
                        {{ $t(message) }}
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" class="pt-0">
                    <v-text-field
                      v-model="currentArticle.estimated_price"
                      :label="$t('Estimated price')"
                      class="input-field"
                      placeholder=" "
                      :hint="$t('format X-Y (i.e. 100-200)')"
                      :rules="[validationEstimatedPriceRule()]"
                    >
                      <template #message="{ message }">
                        {{ translateValidationIfNeeded(message) }}
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="currentAuction.type === 'timed'">
                  <v-col cols="6" class="pt-0">
                    <vc-date-picker
                      :value="currentArticle.timedData ? currentArticle.timedData.active_from : null"
                      mode="dateTime"
                      is24hr
                      @input="inputTimedActiveFromHandler"
                    >
                      <template #default="{ inputValue, togglePopover }">
                        <v-text-field
                          class="bg-white text-gray-700 w-full py-2 px-0 input-field"
                          :value="inputValue"
                          :rules="[validationInFutureRule(), validationOlderThanEndRule(currentArticle)]"
                          @focus="togglePopover"
                          :label="$t('Starts at')"
                          :disabled="currentArticle.status === '0' || currentArticle.status === '2'"
                          :hide-details="currentArticle.status === '0' || currentArticle.status === '2'"
                          :readonly="currentArticle.status !== '1'"
                        >
                          <template #message="{ message }">
                            {{ $t(message) }}
                          </template>
                        </v-text-field>
                      </template>
                    </vc-date-picker>
                  </v-col>
                  <v-col cols="6" class="pt-0">
                    <vc-date-picker
                      :value="currentArticle.timedData ? currentArticle.timedData.active_until : null"
                      mode="dateTime"
                      is24hr
                      @input="inputTimedActiveUntilHandler"
                    >
                      <template #default="{ inputValue, togglePopover }">
                        <v-text-field
                          class="bg-white text-gray-700 w-full py-2 px-0 input-field"
                          :value="inputValue"
                          :rules="[validationInFutureRule(), validationNewerThanStartRule(currentArticle)]"
                          @focus="togglePopover"
                          :label="$t('Ends at')"
                          :disabled="currentArticle.status === '0'"
                          :hide-details="currentArticle.status === '0'"
                          :readonly="currentArticle.status !== '1'"
                        >
                          <template #message="{ message }">
                            {{ $t(message) }}
                          </template>
                        </v-text-field>
                      </template>
                    </vc-date-picker>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="7" class="pt-0">
                    <v-autocomplete
                      v-model="currentArticle.category_id"
                      :items="categories"
                      :rules="[validationRequiredRule()]"
                      :label="$t('Category')"
                      class="input-field"
                      item-value="id"
                      item-text="name"
                    >
                      <template #message="{ message }">
                        {{ $t(message) }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="5" class="pt-4">
                    <form-lang-switcher ref="langSwitcher" @on-changed="langChanged"/>
                  </v-col>
                </v-row>
                <v-checkbox
                  :hide-details="true"
                  class="input-field"
                  v-model="currentArticle.no_shipping"
                  :label="$t('Pickup only')"
                />
                <v-checkbox
                  :hide-details="true"
                  class="input-field"
                  v-model="currentArticle.offensive"
                  :label="$t('Offensive item')"
                />
                <v-checkbox
                  :hide-details="true"
                  class="input-field"
                  v-model="currentArticle.is_highlight"
                  :label="$t('Highlight')"
                />
              </v-form>
            </v-col>
            <v-col md="8" align="center">
              <v-form ref="articleFormRight" v-model="isFormRightValid">
                <v-textarea
                  :label="$t('Lot name')"
                  required
                  class="input-field"
                  rows="2"
                  placeholder=" "
                  :rules="[requiredIfNativeLanguageRule()]"
                  :value="getTranslatedText('name')"
                  @input="val => setTranslatableValue('name', val)"
                  no-resize
                  single-line
                >
                  <template #message="{ message }">
                    {{ $t(message) }}
                  </template>
                </v-textarea>
                <v-textarea
                  v-if="!useHtml"
                  :label="$t('Description')"
                  required
                  class="input-field"
                  :rules="[requiredIfNativeLanguageRule()]"
                  rows="2"
                  placeholder=" "
                  :value="getTranslatedText('description')"
                  @input="val => setTranslatableValue('description', val)"
                  no-resize
                  single-line
                >
                  <template #message="{ message }">
                    {{ $t(message) }}
                  </template>
                </v-textarea>
                <VueEditor id="editor"
                 v-model="vueEditorDescription"
                 :editorToolbar="customToolbar"
                 @text-change="(delta, oldDelta, source) => setTranslatableValue('description', vueEditorDescription)"
                />
                <v-col cols="12">
                  <article-image-management
                    v-if="currentArticle.id"
                    :auctionId="currentAuction.id"
                    :articleId="currentArticle.id"
                  />
                </v-col>
              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="justify-center flex-wrap action-buttons">
        <v-btn @click="close" class="secondary-btn" large :disabled="loading">
          <span>{{ $t('Cancel') }}</span>
        </v-btn>
        <v-btn class="primary-btn save-btn" large @click="createOrUpdateArticle" :disabled="!isFormLeftValid || !isFormRightValid || loading || wrongDates">
          <span v-if="!currentArticle.id" style="color:white;" class="a1">{{ $t('Add Article') }}</span>
          <span v-if="currentArticle.id" style="color:white;" class="a2">{{ $t('Save') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import { mapActions, mapState } from 'pinia'
import ArticleImageManagement from '@/components/blocks/article-image-management'
import frontendValidations from '@/utils/rules';
import _ from 'lodash';
import useRootStore from '@/stores/rootStore';
import useAuctionStore from '@/stores/auctionStore';
import { localFeatures } from '@/utils/constants';
import { translateValidationIfNeeded } from '@/services/i18n';
import { VueEditor } from "vue2-editor";

export default {
  name: 'UpsertArticleModal',
  components: {
    ArticleImageManagement,
    VueEditor
  },
  props: {
    categories: {
      type: Array,
      default: () => []
    },
    currentAuction: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      // isFormReady: true,
      isOpen: false,
      loading: false,
      defaultArticle: {},
      currentArticle: {},
      isDuplicate: false,
      isDuplicateError: '',
      selectedLang: null,
      wrongDates: false,
      isFormLeftValid: false,
      isFormRightValid: false,
      useHtml: localFeatures.useHtml,
      vueEditorDescription: '',
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ['clean']
      ]
    }
  },
  emits: ['reFetchArticles'],
  computed: {
    ...mapState(useRootStore, ['appStoreLang']),
  },
  watch: {
    // evaluate every time the modal is opened
    isOpen(val) {
      if (val) {
        this.vueEditorDescription = this.getTranslatedText('description')
      }
    },
    selectedLang() {
      if (this.selectedLang !== null) {
        this.vueEditorDescription = this.getTranslatedText('description')
     }
    }
  },
  methods: {
    ...mapActions(useRootStore, ['SET_TEMP_ALERT']),
    ...mapActions(useAuctionStore, ['addArticle', 'updateArticle']),
    translateValidationIfNeeded,
    validationRequiredRule: frontendValidations.required,
    validationInFutureRule: frontendValidations.inFuture,
    validationNewerThanStartRule: frontendValidations.newerThanStart,
    validationOlderThanEndRule: frontendValidations.olderThanEnd,
    validationEstimatedPriceRule: frontendValidations.estimatedPrice,
    requiredIfNativeLanguageRule() {
      return value => {
        if (!this.selectedLang || this.selectedLang === this.appStoreLang) {
          return !!value || 'validation.inputRequired'
        } else {
          return true
        }
      }
    },
    getFieldDate(date) {
      if (date && isNaN(Date.parse(date))) {
        return `${date.slice(6, 10)}-${date.slice(3, 5)}-${date.slice(0, 2)}T${date.slice(11, 13)}:${date.slice(14, 16)}:00.000Z`
      }
      return date
    },
    getTranslatedText(field) {
      if (!this.selectedLang || this.selectedLang === this.appStoreLang) {
        return this.currentArticle[field]
      } else {
        return this.currentArticle[`${field}_i18n`] ? this.currentArticle[`${field}_i18n`][this.selectedLang] : ''
      }
    },
    open(article = null) {
      this.currentArticle = article ? _.clone(article) : {}
      if (article) this.defaultArticle = _.clone(article)
      this.isOpen = true
      this.$nextTick(() => {
        this.$refs.articleFormLeft.resetValidation()
        this.$refs.articleFormRight.resetValidation()
      })
    },
    close() {
      this.isOpen = false;
      this.clearData();
      if (!_.isEmpty(this.$route.query)) this.$router.replace({ query: null })
    },
    clearData() {
      this.loading = false
      this.isDuplicate = false
      this.defaultArticle = {}
      this.currentArticle = {}
      this.$refs.articleFormLeft.resetValidation()
      this.$refs.articleFormRight.resetValidation()
      this.$refs.langSwitcher.resetLanguage()
    },
    async createOrUpdateArticle() {
      this.loading = true

      if (_.isEqual(this.currentArticle, this.defaultArticle)) {
        this.loading = false
        this.close()
        return
      }

      let article = {
        ...this.currentArticle,
        conditional: !!this.currentArticle.conditional,
        offensive: !!this.currentArticle.offensive,
        no_shipping: !!this.currentArticle.no_shipping,
        is_highlight: !!this.currentArticle.is_highlight,
        artist_resale_rights: !!this.currentArticle.artist_resale_rights,
        no_post_auction_sale: !!this.currentArticle.no_post_auction_sale,
        differential_taxation: !!this.currentArticle.differential_taxation,
      }
      delete (article.knockdown)
      delete (article.bidder_num)
      delete (article.type)
      delete (article.images)
      delete (article.image)
      delete (article.live_bidder)

      const isUpdate = article.id !== undefined;

      if (isUpdate) {
        if ((await this.updateArticle({ auctionId: this.currentAuction.id, article })) !== null) {
          this.SET_TEMP_ALERT({ flavor: 'success', content: this.$t('Values changed successfully') })
        } else {
          this.error = true
        }
      } else {
        if ((await this.addArticle({ auctionId: this.currentAuction.id, article })) !== null) {
          this.SET_TEMP_ALERT({ flavor: 'success', content: this.$t('Article added successfully') })
        } else {
          this.error = true
        }
      }
      this.loading = false
      this.$emit('reFetchArticles')
      this.close()
    },
    langChanged(lang) {
      this.selectedLang = lang
    },
    setTranslatableValue(field, val) {
      // only if the non-native language is selected
      this.isFormRightValid = (!this.selectedLang || this.selectedLang === this.appStoreLang) ? !(field === 'description' && val === '') : true // needed as ckeditor has no rules prop

      this.isFormRightValid = !(field === 'description' && val === '')// needed as ckeditor has no rules prop
      if (!this.selectedLang || this.selectedLang === this.appStoreLang) {
        this.currentArticle[field] = val
      } else {
        if (this.currentArticle[`${field}_i18n`]) {
          this.currentArticle[`${field}_i18n`] = {
            ...this.currentArticle[`${field}_i18n`],
            [this.selectedLang]: val
          }
        } else {
          this.currentArticle = {
            ...this.currentArticle,
            [`${field}_i18n`]: {
              [this.selectedLang]: val
            }
          }
        }
      }
    },
    inputLArticleNumberHandler (e) {
      this.disabledBidButton = this.selectedArticle.limit > +e;
      const expr = /^[0-9]*$/
      // allow the following keys to be able to still use the application properly
      if (!expr.test(e.key) && e.key !== 'Tab' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight' && e.key !== 'Backspace' && e.key !== 'Delete') {
        e.preventDefault()
      }
    },
    inputLimitHandler (e) {
      this.disabledBidButton = this.selectedArticle.limit > +e;
      const expr = /^[0-9]*$/
      // allow the following keys to be able to still use the application properly
      if (!expr.test(e.key) && e.key !== 'Tab' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight' && e.key !== 'Backspace' && e.key !== 'Delete') {
        e.preventDefault()
      }
    },
    inputTimedActiveFromHandler(e) {
      this.currentArticle = {
        ...this.currentArticle,
        timedData: {
          ...this.currentArticle.timedData,
          active_from: e
        }
      }
      /* setTimeout(() => {
        this.wrongDates = !this.$refs.articleFormLeft.validate()
      }, 1) */
    },
    inputTimedActiveUntilHandler(e) {
      this.currentArticle = {
        ...this.currentArticle,
        timedData: {
          ...this.currentArticle.timedData,
          active_until: e
        }
      }
      /* setTimeout(() => {
        this.wrongDates = !this.$refs.articleFormLeft.validate()
      }, 1) */
    },
  }
}

</script>
<style lang="scss">
.article-modal {
  .main-card {
    .form-container {
      .input-field {
        .v-input__slot {
          .v-text-field__slot {
            input {
              height: 30px !important;
              max-height: 30px !important;
            }
          }
        }
      }
    }
  }
}
</style>
<style scoped lang="scss">
.article-modal {
  .input-field {
    margin-top: 4px;
    width: 100% !important;
  }
}
.v-dialog__content {
    top: 50px;
    height: 98%;
}
.v-dialog--scrollable {
    .v-card > .v-card__text
    {
        overflow-y: unset;
    }
}
</style>
