<template>
  <v-overlay class="custom-back-drop status-modal">
    <div class="message-box-wrapper">
      <div class="message-box">
        <div class="message-content">
          <v-progress-circular
            class="loading-icon"
            indeterminate
            color="#555"
          />
          <br>
          <span class="loading-message">{{ $t('Loading...') }}</span>
        </div>
      </div>
    </div>
  </v-overlay>
</template>
<script>
export default {
  name: 'AppLoader'
}
</script>
