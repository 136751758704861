<template>
  <v-dialog width="600" v-model="dialog" @click:outside="closeModal" persistent>
    <v-card class="py-8">
      <v-card-title class="justify-center">
        <span class="headline">{{ $t('Do you want to buy the article?') }}
          <p> {{ $t('The price is') }} {{ buy_price }}</p></span>
      </v-card-title>

      <v-card-actions class="justify-center flex-wrap action-buttons">
        <v-btn class="btn primary-btn ma-2" @click="closeModal" :disabled="loading">
          <span>{{ $t('No') }}</span>
        </v-btn>
        <v-btn class="btn primary-btn ma-2" @click="$emit('submitBuyArticle')" :disabled="loading">
          <span>{{ $t('Yes') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'InstantBuyModal',
  props: {
    title: {},
    text: {},
    loading: Boolean
  },
  data() {
    return {
      dialog: false,
      buy_price: null
    }
  },
  methods: {
    openModal(buyPrice) {
      this.dialog = true
      this.buy_price = buyPrice
    },
    closeModal() {
      this.dialog = false
      this.$emit('clearSelectedArticle')
    }
  }
}
</script>

<style scoped lang="scss">
.btn {
  width: 140px;
}
</style>
