<template>
  <div class="auction-container settings users">
    <v-container class="table-template-wrap">
      <v-row class="table-container">
        <v-col sm="12" class="full-width">
          <v-row class="search-wrap">
            <v-col align="center">
              <div class="section-title mb-0">{{ $t('Users') }}</div>
            </v-col>
          </v-row>
        </v-col>
        <v-col align="center">
          <v-data-table
            :headers="headers"
            :items="tmpData"
            :items-per-page="itemPerPage"
            class="settings remove-border-last-col admin-edit-users ma-0 px-4 py-1"
            :item-class="rowClasses"
            must-sort
            @click:row="openUserProfile"
            :no-data-text="$t('No data available')"
            :footer-props="{
              'items-per-page-all-text': $t('All'),
              'items-per-page-text': $t('Rows per page'),
              'page-text': `{0} - {1} ${$t('of')} {2} ${$t('items')}`,
              'items-per-page-options': [5, 10 , 25 , 50 , 100]
            }"
          >
            <template #[`item.action`]="{ item }">
              <v-icon @click.stop="openKnockdownsModal(item.id)" small class="icon-with-hover">fa-gavel</v-icon>
              <v-icon @click.stop="handleOnClickDelete(item)" small class="ml-2 icon-with-hover">
                fa-trash-can
              </v-icon>
            </template>
            <template #[`item.index`]="{ item }">
              <span>{{ padStart(item.index, 4, '0') }}</span>
            </template>
            <template #[`item.doi`]="{ item }">
              <v-icon
                :color="item.doi ? 'green' : 'grey lighten-1'"
              >
                {{ item.doi ? 'fa-check-circle' : 'fa-circle' }}
              </v-icon>
            </template>
            <template #[`item.status`]="{ item }">
              <v-icon v-if="item.status === 'unlocked'" color="green">fa-check-circle</v-icon>
              <v-icon v-if="item.status === 'locked'" color="red">fa-times-circle</v-icon>
              <v-icon v-if="item.status === 'pending'" color="grey lighten-1">fa-circle</v-icon>
            </template>

            <template #[`item.last_name`]="{ item }">
              <span class="text-no-wrap">{{ item.last_name }}</span>
            </template>

            <template #[`item.last_login`]="{ item }">
              <span class="text-no-wrap">{{ item.last_login }}</span>
            </template>

            <template #[`item.initial_auth_provider`]="{ item }">
              <span class="text-no-wrap">{{ $t("authProvider." + item.initial_auth_provider) }}</span>
            </template>

            <template #top>
              <v-row>
                <v-col cols="3" class="pb-0">
                  <v-select
                    class="input-field mb-3"
                    :items="userRoles"
                    :label="$t('Role')"
                    v-model="userRole"
                    hide-details
                    @input="selectRole"
                  />
                </v-col>
                <v-col cols="9" class="pb-0">
                  <v-text-field
                    v-model="search"
                    append-icon="fa-magnifying-glass"
                    :label="$t('Search')"
                    hide-details
                    class="input-field mb-3"
                    @input="searchHandler"
                  />
                </v-col>
              </v-row>
            </template>
          </v-data-table>
          <div class="add-item-btn-container">
            <v-btn @click="$router.push({ name: 'AdminUserProfile' })" class="text-none">
              <v-icon class="mr-1">fa-circle-plus</v-icon>
              {{ $t('Create user') }}
            </v-btn>
            <v-btn @click="downloadUserExport" class="ml-2 text-none">
              <v-icon class="mr-1">fa-download</v-icon>
              {{ $t('Export users') }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <dialog-confirm
      ref="dialog"
      @onOk="dialogType === 'delete' ? deleteUser() : importUsers()"
      @onCancel="dialogType = null"
    />
    <admin-knockdowns-modal
      v-if="isMediaReady"
      :isMediaReady="isMediaReady"
      :auctions="auctions"
      :activeUserId="activeUserId"
    />
  </div>
</template>

<script>
import { Role } from '@/utils/constants'
import { processCSVFile, saveAsCSV } from '@/utils/file'
import _ from 'lodash'
import DialogConfirm from '@/components/dialog/dialog-confirm'
import updateData from '@/mixins/updateData'
import apiRequests from '@/api/apiRequests'
import { mapActions, mapState } from 'pinia'
import AdminKnockdownsModal from '@/views/knockdowns-admin/index'
import Vue from 'vue';
import { getBidderNumbersFromUser } from '@/services/user';
import dispatcher from '@/api/dispatch';
import moment from 'moment';
import useRootStore from '@/stores/rootStore';

const exportColumns = [
  {
    label: 'ID',
    key: 'id'
  },
  {
    label: 'Status',
    key: 'status',
  },
  {
    label: 'Verified',
    key: 'doi',
  },
  {
    text: 'Initial signup',
    value: 'initial_auth_provider',
  },
  {
    label: 'First Name',
    key: 'first_name'
  },
  {
    label: 'Last Name',
    key: 'last_name'
  },
  {
    label: 'Company',
    key: 'company_name'
  },
  {
    label: 'Email',
    key: 'email'
  },
  {
    label: 'Country',
    key: 'country'
  },
  {
    label: 'Street',
    key: 'street'
  },
  {
    label: 'Number',
    key: 'house_number'
  },
  {
    label: 'Zipcode',
    key: 'zipcode'
  },
  {
    label: 'City',
    key: 'city'
  },
  {
    label: 'Telephone 1',
    key: 'telephone1'
  },
  {
    label: 'Telephone 2',
    key: 'telephone2'
  },
  {
    label: 'Role',
    key: 'role'
  },
  {
    label: 'Language',
    key: 'language'
  },
  {
    label: 'Last Login',
    key: 'last_login'
  },
]

export default {
  name: 'UsersSetting',
  components: {
    DialogConfirm,
    AdminKnockdownsModal
  },
  async created () {
    await this.getUsers();
    await this.getAuctions()
    this.getUsersData()
    this.UPDATE_GLOBAL_STATE({
      key: 'appLoading',
      value: true,
    })
  },
  data: function () {
    return {
      search: '',
      fillDate: false,
      loading: true,
      itemPerPage: 10,
      page: 0,
      start: 0,
      end: 0,
      userRole: '',
      headers: [
        /* {
        text: this.$t('User #'),
        value: 'index',
        sortable: true,
      }, */
        {
          text: this.$t('Last name'),
          value: 'last_name',
          sortable: true,
        },
        {
          text: this.$t('First name'),
          value: 'first_name',
          sortable: true,
          width: 90
        },
        {
          text: this.$t('Status'),
          value: 'status',
          align: 'center',
        },
        {
          text: this.$t('Verified'),
          value: 'doi',
          align: 'center',
        },
        {
          text: this.$t('Email'),
          value: 'email',
          sortable: true,
        },
        {
          text: this.$t('Street'),
          value: 'street',
          sortable: true,
        },
        {
          text: this.$t('Number'),
          value: 'house_number',
          sortable: true,
        },
        {
          text: this.$t('Zipcode'),
          value: 'zipcode',
          sortable: true,
        },
        {
          text: this.$t('City'),
          value: 'city',
          sortable: true,
        },
        {
          text: this.$t('Country'),
          value: 'country',
          sortable: true,
        },
        {
          text: this.$t('Telephone1_admin'),
          value: 'telephone1',
          sortable: true,
        },
        {
          text: this.$t('Role'),
          value: 'role',
          sortable: true,
        },
        {
          text: this.$t('Last logon'),
          value: 'last_login',
          sortable: true,
        },
        {
          text: this.$t('Initial signup'),
          value: ('initial_auth_provider'),
          sortable: true,
        },
        {
          value: 'action',
          class: 'action-btns',
          width: 80,
          divider: false
        }
      ],
      newUsers: null,
      currentDeleteUser: null,
      dialogType: null, // delete, import
      auctions: null,
      activeUserId: null,
      searchResult: [],
      tmpData: [],
      fullUpdate: false
    }
  },
  mixins: [
    updateData
  ],
  computed: {
    ...mapState(useRootStore, ['appLocalization', 'users', 'adminDataChange']),
    isMediaReady() {
      return !!((this.appLocalization))
    },
    userRoles() {
      const roles = [{ text: this.$t('All'), value: '' }]
      for (const role of Object.keys(Role)) {
        roles.push({ value: role, text: this.$t(Role[role]) })
      }
      return roles
    },
  },
  watch: {
    adminDataChange: {
      deep: true,
      handler(val) {
        this.pushUpdate(val)
        this.tmpData = [...this.users]
      }
    }
  },
  methods: {
    ...mapActions(useRootStore, ['UPDATE_GLOBAL_STATE', 'fetchUsers', 'SET_USERS', 'SET_TEMP_ALERT']),
    rowClasses(item) {
      if (item.role === 'admin') return 'text-bold'
    },
    async getAuctions() {
      try {
        const data = await dispatcher.getAllAuctions();
        this.auctions = data
        this.pastAuctions = data.filter(el => el.status === 'paused' && el.updated_at)
        if (this.pastAuctions.length) {
          const bidderNumbers = await getBidderNumbersFromUser(this.userProfile.id)
          const auctions = bidderNumbers.map(el => this.pastAuctions.find(item => item.id === el.auction_id))
          if (auctions.length) {
            this.lastAuctionData = auctions[0]
            // this.participateModal = !_.isEmpty(this.lastAuctionData)
          }
        }
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error getting the auctions. Please try again later') });
        this.error = true;
      }
    },
    openUserProfile(value) {
      this.$router.push({ name: 'AdminUserProfile', params: { id: value.id } })
    },
    async getUsers() {
      try {
        this.loading = true;
        const result = await dispatcher.getUsersLastUpdatedTimestamp()
        const cacheUpdateTime = Vue.$cookies.get('users') ? moment(Vue.$cookies.get('users').userCacheLastUpdated) : null
        const responseUpdateTime = result.updated_at ? moment(result.updated_at) : null
        await this.fetchUsers(cacheUpdateTime && responseUpdateTime && responseUpdateTime.diff(cacheUpdateTime, 'seconds') > 0)
        this.tmpData = [...this.users]
        this.page = 0;
        this.loading = false;
      } catch (e) {
        this.error = true;
        this.loading = false;
      }
    },

    async deleteUser() {
      if (!this.currentDeleteUser) {
        return;
      }

      try {
        this.loading = true;
        await dispatcher.removeUser(this.currentDeleteUser.id);
        this.SET_USERS(this.users.filter(u => u.id !== this.currentDeleteUser.id))
        this.loading = false;
        this.SET_TEMP_ALERT({ flavor: 'success', content: this.$t('User deleted successfully') })
      } catch (e) {
        this.error = true;
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('User deletion failed, the user has data assigned') })
      }
      this.loading = false;
      this.currentDeleteUser = null;
      this.$refs.dialog.close();
    },

    async importUsers() {
      if (this.newUsers && this.newUsers.length > 0) {
        try {
          this.loading = true;
          await dispatcher.importUsers(this.newUsers)
          await this.fetchUsers(true);
        } catch (e) {
          this.error = true;
        }
        this.loading = false;
        this.newUsers = null;
        this.$refs.dialog.close();
      }
    },

    async handleImportedFile(file) {
      const { data } = await processCSVFile(file);

      this.newUsers = data.map(item => ({
        first_name: item[0],
        last_name: item[1],
        email: item[2].replace(/[\s\t\r]/g, ''),
        company_name: item[3],
        country: item[4],
        street: item[5],
        house_number: item[6],
        city: item[7],
        zipcode: item[8],
        telephone1: item[9],
        telephone2: item[10],
        password: (item[11] || '').replace(/[\s\t\r]/g, ''),
        role: 'user'
      }))

      this.openDialog(
        'import',
        'Import User',
        `Do you want import new ${this.newUsers.length} users? This action will delete the old users and cannot be undone.`
      );
    },

    async handleOnClickDelete(user) {
      this.currentDeleteUser = user;

      this.openDialog(
        'delete',
        this.$t('Delete User'),
        this.$t('Are you sure to delete this user?')
      );
    },

    padStart(...args) {
      return _.padStart(...args)
    },

    exportUsers() {
      const processedData = this.users.map(user => exportColumns.map(({ key }) => {
        return (user[key] || ' ').toString().replace(/[\r\t]/g, ' ');
      }));

      saveAsCSV(`${Date.now()}_export_users.csv`, [exportColumns.map(({ label }) => label)].concat(processedData));
    },

    openDialog(type, ...args) {
      this.dialogType = type;
      this.$refs.dialog.open('confirm', ...args);
    },
    openKnockdownsModal(id) {
      this.activeUserId = id
      this.UPDATE_GLOBAL_STATE({
        key: 'showBottomNavigation',
        value: false,
      })
      this.UPDATE_GLOBAL_STATE({
        key: 'showAdminKnockdownsModal',
        value: true,
      })
    },
    getUsersData() {
      if (this.search) {
        this.searchResult = []
        const tableSort = [...this.headers]
        tableSort.splice(this.headers.length - 1, 1)
        this.tmpData.forEach(el => {
          tableSort.forEach(val => {
            if (el[val.value] && el[val.value].toString().toLowerCase().includes(this.search.toLowerCase()) && this.searchResult.map(({ id }) => id).indexOf(el.id) === -1) {
              this.searchResult.push(el)
            }
          })
        })
        this.tmpData = [...this.searchResult]
      }
    },
    changePage(el) {
      this.page = el
    },
    searchHandler: _.debounce(function () {
      this.selectRole()
      if (this.search) {
        this.tmpData = this.tmpData.filter(el => (el.index && el.index.toString().includes(this.search)) ||
          (el.number_optional && el.number_optional.includes(this.search)) ||
          el.first_name.toLowerCase().includes(this.search.toLowerCase()) ||
          el.last_name.toLowerCase().includes(this.search.toLowerCase()) ||
          el.email.toLowerCase().includes(this.search.toLowerCase()))
      }
      this.getUsersData()
    }, 500),
    selectRole() {
      this.page = 0
      if (this.userRole === '') {
        this.tmpData = [...this.users]
      } else {
        this.tmpData = this.users.filter(el => el.role === this.userRole)
      }
      this.getUsersData()
    },
    async downloadUserExport() {
      try {
        await dispatcher.exportUsers(`users-export-${moment().format('DDMMYYYY')}.csv`)
        this.SET_TEMP_ALERT({ flavor: 'success', content: this.$t('Export succeeded') })
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error during the export') })
      }
    },
  }
}
</script>
<style lang="scss">
.auction-container {
  &.users {
    .v-data-table>.v-data-table__wrapper>table>tbody>tr>td,
    .v-data-table>.v-data-table__wrapper>table>tfoot>tr>td,
    .v-data-table>.v-data-table__wrapper>table>thead>tr>td {
      height: 36px;
    }
  }
}
</style>
<style lang="scss" scoped>
.auction-container {
  padding-bottom: 80px;
}
</style>
