<template>
  <div id="app-user-auction-container" class="auction-container auctioneer-screens pt-0" v-if="appLoading">
    <!--<v-overlay v-if="socketConnectionStatus !== SocketConnectionStatus.connected">
      <div style="margin:3em;padding:2em;border-radius:3px;background-color:white;color:black;">
        <span>{{ $t('Your internet connection is unstable. Please refresh the page if this message persists.') }}</span>
      </div>
    </v-overlay>-->
    <v-row v-if="ongoingArticle">
      <v-col xs="12">
        <h1 class="auctioneer-screen-title">{{ $t('Auctioneers Screen') }}</h1>
      </v-col>
    </v-row>
    <v-row class="auction-wrapper" justify="center" v-if="ongoingArticle">
      <v-col md="5" class="mobile-full">
        <div class="item-information">
          <v-row class="no-gutters">
            <v-col cols="6">
              <span class="light-title">{{ $t('Lot') }}</span>&nbsp;
              <span class="price-value">#{{ ongoingArticle.number_optional || _.padStart(ongoingArticle.number, 4, '0') }}</span>
            </v-col>
            <v-col cols="6" class="price-wrapper no-gutters" v-if="ongoingArticle.limit">
              <span class="light-title">{{ $t('Limit') }}</span>&nbsp;
              <span class="price-value"> {{ currency }}{{ euNumbers(ongoingArticle.limit) }}</span>
            </v-col>
          </v-row>
          <v-row class="price-wrapper no-gutters" v-if="ongoingArticle.estimated_price">
            <span class="light-title">{{ $t('Est.') }}</span>&nbsp;
            <span class="price-value"> {{ currency }}{{ ongoingArticle.estimated_price }}</span>
          </v-row>
          <div class="auctioneer-item-image">
            <img :src="itemThumbnail">
          </div>
        </div>
      </v-col>
      <v-col md="5" class="mobile-full">
        <div class="item-bid-information">
          <div class="hammer-icon-container" v-if="ongoingArticle.last_ask_price > 0">
            <span v-if="isArticleSoldOut">{{ $t('Sold') }}:</span>
            <span v-else>{{ $t('Hammer') }}:</span>
            <span v-if="currentHighestBid.value > 0" class="bold-500 bid-value"> {{ currency }}{{ euNumbers(currentHighestBid.value) }} </span>
            <span v-else class="bold-500 bid-value"> {{ currency }} --- </span>
            <span v-if="currentHighestBid.value > 0" class="auctioneer-bid-type" :class="{'auctioneer-color-red': historyLatestBid.type === 'live'}"> ({{ $t(historyLatestBid.type) }}) </span>
          </div>
          <div>
            <span>{{ $t('Ask_2') }}: </span>
            <span class="bold-500 bid-value"> {{ currency }}{{ euNumbers(nextBidAmount) }} </span>
          </div>
          <div
            class="font-weight-bold top-bid-user mt-16"
            v-if="currentHighestBid.value > 0 && isArticleSoldOut"
            :class="{'auctioneer-color-red': historyLatestBid.type === 'live'}"
          >
            <span>{{ $t('Paddle') }}: </span>
            <span>#{{ _.padStart(historyLatestBid.bidder_number, 4, '0') }}</span>
          </div>
          <p v-if="isLastArticleSoldOut && isArticleSoldOut" class="bold-500  sold-out-last auctioneer-color-green">
            <span>{{ $t('Last article sold out. The auction will end immediately.') }}</span>
          </p>
        </div>
      </v-col>
    </v-row>

    <!-- Todo : We need to change this to component -->
    <v-overlay class="custom-back-drop status-modal" v-if="renderComponent && loadingModal">
      <div class="message-box-wrapper">
        <div class="message-box">
          <div class="message-content">
            <v-progress-circular
              class="loading-icon"
              indeterminate
              color="#555"
            />
            <br>
            <span class="loading-message">{{ getLoadingMessage() }}</span>
          </div>
        </div>
      </div>
    </v-overlay>
    <v-overlay class="custom-back-drop status-modal" v-if="showNetworkError">
      <div class="message-box-wrapper">
        <div class="message-box">
          <div class="message-content">
            <v-progress-circular
              class="loading-icon"
              indeterminate
              color="#555"
            />
            <br>
            <span class="loading-message">{{ getLoadingMessage() }}</span>
          </div>
        </div>
      </div>
    </v-overlay>

    <v-overlay class="custom-back-drop no-ongoing-article-modal" v-if="currentAuction.status === 'started' && _.isEmpty(ongoingArticle)">
      <div class="message-box-wrapper">
        <div class="message-box">
          <div class="message-content">
            <v-progress-circular
              class="loading-icon"
              indeterminate
              color="#555"
            />
            <br/>
            <span class="loading-message">{{ $t('No ongoing article found in the live auction. Please wait') }}</span>
          </div>
        </div>
      </div>
    </v-overlay>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import SocketClient from '@/utils/socket'
import SocketHandlers, { SocketHandleEmitterEvent } from '@/api/socketHandlers';
import tinykeys from '@/utils/tiny-keys'
import _ from 'lodash';
import useRootStore from '@/stores/rootStore';
import useAuctionStore from '@/stores/auctionStore';
import { SocketConnectionStatus } from '@/utils/constants';
import { euNumbers } from '@/services/i18n';

export default {
  name: 'AuctionAuctioneer',
  async created () {
    // Check the status to if it's Sold Out
    try {
      // Listener
      this.setUpSocket()

      this.UPDATE_GLOBAL_STATE({
        key: 'appLoading',
        value: false,
      })
      let rs = await this.getCurrentActiveAuction()
      // No live auction started
      if (_.isEmpty(rs)) {
        this.CHANGE_STATE([
          {
            key: 'loadingModal',
            value: true,
          },
          {
            key: 'loadingType',
            value: 'noLive',
          }])
      }
      // Hide modal if auction is available
      if (rs) {
        if (rs.status === 'paused') {
          if (this.showNetworkError) {
            this.CHANGE_STATE([
              {
                key: 'loadingModal',
                value: true,
              },
              {
                key: 'loadingType',
                value: 'networkPause',
              }])
          } else {
            this.CHANGE_STATE([
              {
                key: 'loadingModal',
                value: true,
              },
              {
                key: 'loadingType',
                value: 'paused',
              }])
          }
        }

        if (rs.status === 'started') {
          this.CHANGE_STATE({
            key: 'loadingModal',
            value: false,
          })
        }
      }

      this.UPDATE_GLOBAL_STATE({
        key: 'appLoading',
        value: true,
      })

      if (this.ongoingArticle.id) {
        this.articleGallery = this.ongoingArticle.images
      } else {
        this.articleGallery = []
      }

      this.removeListener = tinykeys(window, {
        l: () => {
          this.logoutHandler()
        }
      })
    } catch (e) {
      console.log(e)
      this.UPDATE_GLOBAL_STATE([
        {
          key: 'error',
          value: true,
        },
        {
          key: 'errorMessage',
          value: e.message,
        },
      ])
      this.UPDATE_GLOBAL_STATE({
        key: 'appLoading',
        value: true,
      })
    }
  },
  beforeDestroy() {
    const emitter = SocketHandlers.getEmitter();
    emitter.off(SocketHandleEmitterEvent.startAuctionPostHandleEmitterEvent, this.startAuctionPostHandleEmitterEvent);
    emitter.off(SocketHandleEmitterEvent.resumeAuctionPostHandleEmitterEvent, this.resumeAuctionPostHandleEmitterEvent);
    emitter.off(SocketHandleEmitterEvent.sellItemPostHandleEmitterEvent, this.sellItemPostHandleEmitterEvent);
    this.removeListener();
  },
  async destroyed() {
    if (this.socket.disconnect) {
      this.socket.disconnect()
    }
  },
  data: () => ({
    slideModel: null,
    items: [],
    showNotiTooltip: false, // When user got higher bid
    notificationMessage: '',
    currentMobilePage: 1,
    showMobileArticleModal: false,
    articleGallery: [],
    showNetworkError: false,
    isArticleSoldOut: false,
    isLastArticleSoldOut: false,
    disconnected: false,
    cachedCurrentArticle: null,
    renderComponent: true,
    showPushNotiTooltipTest: true,
    historyLatestBid: {},
    removeListener: null
  }),
  watch: {
    async ongoingArticle() {
      if (this.currentAuction && this.currentAuction.id) {
        if (this.ongoingArticle.id !== this.cachedCurrentArticle) {
          this.cachedCurrentArticle = this.ongoingArticle.id
          this.articleGallery = this.ongoingArticle.images
        }
      }
    },
    async currentAuction() {
      if (this.currentAuction) this.historyLatestBid = this.currentAuction.highestBid

      this.isArticleSoldOut = (this.currentAuction && this.ongoingArticle && this.ongoingArticle.status === '0');
    },
    async isOffline () {
      if (!this.isOffline) {
        this.disconnected = false
        this.showNetworkError = false
        await this.getCurrentActiveAuction()
      } else {
        this.disconnected = true
        this.showNetworkError = true
      }
    }
  },
  computed: {
    ...mapState(useAuctionStore, ['currentAuction', 'loadingModal', 'loadingType', 'showPushNotiTooltip', 'currentAuctionArticles', 'isAdminLogout', 'ongoingArticle', 'currentHighestBid', 'nextBidAmount', 'currentStepValue', 'bidHistory']),
    ...mapState(useRootStore, ['userProfile', 'notificationList', 'showBottomNavigation', 'appLoading', 'appStoreLang', 'appSettings', 'appLocalization', 'isMobile', 'appFeatures', 'isOffline', 'socket', 'socketConnectionStatus']),
    currency() {
      return this.appLocalization.currency
    },
    getArticleNumber() {
      return this.ongoingArticle.number_optional || this.ongoingArticle.number
    },
    isMediaReady() {
      return !!((this.userProfile.id && this.appLocalization))
    },
    isArticleReady() {
      const { ongoingArticle } = this.currentAuction
      if (ongoingArticle) {
        return ongoingArticle.last_ask_price === 0 || this.userProfile.id === this.currentHighestBid.id || ongoingArticle.status !== '2'
      }
      return false
    },
    itemThumbnail() {
      return (this.articleGallery.length) ? this.articleGallery[0].image : ''
    },
    // historyLatestBid(){
    //   return (this.bidHistory.length) ? this.bidHistory[this.bidHistory.length - 1] : {}
    // }
    SocketConnectionStatus() {
      return SocketConnectionStatus
    }
  },
  methods: {
    ...mapActions(useAuctionStore, ['getCurrentActiveAuction', 'CHANGE_STATE', 'SET_IS_ADMIN_LOGOUT', 'getAuctionArticles']),
    ...mapActions(useRootStore, ['UPDATE_GLOBAL_STATE', 'SET_LOGOUT_CLICKED', 'getNotification', 'markReadNotification', 'logout', 'SET_SOCKET_DATA']),
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;
      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    euNumbers,
    startAuctionPostHandleEmitterEvent(data) {
      this.CHANGE_STATE(
        [{
           key: 'loadingModal',
           value: false,
         },
         {
           key: 'loadingType',
           value: 'started',
         }])
    },
    resumeAuctionPostHandleEmitterEvent(data) {
      this.SET_IS_ADMIN_LOGOUT(false)
    },
    async sellItemPostHandleEmitterEvent(data) {
      this.isArticleSoldOut = true
      this.forceRerender()
    },
    setUpSocket() {
      // connect to user namespace
      const { socket } = SocketClient.getInstance();
      const emitter = SocketHandlers.getEmitter();

      // Start auction (migrated to socket handlers)
      emitter.on(SocketHandleEmitterEvent.startAuctionPostHandleEmitterEvent, this.startAuctionPostHandleEmitterEvent);

      // Resume (migrated to socket handlers)
      emitter.on(SocketHandleEmitterEvent.resumeAuctionPostHandleEmitterEvent, this.resumeAuctionPostHandleEmitterEvent);

      // Sell item (migrated to socket handlers)
      emitter.on(SocketHandleEmitterEvent.sellItemPostHandleEmitterEvent, this.sellItemPostHandleEmitterEvent);

      this.SET_SOCKET_DATA(socket)
    },
    getLoadingMessage() {
      if (this.showNetworkError) {
        return this.$t('auctionLoadingState.userNetworkIssue')
      }
      return this.$t('auctionLoadingState.' + this.loadingType)
    },
    logoutHandler() {
      this.SET_LOGOUT_CLICKED(true)
      this.logout()
    }
  },
}
</script>
<style lang="scss" scoped>
</style>
