<template>
  <v-dialog max-width="400" v-model="dialog" @click:outside="$emit('close')">
    <v-card-title class="headline">{{ $t('Do you really want to start the current auction?') }}</v-card-title>
    <v-card-text v-if="auction.type !== 'live'">{{ $t('If you start an auction manually, it will override the automatic start date if set on that auction') }}</v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn class="text-none" @click="closeModal">{{ $t('No') }}</v-btn>
      <v-btn class="text-none" color="success" @click="$emit('submit')">{{ $t('Yes') }}</v-btn>
    </v-card-actions>
  </v-dialog>
</template>

<script>
export default {
  name: 'StartAuctionModal',
  props: {
    auction: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      dialog: false,
      force: true
    }
  },
  methods: {
    openModal() {
      this.dialog = true
    },
    closeModal() {
      this.dialog = false
      this.force = true
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
.btn {
  width: 140px;
}
</style>
