import { localFeatures } from '@/utils/constants';
import _ from 'lodash'
import { getTranslatedText } from '@/services/i18n';

/**
 * Translate a category name
 * @param {any} category - The category object
 * @param {string} lang - 2-digit language code
 * @return {string} - the translated category name
 */
export function translateCategoryName(category: any, lang: string): string {
  return getTranslatedText(category, 'name', lang)
}

/**
 * Sort categories according to the fixed order (see constants.ts)
 * @param {any[]} categories - The categories to sort
 * @param {string} lang - 2-digit language code
 * @return {any[]} - The sorted categories
 */
export function sortCategories(categories: any[], lang: string): any[] {
  let source = [...categories];
  let result;
  const sortFunction = (a, b) => translateCategoryName(a, lang).localeCompare(translateCategoryName(b, lang));
  if (!localFeatures.categoriesFixedSortOrder || localFeatures.categoryFixedSortOrderValues.length === 0) {
    result = source.sort(sortFunction);
  } else {
    result = [];
    // add elements in the order of the fixed sort order
    for (const index of localFeatures.categoryFixedSortOrderValues) {
      const foundIndex = source.findIndex(e => e.id === index);
      if (foundIndex > -1) {
        result.push(source[foundIndex]);
        source.splice(foundIndex, 1);
      }
    }
    // append the rest of the categories at the end of the result
    if (source.length > 0) {
      if (source.length > 1) {
        source.sort(sortFunction);
      }
      result.push(...source);
    }
  }
  return _.uniqBy(result, 'id');
}
