const inputHandlers = {

  inputZipHandler(e) {
    const expr = /^[a-zA-Z]|[0-9]\d*$/
    // allow the following keys to be able to still use the application properly
    if (!expr.test(e.key) && e.key !== 'Tab' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight' && e.key !== 'Backspace' && e.key !== 'Delete') {
      e.preventDefault()
    }
  },
  inputPhoneHandler (e) {
    const expr = /^[0-9+]*$/
    // allow the following keys to be able to still use the application properly
    if (!expr.test(e.key) && e.key !== 'Tab' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight' && e.key !== 'Backspace' && e.key !== 'Delete') {
      e.preventDefault()
    }
  },
}

export default inputHandlers;
