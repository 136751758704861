<template>
  <div class="article-list mr-3 pl-1" :class="{ active: articlesListActive }">
    <v-btn class="toggle-btn" @click="toggleSlider">
      <v-icon class="close-modal-icon" v-if="articlesListActive">fa-times</v-icon>
      <v-icon class="close-modal-icon" v-else>fa-arrow-right</v-icon>
      <p class="articles-length">{{ $t('Article Overview') }} ({{ tmpArticles.length }})</p>
    </v-btn>
    <v-progress-circular v-if="loading" class="d-block mx-auto mt-6" indeterminate />
    <v-virtual-scroll
      class="article-group"
      v-else-if="tmpArticles.length"
      :bench="0"
      :items="tmpArticles"
      :height="sliderHeight"
      :item-height="itemHeight"
      ref="scrollContainer"
    >
      <template #default="{ item }">
        <v-card
          class="mx-auto ma-4 article-card"
          :class="[ item.id === ongoingArticle.id ? 'border-blue':'' ]"
          elevation="0"
        >
          <v-img :src="item.image" width="115px" style="margin: 0 auto;" :contain="true" />

          <div class="article-card__content">
            <v-card-subtitle class="article-title pt-0 px-2">
              {{ articleTitle(item) }}
            </v-card-subtitle>

            <v-card-subtitle class="article-description px-2">
              <div class="article-desc-block">
                <v-row>
                  <v-col>
                    <div>
                      <span>{{ $t('Article:') }}</span> {{ getArticleNumber(item) }}
                    </div>
                    <div v-if="item.limit && item.limit !== 0">
                      <span>{{ $t('Limit:') }}</span> {{ currency }}{{ euNumbers(item.limit) }}
                    </div>
                    <div v-if="item.estimated_price && item.estimated_price !== 0">
                      <span>{{ $t('Estimated Price:') }}</span> {{ currency }}{{ item.estimated_price }}
                    </div>
                  </v-col>
                  <v-col class="pb-0" v-if="item.sold_price">
                    <div v-if="item.sold_price && item.sold_price !== 0" class="text-bold">
                      <span>{{ $t('Knockdown Price:') }}</span> {{ currency }}{{ euNumbers(item.sold_price) }}<br>
                    </div>
                    <!--                  <div class="slider-sold-out" v-if="item.sold_price">-->
                    <!--                    <span v-translate v-if="item.bidder_number && item.type === 'live'">This item is sold out to you</span>-->
                    <!--                    <span v-else v-translate>This item is sold out</span>-->
                    <!--                  </div>-->
                    <div :class="{ live: item.live_bidder }">
                      <span v-if="item.live_bidder">{{ $t('Live bidder:') }}</span>
                      <span v-else>{{ $t('Room bidder') }}</span>
                      <span v-if="item.bidder_number"> {{ item.bidder_number }}</span><br>
                    </div>
                    <span key="item.status.0" v-if="item.status === '0'" class="admin-status-text">{{ $t('Sold') }}</span>
                    <span key="item.status.1" v-if="item.status === '1'" class="admin-status-text">{{ $t('Upcoming') }}</span>
                    <span key="item.status.2" v-if="item.status === '2'" class="admin-status-text">{{ $t('Current') }}</span>
                    <span key="item.status.3" v-if="item.status === '3'" class="admin-status-text">{{ $t('Skipped') }}</span>
                    <span key="item.status.4" v-if="item.status === '4'" class="admin-status-text">{{ $t('Passed') }}</span>
                  </v-col>
                  <v-col class="pb-0" v-else-if="item.id === ongoingArticle.id && isArticleSoldOut">
                    <div class="text-bold">
                      <span>{{ $t('Knockdown Price:') }}</span> {{ currency }}{{ euNumbers(currentHighestBid.value) }}<br>
                    </div>
                    <span key="item.status.0" v-if="item.sold_price !== 0 || item.status === '0'" class="admin-status-text">{{ $t('Sold') }}</span>
                    <span key="item.status.1" v-else-if="item.status === '1'" class="admin-status-text">{{ $t('Upcoming') }}</span>
                    <span key="item.status.2" v-else-if="item.status === '2'" class="admin-status-text">{{ $t('Current') }}</span>
                    <span key="item.status.3" v-else-if="item.status === '3'" class="admin-status-text">{{ $t('Skipped') }}</span>
                    <span key="item.status.4" v-else-if="item.status === '4'" class="admin-status-text">{{ $t('Passed') }}</span>
                  </v-col>
                  <v-col class="pb-0" v-else>
                    <span key="item.status.0" v-if="item.status === '0'" class="admin-status-text">{{ $t('Sold') }}</span>
                    <span key="item.status.1" v-if="item.status === '1'" class="admin-status-text">{{ $t('Upcoming') }}</span>
                    <span key="item.status.2" v-if="item.status === '2'" class="admin-status-text">{{ $t('Current') }}</span>
                    <span key="item.status.3" v-if="item.status === '3'" class="admin-status-text">{{ $t('Skipped') }}</span>
                    <span key="item.status.4" v-if="item.status === '4'" class="admin-status-text">{{ $t('Passed') }}</span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6" class="py-0" v-if="+item.status === 1 || +item.status === 3">
                    <v-btn class="btn jump-btn" @click="askJump(item.id);$emit('articleJump', true);" :disabled="currentAuction.status === 'paused'">
                      <span>{{ $t('Jump to this') }}</span>
                    </v-btn>
                  </v-col>
                  <v-col cols="6" class="py-0" v-if="+item.status === 0 || +item.status === 4">
                    <v-btn class="btn jump-btn" @click="askJump(item.id, true);$emit('articleJump', true);" :disabled="currentAuction.status === 'paused'">
                      <span>{{ $t('Re-auction') }}</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-card-subtitle>
          </div>
        </v-card>
      </template>
    </v-virtual-scroll>
    <div class="article-group" v-else>
      <h4 class="text-center px-5">{{ $t('No articles found') }}</h4>
    </div>
    <div class="article-list__footer" v-if="!loading">
      <v-text-field
        append-icon="fa-magnifying-glass"
        :label="$t('Search')"
        hide-details
        @input="searchHandler"
      />
      <v-icon small class="mt-3 ml-2 icon-with-hover" @click="$emit('syncData')">fa-sync</v-icon>
    </div>
    <v-overlay class="custom-back-drop footer-crawler-modal" v-if="jumpLoadingModal" :class="{ ongoing: !_.isEmpty(ongoingArticle)}">
      <div class="message-box-wrapper">
        <div class="message-box">
          <div class="message-content">
            <v-icon class="warning-icon">fa-triangle-exclamation</v-icon>
            <br>
            <p class="loading-message">{{ getJumpLoadingMessage() }}</p>
            <v-card-actions>
              <v-spacer />
              <v-btn class="btn primary-btn" @click="CHANGE_STATE([{key: 'jumpLoadingModal', value: false}, {key: 'reOffered', value: false}]);$emit('articleJump',false);">{{ $t('Cancel') }}</v-btn>
              <v-btn class="btn primary-btn" @click="jumpArticle()">{{ $t('Yes') }}</v-btn>
            </v-card-actions>
          </div>
        </div>
      </div>
    </v-overlay>
    <v-overlay class="custom-back-drop footer-crawler-modal" v-if="reauctionLoadingModal">
      <div class="message-box-wrapper">
        <div class="message-box">
          <div class="message-content">
            <v-icon class="warning-icon">fa-triangle-exclamation</v-icon>
            <br>
            <span class="loading-message">{{ getReauctionLoadingMessage() }}</span>
            <div class="button-list">
              <v-btn
                class="btn primary-btn cancel-btn"
                @click="CHANGE_STATE([{key: 'reauctionLoadingModal', value: false}, {key: 'reOffered', value: false}]);$emit('articleJump', false);"
              >
                <span>{{ $t('Cancel') }}</span>
              </v-btn>
              <v-btn class="btn primary-btn yes-stop-btn" @click="jumpArticle()"><span>{{ $t('Yes') }}</span></v-btn>
            </div>
          </div>
        </div>
      </div>
    </v-overlay>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import _ from 'lodash'
import tinykeys from '@/utils/tiny-keys';
import dispatcher from '@/api/dispatch';
import useRootStore from '@/stores/rootStore';
import useAuctionStore from '@/stores/auctionStore';
import { searchArticles } from '@/services/article';
import { euNumbers, getTranslatedText } from '@/services/i18n';

export default {
  name: 'AdminArticlesListDesktop',
  props: {
    isArticleSoldOut: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: true
    },
    articleIsStarted: {
      type: Boolean,
      default: true
    },
    cachedCurrentArticle: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      prevId: null,
      search: '',
      tmpArticles: [],
      itemHeight: 160,
      prevArticleId: null
    }
  },
  created() {
    this.removeListener = tinykeys(window, {
      Enter: async () => {
        if (this.jumpLoadingModal || this.reauctionLoadingModal) this.jumpArticle()
      },
      Escape: async () => {
        if (this.jumpLoadingModal) {
          this.CHANGE_STATE({ key: 'jumpLoadingModal', value: false })
        } else if (this.reauctionLoadingModal) {
          this.CHANGE_STATE({ key: 'reauctionLoadingModal', value: false })
        }
      }
    })
  },
  beforeDestroy() {
    this.removeListener()
  },
  watch: {
    loading(val) {
      if (!val) {
        this.scrollToActiveArticle()
      }
    },
    currentAuction: {
      deep: true,
      handler() {
        const idx = this.currentAuctionArticles.findIndex(el => el.id === this.ongoingArticle.id)
        this.currentAuctionArticles.splice(idx, 1, this.ongoingArticle)
      }
    },
    currentAuctionArticles: {
      deep: true,
      handler(val) {
        let auctionNumber = this.currentAuction.code
        let mediaServer = this.appLocalization.media_server_url
        this.tmpArticles = val.map((itm) => {
          let articleNumber = itm.number
          let imageUrl = `${mediaServer}/${auctionNumber}/${articleNumber}.jpg`
          return { ...itm, image: imageUrl }
        })
      }
    },
    async ongoingArticle(val) {
      const index = this.tmpArticles.findIndex(el => el.id === val.id)
      this.tmpArticles.splice(index, 1, {
        ...this.tmpArticles[index],
        ...val
      })
      const scrollTo = index * this.itemHeight
      if (val.id !== this.prevId) {
        this.$nextTick(() => {
          if (this.$refs.scrollContainer && this.$refs.scrollContainer.$el.scrollTop !== scrollTo) this.$refs.scrollContainer.$el.scrollTop = scrollTo
        })
        this.prevId = val.id
        this.getArticles()
      }
    }
  },
  computed: {
    ...mapState(useAuctionStore, ['currentAuction', 'currentAuctionArticles', 'jumpLoadingModal', 'reauctionLoadingModal', 'askJumpId', 'articlesListActive', 'ongoingArticle', 'currentHighestBid']),
    ...mapState(useRootStore, ['appLoading', 'userProfile', 'appLocalization', 'appStoreLang', 'isMobile', 'appFeatures']),
    currency () {
      return this.appLocalization.currency
    },
    sliderHeight() {
      return window.innerHeight - 150
    },
    getArticlesWithImages() {
      let auctionNumber = this.currentAuction.code
      let mediaServer = this.appLocalization.media_server_url
      return this.currentAuctionArticles.map((itm) => {
        let articleNumber = itm.number
        let imageUrl = `${mediaServer}/${auctionNumber}/${articleNumber}.jpg`
        return { ...itm, image: imageUrl }
      })
    },
    loadingMessage() {
      return {
        stop: this.$t('Do you really want to stop the active auction?'),
        jump: this.$t('Do you really want to jump to another article?'),
        reauction: this.$t('Re-auction will override old bids, are you sure to continue?')
      }
    }
  },
  methods: {
    ...mapActions(useAuctionStore, ['CHANGE_STATE', 'TOGGLE_CRAWLER', 'getAuctionArticles', 'jumpToArticle']),
    euNumbers,
    getArticleNumber(itm) {
      return itm.number_optional || `#${_.padStart(itm.number, 4, '0')}`
    },
    getReauctionLoadingMessage () {
      return this.loadingMessage.reauction
    },
    getJumpLoadingMessage () {
      return this.loadingMessage.jump
    },
    async getArticles() {
      let knockdownsResp
      try {
        knockdownsResp = this.articlesListActive ? await dispatcher.getKnockdownsArticleSlider(this.currentAuction.id) : null
      } catch (e) {
        this.CHANGE_STATE({
          key: 'currentAuctionArticles',
          value: [],
        })
        this.tmpArticles = []
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('UThere was an error getting the article slider data. Please try again later') })
      }
      let auctionNumber = this.currentAuction.code
      let mediaServer = this.appLocalization.media_server_url

      if (this.currentAuction && this.currentAuctionArticles.length) {
        this.tmpArticles = this.currentAuctionArticles.map((itm) => {
          let articleNumber = itm.number
          let imageUrl = `${mediaServer}/${auctionNumber}/${articleNumber}.jpg`
          const updatedVal = knockdownsResp ? knockdownsResp.find(el => el.article_id === itm.id) : null
          // delete (itm.sold_price)
          if (updatedVal) return { ...itm, image: imageUrl, ...updatedVal }
          return { ...itm, image: imageUrl }
        })
      } else {
        this.CHANGE_STATE({
          key: 'currentAuctionArticles',
          value: [],
        })
        this.tmpArticles = []
      }
    },
    articleTitle (article) {
      return getTranslatedText(article, 'name', this.appStoreLang)
    },
    articleDescription (article) {
      return getTranslatedText(article, 'description', this.appStoreLang)
    },
    searchArticles,
    searchHandler: _.debounce(function (e) {
      if (e) {
        this.tmpArticles = this.searchArticles(e, this.getArticlesWithImages, this.appStoreLang, this.appLocalization.i18n.defaultLanguage.lang)
      } else {
        this.tmpArticles = [...this.getArticlesWithImages]
        this.scrollToActiveArticle()
      }
    }, 500),
    askJump (id, reauction) {
      let updateData = [{ key: 'askJumpId', value: id }];
      if (reauction) { // Show reauction
        updateData.push({
          key: 'reauctionLoadingModal',
          value: true,
        })
      } else {
        updateData.push({
          key: 'jumpLoadingModal',
          value: true,
        })
      }

      this.CHANGE_STATE(updateData)
    },
    async jumpArticle () {
      let soldPrice = 0
      let bidderNumber = null
      let liveBidder = false
      if (this.isArticleSoldOut) {
        soldPrice = this.currentAuction.highestBid.bid
        bidderNumber = this.currentAuction.highestBid.bidder_number
        liveBidder = this.currentAuction.highestBid.type === 'live'
      }
      const id = this.ongoingArticle.id
      await this.jumpToArticle({ articleId: this.askJumpId, auctionId: this.currentAuction.id, prevId: this.cachedCurrentArticle, articleIsStarted: this.articleIsStarted, sold_price: soldPrice, bidder_number: bidderNumber, live_bidder: liveBidder})
      this.CHANGE_STATE([
        {
          key: 'jumpLoadingModal',
          value: false,
        },
        {
          key: 'reauctionLoadingModal',
          value: false,
        },
        {
          key: 'reOffered',
          value: id === this.askJumpId,
        }])
    },
    scrollToActiveArticle() {
      setTimeout(() => {
        const index = this.tmpArticles.findIndex(el => el.id === this.ongoingArticle.id)
        const scrollTo = index * this.itemHeight
        if (this.$refs.scrollContainer && this.$refs.scrollContainer.$el.scrollTop !== scrollTo) this.$refs.scrollContainer.$el.scrollTop = scrollTo
      }, 500)
    },
    toggleSlider() {
      this.CHANGE_STATE({ key: 'articlesListActive', value: !this.articlesListActive })
      if (this.articlesListActive) this.getArticles()
    }
  }
}
</script>

<style scoped lang="scss">
.toggle-btn {
  position: absolute;
  text-align: center;
  height: 20px !important;
  width: auto;
  min-width: 20px !important;
  background-color: transparent !important;
  box-shadow: none !important;
  text-transform: none;
  font-size: 12px;
  font-weight: normal;
  line-height: 20px;
  letter-spacing: unset !important;
  padding: 0!important;
  top: 0;
  right: -5px;
  transform: translateX(100%);
  transition: all .3s ease;
  .articles-length {
    margin: 0 0 0 4px;
  }
  &:before{
    display: none;
  }
  .v-ripple__container {
    display: none !important;
  }
  &:hover {
    .v-icon {
      color: #000 !important;
    }
  }
  .v-icon {
    font-size: 14px;
    width: 14px;
    height: 14px;
    background: transparent;
    border-radius: 8px;
    color: #676767 !important;
    border: 1px solid transparent;
    transition: all 0.3s ease;
  }
}
.auction-container {
  .article-list {
    left: -380px;
    &.active {
      left: 0;
      margin-left: -80px;
    }
    .article-group {
      .article-card {
        height: 160px;
      }
    }
  }
}
.live {
  color: #8b141e;
}
</style>
