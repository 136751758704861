<template>
  <v-dialog
    v-model="isOpen"
    width="842"
    content-class="overflow-visible"
    @keydown.esc="close()"
    persistent
  >
    <v-card class="auction-modal category-modal pa-0 pt-5">
      <v-btn class="modal-close-btn icon-with-hover" icon @click="close()"><v-icon>fa-times</v-icon></v-btn>
      <v-card-text class="main-card">
        <v-container>
          <v-row align="center" class="form-container">
            <v-col align="center">
              <div class="form-title">{{ $t('Add new auction') }}</div>
              <v-form ref="form" v-model="isFormValid">
                <v-row>
                  <v-col cols="4" class="pb-2">
                    <v-select
                      class="input-field mt-0"
                      :items="typeItems"
                      :label="$t('Type')"
                      :rules="[validationRequired()]"
                      v-model="auction.type"
                      required
                      @input="setType"
                    >
                      <template #message="{ message }">
                        {{ $t(message) }}
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="8" class="pb-2">
                    <v-text-field
                      v-model="auction.code"
                      :label="$t('Code')"
                      placeholder=" "
                      :rules="[validationRequired()]"
                      required
                      class="input-field mt-0"
                    >
                      <template #message="{ message }">
                        {{ $t(message) }}
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-text-field
                  v-model="auction.name"
                  :label="$t('Name')"
                  placeholder=" "
                  :rules="[validationRequired()]"
                  required
                  class="input-field mt-0 pt-0"
                >
                  <template #message="{ message }">
                    {{ $t(message) }}
                  </template>
                </v-text-field>

                <v-text-field
                  v-model="auction.description"
                  :label="$t('Description')"
                  placeholder=" "
                  required
                  hide-details
                  class="input-field mt-0"
                >
                  <template #message="{ message }">
                    {{ $t(message) }}
                  </template>
                </v-text-field>

                <v-row class="mt-0">
                  <v-col cols="6" v-if="auction.type === AuctionFlavor.live || auction.type === AuctionFlavor.sale" class="pb-0">
                    <vc-date-picker v-model="auction.start_at" mode="dateTime" is24hr>
                      <template #default="{ inputValue, togglePopover }">
                        <v-text-field
                          class="bg-white text-gray-700 w-full py-2 px-0 input-field"
                          :value="inputValue"
                          :rules="[validationInFuture()]"
                          :label="$t('Start date')"
                          @focus="togglePopover"
                        >
                          <template #message="{ message }">
                            {{ $t(message) }}
                          </template>
                        </v-text-field>
                      </template>
                    </vc-date-picker>
                  </v-col>
                  <v-col :cols="(auction.type === AuctionFlavor.live || auction.type === AuctionFlavor.sale) ? 6 : 12" class="pb-0">
                    <vc-date-picker v-model="auction.active_until" mode="dateTime" is24hr>
                      <template #default="{ inputValue, togglePopover }">
                        <v-text-field
                          class="bg-white text-gray-700 w-full py-2 px-0 input-field"
                          :value="inputValue"
                          :rules="[validationRequired(), validationInFuture()]"
                          :label="$t('Active until')"
                          @input="activeUntilHandler"
                          @focus="togglePopover"
                        >
                          <template #message="{ message }">
                            {{ $t(message) }}
                          </template>
                        </v-text-field>
                      </template>
                    </vc-date-picker>
                  </v-col>
                </v-row>

                <v-row v-if="auction.type === AuctionFlavor.timed">
                  <v-col cols="6" class="pt-0">
                    <vc-date-picker :value="auction.timedData.active_from" mode="dateTime" is24hr @input="inputTimedActiveFrom">
                      <template #default="{ inputValue, togglePopover }">
                        <v-text-field
                          class="bg-white text-gray-700 w-full py-2 px-0 input-field"
                          :value="inputValue"
                          :rules="[validationRequired(), validationInFuture(), validationOlderThanEnd(auction)]"
                          :label="$t('Starts at')"
                          @input="timedActiveFromHandler"
                          @focus="togglePopover"
                        >
                          <template #message="{ message }">
                            {{ $t(message) }}
                          </template>
                        </v-text-field>
                      </template>
                    </vc-date-picker>
                  </v-col>
                  <v-col cols="6" class="pt-0">
                    <vc-date-picker :value="auction.timedData.active_until" mode="dateTime" is24hr @input="inputTimedActiveUntil">
                      <template #default="{ inputValue, togglePopover }">
                        <v-text-field
                          class="bg-white text-gray-700 w-full py-2 px-0 input-field"
                          :value="inputValue"
                          :rules="[validationRequired(), validationInFuture(), validationNewerThanStart(auction)]"
                          :label="$t('Ends at')"
                          @input="timedActiveUntilHandler"
                          @focus="togglePopover"
                        >
                          <template #message="{ message }">
                            {{ $t(message) }}
                          </template>
                        </v-text-field>
                      </template>
                    </vc-date-picker>
                  </v-col>
                </v-row>

                <v-select
                  v-if="auction.type === AuctionFlavor.live"
                  class="input-field mt-0"
                  :items="catalogueItems"
                  :label="$t('Product catalogue')"
                  v-model="auction.catalogue_enabled"
                  hide-details
                />

                <v-checkbox
                  v-if="auction.type === AuctionFlavor.live"
                  :hide-details="true"
                  class="input-field mt-0"
                  v-model="auction.post_auction_sale_enabled"
                  :label="$t('Post auction sale enabled')"
                />

                <v-checkbox
                  v-if="auction.type === AuctionFlavor.live"
                  :hide-details="true"
                  class="input-field mt-0"
                  v-model="auction.commission_bids_enabled"
                  :label="$t('Commission bids enabled')"
                />

                <v-row class="input-field mt-2" justify="space-between">
                  <v-btn @click="close" class="btn secondary-btn">
                    <span>{{ $t('Cancel') }}</span>
                  </v-btn>
                  <v-btn @click="doUpsert" class="btn primary-btn" :disabled="submitDisabled" :loading="loading">
                    <span>{{ submitBtnText }}</span>
                  </v-btn>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import moment from 'moment'
import frontendValidations from '@/utils/rules';
import { AuctionFlavor } from '@/utils/constants';

export default {
  name: 'CreateAuction',
  props: {
    onUpsert: {
      required: true,
      type: Function
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isOpen: false,
      isOpenDatePicker: false,
      defaultAuction: {
        start_at: moment().add(moment().utcOffset() / 60 + 1, 'h').toDate(),
        active_until: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        commission_bids_enabled: false,
        require_bid_limit_on_signup: false,
        sale_dates: null,
        preview_data: null,
        default_bid_step: 10,
        tax: 0,
        surcharge: 0,
        signup_enabled: true,
        no_shipping: false,
        no_telephone_bids: false,
        catalogue_enabled: 'enabled_with_results_and_estimations',
      },
      isFormValid: false,
      auction: {
        active_until: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
      },
      catalogueItems: [
        {
          value: 'disabled',
          text: this.$t('disabled')
        },
        {
          value: 'enabled',
          text: this.$t('enabled')
        },
        {
          value: 'enabled_with_results',
          text: this.$t('enabled with results')
        },
        {
          value: 'enabled_with_results_and_estimations',
          text: this.$t('enabled with results and estimations')
        }
      ],
      submitBtnText: this.$t('Create Auction'),
      date: new Date(),
      typeItems: [
        {
          text: this.$t('Live'),
          value: 'live'
        },
        {
          text: this.$t('Timed'),
          value: 'timed'
        },
        {
          text: this.$t('Sale'),
          value: 'sale'
        }
      ]
    }
  },
  methods: {
    validationRequired: frontendValidations.required,
    validationInFuture: frontendValidations.inFuture,
    validationOlderThanEnd: frontendValidations.olderThanEnd,
    validationNewerThanStart: frontendValidations.newerThanStart,
    open(_auction = this.defaultAuction) {
      this.auction = { ..._auction };
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
      this.auction = this.defaultAuction;
    },
    async doUpsert() {
      this.auction.start_at = this.auction.type === AuctionFlavor.live ? this.auction.start_at : null
      this.auction.signup_enabled = this.auction.type === AuctionFlavor.live
      await this.onUpsert(this.auction)
    },
    setType() {
      if (this.auction.type ===  AuctionFlavor.timed) {
        this.auction.timedData = {
          active_from: null,
          active_until: null
        }
      } else {
        delete (this.auction.timedData)
      }
    },
    activeUntilHandler(e) {
      if (e === '') this.auction.active_until = null
    },
    timedActiveFromHandler(e) {
      if (e === '') this.auction.timedData.active_from = null
    },
    timedActiveUntilHandler(e) {
      if (e === '') this.auction.timedData.active_until = null
    },
    inputTimedActiveFrom(e) {
      this.auction = {
        ...this.auction,
        timedData: {
          ...this.auction.timedData,
          active_from: e
        }
      }
      this.$refs.form.validate()
    },
    inputTimedActiveUntil(e) {
      this.auction = {
        ...this.auction,
        timedData: {
          ...this.auction.timedData,
          active_until: e
        }
      }
      this.$refs.form.validate()
    },
  },
  watch: {
    auction: {
      deep: true,
      handler (val) {
        if (val.id) this.submitBtnText = this.$t('Save Auction')
      }
    }
  },
  computed: {
    AuctionFlavor() {
      return AuctionFlavor
    },
    submitDisabled() {
      const startDateCheck = this.auction.type === 'live' ? moment().diff(moment(this.auction.start_at, 'DD.MM.YYYY HH:mm'), 'seconds') > 0 : false
      let timedActiveFromCheck = false
      let timedActiveUntilCheck = false
      if (this.auction.type === 'timed') {
        timedActiveFromCheck = !this.auction.timedData.active_from || moment().diff(moment(this.auction.timedData.active_from, 'DD.MM.YYYY HH:mm'), 'seconds') > 0 || moment(this.auction.timedData.active_until).diff(moment(this.auction.timedData.active_from, 'DD.MM.YYYY HH:mm'), 'minutes') < 0
        timedActiveUntilCheck = !this.auction.timedData.active_until || moment().diff(moment(this.auction.timedData.active_until, 'DD.MM.YYYY HH:mm'), 'seconds') > 0 || moment(this.auction.timedData.active_from).diff(moment(this.auction.timedData.active_until, 'DD.MM.YYYY HH:mm'), 'minutes') > 0
      }
      return !this.auction.code || !this.auction.name || !this.auction.type || !this.auction.active_until || moment().diff(moment(this.auction.active_until, 'DD.MM.YYYY HH:mm'), 'seconds') > 0 ||
        startDateCheck || timedActiveFromCheck || timedActiveUntilCheck
    }
  }
}

</script>
