export const customerFeatures = {
  // put your customer specific features here

  /*
  // If enabled, a message will be displayed on the dashboard, product catalogue, highlights catalogue and port auction sale catalogue
  // in guest mode to login for full features
  // guestLoginPrompt: false,
  // If enabled, the search field will be visible in guest mode
  //guestSearchField: true,
  // If enabled, if the user is logged in is not yet signed up for the auction, display a message on the product catalogue with a link to sign up
  // It will only show if signup_enabled is set for that auction and if the status is not "completed"
  //userAuctionSignupReminder: true,
  // If enabled, the input for the message on a post auction sale request will not be shown
  //disablePostAuctionInquiryMessage: false,
  // If enabled, the custom sort order will be used for categories. If not, categories will be sorted alphabetically
  //categoriesFixedSortOrder: true,
  // specify the sort order by using category ids. If empty or null, the sorting will be done alphabetically
  //categoryFixedSortOrderValues: [],
  // If enabled, the language switcher will be shown
  enableThemeSwitcher: false,
  // if this is set to >= 3, there will be a fixed amount of article per row on the grid view regardless of the screen size
  //otherwise, there will by a dynamic count of articles per row depending on the screen size
  //gridViewItemsPerRow: 0,
  // If this is set to true, the grid view will be the default view on the product catalogue and post auction sale catalogue instead of the list view
  gridViewActiveByDefault: true,
  // If this is set to true, errors are shown if run in development mode
  //showErrorsOnDevelopment: false,
  // custom auctionhouse banner for the header
  //urlBannerAuctionhouse: "",
  // If enabled, Sentry.js will be used for error reporting
  //"useSentry": false,
  // number of elements per page (i.e. product cataloge) - only for desktop view
  //elementsPerPage: [50, 100, 150, 200],
  // default for elements per page
  //elementsPerPageDefault: 50,
  // show the logo on the left on the desktop view
  //showLogoOnDesktop: true,
  // show the logo on the left on the desktop view
  //showLogoOnMobile: true,
  // show the auctionhouse banner (if enabled) on the mobile view
  //mobileViewShowBanner: true,
  // use html in data (article description)
  //useHtml: false,
  // show the auction number in the auction search component
  //showAuctionNumberOnSearchField: true,
  // show the total commission costs per auction
  // showTotalCommissionCosts: true
  */
}
