<template>
  <v-row no-gutters>
    <v-col md="12">
      <v-select
        v-model="selectedLanguage"
        :items="availableLanguages"
        item-text="name"
        item-value="lang"
        hide-details
        @change="langChange"
        class="pa-0 ma-0 lang-selector"
        append-icon="fa-angle-down"
        :menu-props="{ 'z-index': zIndex }"
        :label="title"
      >
        <template #selection="{ item }">
          <span class="select-label">
            {{ item.name }}
          </span>
        </template>
        <template #item="slotProps">
          <span>
            {{ slotProps.item.name }}
          </span>
        </template>
      </v-select>
    </v-col>
  </v-row>
</template>
<script>
import { mapActions, mapState } from 'pinia'
import { i18n } from '@/main';
import useRootStore from '@/stores/rootStore';
import { sortLanguages } from '@/utils';

export default {
  name: 'AppLangSwitcher',
  props: {
    updateUILanguage: {
      type: Boolean,
      default: true
    },
    selected: {
      type: String,
      default: ''
    },
    synced: {
      type: Boolean,
      default: false
    },
    zIndex: {
      type: String,
      default: ''
    },
    theme: {
      type: String,
      default: 'dark'
    },
    title: {
      type: String,
      default: ''
    }
  },
  emits: ['on-changed'],
  data: () => ({
    isReady: false,
    selectedLanguage: {},
    availableLanguages: [],
    counter: 1
  }),
  computed: {
    ...mapState(useRootStore, ['appLocalization', 'appStoreLang', 'userProfile']),
    getDefaultLang() {
      let queryLang = this.$route.query.lang;
      if (queryLang) {
        let found = this.availableLanguages.find((it) => it.lang === queryLang);
        if (found) {
          this.APP_GLOBAL_STORE_LANG(queryLang)
          return found;
        } else {
          let defaultLang = this.appLocalization.i18n.defaultLanguage.lang;
          this.SET_TEMP_ALERT({
            flavor: 'error',
            content: this.$t("The language doesn't exist, using the default language")
          })
          this.$router.replace({ lang: null });
          return this.availableLanguages.find((it) => it.lang === defaultLang)
        }
      } else if (this.appStoreLang !== '') {
        return this.availableLanguages.find((it) => it.lang === this.appStoreLang)
      } else if (this.selected) {
        const propLang = this.selected
        if (this.synced === false) {
          const foundLang = this.availableLanguages.find((it) => it.lang === propLang)
          const currentLang = foundLang ? foundLang.lang : this.appLocalization.i18n.defaultLanguage.lang
          this.APP_GLOBAL_STORE_LANG(currentLang)
        }
      } else {
        this.APP_GLOBAL_STORE_LANG(this.userProfile.language)
        return this.availableLanguages.find((it) => it.lang === this.$i18n.locale)
      }
    }
  },
  methods: {
    ...mapActions(useRootStore, ['APP_GLOBAL_STORE_LANG', 'SET_TEMP_ALERT']),
    langChange(itm) {
      this.selectedLanguage = this.availableLanguages.find(it => it.lang === itm)
      if (this.synced === false) this.APP_GLOBAL_STORE_LANG(itm)
      this.pushLanguage(itm);
      this.$emit('on-changed', itm)
    },
    pushLanguage(itm) {
      this.$router.push({ name: this.$route.name, query: { lang: itm } });
      //addOrUpdateQueryParameter(this.$route, "lang", itm, true);
      // TODO has to be tested
    }
  },
  watch: {
    selectedLanguage() {
      if (this.updateUILanguage) {
        this.$i18n.locale = this.selectedLanguage.lang
        i18n.locale = this.selectedLanguage.lang
      }
    },
    '$route.query.lang'() {
      this.selectedLanguage = this.getDefaultLang
    }
  },
  mounted() {
    if (this.appLocalization?.i18n?.availableLanguages) {
      const sortedLanguages = [...this.appLocalization.i18n.availableLanguages];
      sortLanguages(sortedLanguages);
      this.availableLanguages = sortedLanguages;
      this.selectedLanguage = this.getDefaultLang || { ...this.appLocalization.i18n.defaultLanguage}
      this.isReady = true
    }
  }
}
</script>
<style lang="scss" scoped>
.lang-selector {
  width: 100px;
}
</style>
