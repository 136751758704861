import { BidType, Role, UserStatusType } from '@/utils/constants';

export interface IOngoingArticle {
  id: number
  uuid: string
  last_ask_price: number
  number?: number
}

export interface IHighestBid {
  id: number
  auction_id: number
  articles_id: number
  status: number
  bid: number
  is_knockdown: number
  type: BidType
  auction_users_id: number
  sent_at: Date
  created_at: Date
  updated_at: Date
  value: number
  bidder_number: number
  user_id: number
  image?: string
}

export interface IBidStep {
  id: number
  start: number
  bid_step: number
  auction_id: number
  created_at: Date
  updated_at: Date
}

export interface IArticleImage {
  articleNumber: string
  image: string
  birthtime: Date
  size: number
}

export interface IArticle {
  id: number
  uuid: string
  auction_id: number
  status: string
  number: number
  number_optional: number
  name: string
  name_i18n: Record<string, unknown>
  description: string
  description_i18n: Record<string, unknown>
  meta: string
  meta_i18n: Record<string, unknown>
  estimate_low: number
  estimate_high: number
  estimated_price: string
  buy_price: number
  limit: number
  last_ask_price: number
  sold_price: number
  conditional: boolean
  offensive: boolean
  no_shipping: boolean
  is_highlight: boolean
  artist_resale_rights: boolean
  live_bidder?: boolean
  category_id: number
  images?: Array<IArticleImage>
  bidder_number?: number
  created_at: Date
  updated_at: Date
}

export interface ICountArticlesOnAuction {
  count_current_article: number
  count_articles: number
}

export interface IUser {
  id: number
  uuid?: string
  doi: boolean
  role: Role
  status: UserStatusType
  email: string
  customer_number?: number
  language: string
  first_name: string
  last_name: string
  company_name?: string
  country: string
  street: string
  house_number: string
  zipcode: string
  city: string
  telephone1: string
  telephone2: string
  allow_advertisements: boolean
  enable_wishlist_notifications: boolean
  approval_override: string
  password?: string
  old_password?: string
  new_password?: string
  profile_complete: boolean
  initial_auth_provider?: string
  current_auth_provider?: string
}

export interface ICategory {
  id: number
  uuid?: string
  name: string
  name_i18n?: Record<string, unknown> // TODO use this instead of Object
  created_at?: Date
  updated_at?: Date
  deleted_at?: Date
}

export interface IAuctionUser {
  id: number
  type: string
  user_id: number
  auction_id: number
  bidder_number: number
  bid_limit: number
  user_status: UserStatusType
  created_at: Date
  updated_at?: Date
}

export interface IClientListEntry {
  id: number
  socketId: string
  role: Role
  first_name: string
  last_name: string
  email: string
  userToAuction: Array<IAuctionUser>
}

export interface IUserJoinedAuctions {
  runningAuctionsSignedUpFor: Array<IUserJoinedAuctionsData>
}

export interface IUserJoinedAuctionsData {
  id: string
  status: IUserJoinedAuctionStatus
  browserTabFingerprint: string
}

export enum IUserJoinedAuctionStatus {
  notJoined = 'notJoined',
  joined = 'joined'
}

export interface IApiCallEntry {
  apiCall: string,
  isSocket: boolean,
  timestamp: any
}
