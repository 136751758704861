<template>
  <div class="custom-pagination align-center">
    <div class="paginate pa-0 ">
      <v-pagination
        :value="page" @input="changePages" :length="length"
        :total-visible="totalVisible"
        class="full-width-mobile"
      />
    </div>
    <div class="page-input">
      <v-text-field
        type="number" ref="page" @focus="pageInput=''" @focusout="pageInput=page;error=false;"
        v-model="pageInput" @keyup.enter="changePages(pageInput)" :label="$t('Page')"
        :placeholder="$t('Page')" hide-details :error="error"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomPagination',
  props: {
    page: {
      type: Number,
      default: 0
    },
    totalVisible: {
      type: Number,
      default: 11
    },
    length: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      pageInput: 1,
      error: false
    }
  },
  methods: {
    changePages(el) {
      this.error = false;
      if (el) {
        if (el <= this.length) {
          this.$emit('changePage', parseInt(el))
          this.$refs.page.$refs.input.blur()
        } else {
          this.error = true;
        }
      }
    },
  },
  watch: {
    page: {
      handler: function (newValue) {
        this.pageInput = newValue;
      },
      deep: true
    }
  }
}
</script>
<style scoped lang="scss">
.custom-pagination {
  display: flex;

  .page-input {
    .v-input {
      max-width: 35px;
    }
  }

  .paginate {
    @media screen and (max-width: 425px) {
      width: 90%;
    }
  }
}
</style>
