<template>
  <v-dialog
    v-model="isOpen"
    max-width="400"
    @click:outside="onCancel"
  >
    <v-card>
      <v-card-title class="headline">{{ headline }}</v-card-title>
      <v-card-text>{{ content }}</v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="darken-1"
          text
          @click="onCancel"
        >
          <span>{{ cancelLabel || $t('Cancel') }}</span>
        </v-btn>
        <v-btn
          v-if="type === 'confirm'"
          color="darken-1"
          text
          @click="onOk"
        >
          <span>{{ $t('Ok') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

const DialogType = {
  confirm: 'confirm',
  alert: 'alert',
}

export default {
  name: 'DialogConfirm',
  props: {
    cancelLabel: {
      type: String,
      default: null
    }
  },
  data: () => ({
    headline: '',
    content: '',
    isOpen: false,
    type: DialogType.confirm,
    cancelText: 'Cancel'

  }),
  methods: {
    onOk() {
      this.$emit('onOk');
    },
    onCancel(e) {
      this.$emit('onCancel');
      this.close();
    },
    isValidType: value => ['confirm', 'alert'].indexOf(value) !== -1,
    open(type, headline, content) {
      if (!this.isValidType(type)) {
        return;
      }

      this.type = type;
      this.headline = headline;
      this.content = content;
      this.isOpen = true;
    },

    close() {
      this.type = DialogType.confirm;
      this.headline = '';
      this.content = '';
      this.isOpen = false;
    }
  }
}
</script>
