<template>
  <v-dialog class="user-profile-modal" fullscreen v-model="showAdminKnockdownsModal" persistent>
    <v-card class="user-profile-container">
      <div class="close-btn-wrapper" v-if="!modalPosition">
        <v-btn class="close-btn" @click="closeProfileModal()">
          <v-icon class="close-modal-icon">fa-times</v-icon>
        </v-btn>
      </div>
      <v-card-text class="main-card">
        <v-container>
          <div>
            <v-row align="center" class="form-container justify-center">
              <v-col sm="12" class="full-width">
                <h2 class="page-title text-center">{{ $t('Knockdowns') }}</h2>
                <v-col class="main-col-wrapper">
                  <v-col align="center" class="sub-col-wrapper">
                    <v-row>
                      <search-box
                        v-if="auctions && auctions.length > 0"
                        :currentAuction="currentAuction"
                        :auctions="auctions"
                        hide-create-new
                        title=""
                        @onSelectAuction="getAuctionDetail"
                        :key="showAdminKnockdownsModal"
                      />
                    </v-row>
                  </v-col>
                </v-col>
              </v-col>
              <v-col sm="12" v-if="!_.isEmpty(currentAuction) && !error" :key="articlesView.length">
                <v-data-table
                  :headers="disabled ? headers.slice(0, headers.length - 1) : headers"
                  :items="articlesView"
                  :items-per-page="itemPerPage"
                  class="knockdown-table"
                  :class="{disabled}"
                  item-class="edit-article-row"
                  sort-by="number"
                  must-sort
                  ref="table"
                  :key="key"
                  no-data-text="No knockdowns available for this auction"
                  dense
                  :footer-props="{
                    'items-per-page-all-text': $t('All'),
                    'items-per-page-text': $t('Rows per page'),
                    'page-text': `{0} - {1} ${$t('of')} {2} ${$t('items')}`,
                    'items-per-page-options': []
                  }"
                >
                  <template #[`item.number`]="{ item }">
                    <span class="text-light">{{ item.number_optional || item.number }}</span>
                  </template>
                  <template #[`item.knockdown_price`]="{ item }">
                    <div class="article-price-col">
                      <div class="article-price">
                        {{ currency }}{{ euNumbers(item.knockdown_price) }}
                        <span v-if="item.conditional_flag">{{ $t('conditional') }}</span>
                      </div>
                    </div>
                  </template>
                  <template #[`item.name`]="{ item }">
                    <div class="article-name-col">
                      <div class="article-name">{{ item.name }}</div>
                    </div>
                  </template>
                  <template #[`item.limit`]="{ item }">
                    <div class="article-price-col">
                      <div class="article-price">
                        {{ currency }}{{ euNumbers(item.limit) }}
                      </div>
                    </div>
                  </template>

                  <template #top>
                    <v-text-field
                      v-model="search"
                      append-icon="fa-magnifying-glass"
                      :label="$t('Search')"
                      hide-details
                      class="mb-3"
                      @input="searchHandler"
                      clearable
                    />
                  </template>
                </v-data-table>
              </v-col>
              <v-col sm="12" class="text-center" v-else>
                <h3>{{ $t(error) }}</h3>
              </v-col>
            </v-row>
          </div>
        </v-container>
        <p class="hidden">{{ articlesView }}</p>
        <Footer />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from 'pinia'
import Footer from '@/components/footer'
import SearchBox from '@/views/settings/articles/sections/search'
import dispatcher from '@/api/dispatch';
import _ from 'lodash'
import { timeouts } from '@/utils/constants'
import useRootStore from '@/stores/rootStore'
import { euNumbers } from '@/services/i18n';

export default {
  name: 'KnockdownsModal',
  components: {
    Footer,
    SearchBox,
  },
  props: {
    auctions: {
      type: Array,
      default: () => []
    },
    activeUserId: {
      type: Number,
      default: null
    }
  },
  data: function () {
    return {
      renderComponent: true,
      showProfileModal: false,
      modalPosition: false,
      profileLocalization: {},
      currentAuction: {},
      search: '',
      headers: [
        {
          text: this.$t('Article #'),
          value: 'number',
          sortable: true,
          width: 140
        },
        {
          text: this.$t('Knockdown'),
          value: 'knockdown_price',
          align: 'left',
          sortable: true,
          width: 150,
          filterable: false
        },
        {
          text: this.$t('Name'),
          value: 'name',
          align: 'left',
          sortable: false,
        },
        {
          text: this.$t('Limit'),
          value: 'limit',
          align: 'right',
          sortable: false,
          filterable: false
        }
      ],
      itemPerPage: 10,
      page: 0,
      start: 0,
      end: 0,
      key: 0,
      error: '',
      articles: [],
      loading: false,
      searchResult: [],
      articlesView: []
    }
  },
  computed: {
    ...mapState(useRootStore, ['userProfile', 'appLocalization', 'showAdminKnockdownsModal', 'appSettings', 'appStoreLang']),
    emptyFields() {
      return false
    },
    disabledSave() {
      return true
    },
    disabled () {
      return !this.currentAuction
    },
    currency () {
      return this.appLocalization.currency
    },
  },
  watch: {
    /* async showAdminKnockdownsModal(visible) {
      if (!visible) console.log('Dialog was closed!')
    }, */
    appStoreLang() {
      this.$nextTick(() => {
        this.headers = [
          {
            text: this.$t('Article #'),
            value: 'number',
            sortable: true,
            width: 130
          },
          {
            text: this.$t('Knockdown'),
            value: 'knockdown_price',
            align: 'right',
            sortable: true,
            width: 170,
            filterable: false
          },
          {
            text: this.$t('Article name'),
            value: 'name',
            align: 'left',
            sortable: false,
          },
          {
            text: this.$t('Limit'),
            value: 'limit',
            align: 'right',
            sortable: false,
            filterable: false
          }
        ]
      });
    },
    articles() {
      this.searchResult = [...this.articles]
      this.getArticlesData()
    }
  },
  mounted() {
    this.searchResult = [...this.articles]
    this.getArticlesData()
  },
  methods: {
    ...mapActions(useRootStore, ['updateUserProfile', 'UPDATE_GLOBAL_STATE']),
    euNumbers,
    changePage (el) {
      this.page = el
      this.$refs.table.$el.scrollIntoView({ behavior: 'smooth' })
    },
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;
      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    // Close profile modal without saving
    closeProfileModal () {
      this.currentAuction = {}
      this.articles = []
      this.searchResult = []
      this.page = 1
      this.UPDATE_GLOBAL_STATE({
        key: 'showBottomNavigation',
        value: true,
      })
      this.UPDATE_GLOBAL_STATE({
        key: 'showAdminKnockdownsModal',
        value: false,
      })
    },

    async getAuctionDetail(auctionID) {
      try {
        this.loading = true
        this.page = 0
        const responseAuction = await dispatcher.getUserKnockdowns(auctionID, this.activeUserId);

        this.error = null
        this.currentAuction.id = auctionID
        this.articles = responseAuction
        this.searchResult = responseAuction
        this.key++
      } catch (e) {
        this.error = this.$t('There was an error getting the knockdowns. Please try again later')
      } finally {
        this.loading = false
      }
    },
    searchHandler: _.debounce(function () {
      if (this.search) {
        this.searchResult = []
        const tableSort = [...this.headers]
        this.articles.forEach(el => {
          tableSort.forEach(val => {
            if (el[val.value] && el[val.value].toString().toLowerCase().includes(this.search.toLowerCase()) && this.searchResult.indexOf(el.id) === -1) {
              this.searchResult.push(el)
            }
          })
        })
      } else {
        this.searchResult = this.articles
      }
      this.getArticlesData()
    }, timeouts.debounce),
    getArticlesData () {
      this.articlesView = this.searchResult
    }
  },
}
</script>
<style lang="scss" scoped>
.article-name {
  font-size: 12px;
}
.pagination-container {
  width: 1170px;
  max-width: 100%;
  margin: 20px auto 50px;
}
</style>
