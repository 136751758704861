<template>
  <div class="app-header" v-if="userProfile && userProfile.role && userProfile.role !== 'auctioneer'" :class="{ safari: browser.name === 'safari' || browser.name === 'ios' }">
    <div id="nav" v-if="userProfile.role !== 'admin' && userProfile.role !== 'streamer'">
      <user-nav :userId="userProfile.id" />
    </div>
    <div id="nav" class="settings-nav" v-else-if="userProfile.role === 'admin' || userProfile.role === 'streamer'">
      <admin-nav />
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import UserNav from '@/components/navbar/user-nav'
import AdminNav from '@/components/navbar/admin-nav'
import { detect } from 'detect-browser'
import useRootStore from '@/stores/rootStore';

export default {
  name: 'LoggedInHeader',
  data() {
    return {
      isLoggedIn: false,
      browser: detect(),
    }
  },
  components: {
    UserNav,
    AdminNav
  },
  computed: {
    ...mapState(useRootStore, ['userProfile']),
  }
}
</script>
