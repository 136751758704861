<template>
  <div class="add-item-btn-container">
    <v-btn @click="onClick">
      <v-icon>fa-circle-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'AddItemBtn',
  props: [
    'onClick',
    'position',
    'label',
  ],
}
</script>
