<template>
  <div>
    <router-view />
    <Footer />
  </div>
</template>
<script>
import Footer from '@/components/footer/'
import { mapState } from 'pinia'
import useRootStore from '@/stores/rootStore'

export default {
  name: 'AdminSettings',
  components: {
    Footer,
  },
  computed: {
    ...mapState(useRootStore, ['appSettings', 'userProfile', 'adminDrawer', 'isAuthenticatedAsUser']),
  },
  data () {
    return {
      navs: [
        {
          url: '/settings/auctions',
          name: this.$t('Auctions')
        },
        {
          url: '/settings/categories',
          name: this.$t('Categories')
        },
        {
          url: '/settings/users',
          name: this.$t('Users')
        },
        {
          url: '/streaming',
          name: this.$t('Live-Stream'),
          target: '_blank',
          icon: 'fa-external-link-alt'
        },
        {
          url: '/settings/system',
          name: this.$t('System')
        },
        {
          url: '/settings/mailing',
          name: this.$t('Mailing')
        },
        {
          name: this.$t('Reports'),
          children: [
            {
              title: this.$t('Bid transactions'),
              route: {
                name: 'bidTransactions'
              },
              icon: 'fa-history'
            },
            {
              title: this.$t('Statistics'),
              route: {
                name: 'statistics'
              },
              icon: 'fa-chart-line'
            },
            {
              title: this.$t('E-mails'),
              route: {
                name: 'emails'
              },
              icon: 'fa-envelope'
            }
          ]
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
