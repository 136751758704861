<template>
  <v-card class="text-center w-full error-page pa-3">
    <v-img src="/assets/images/500-illustration.svg" max-height="400" contain />
    <div class="display-2 mt-10">{{ $t('OOPS! Something went wrong here') }}</div>
    <div class="mt-3 mb-4">{{ $t('Our experts are working to fix the issue') }}</div>
    <p class="text-small mb-8">{{ unexpectedError }}</p>
    <v-btn @click="sendBack" block large color="primary" class="text-none">{{ $t('Send me back') }}</v-btn>
  </v-card>
</template>

<script>

import useRootStore from '@/stores/rootStore';
import { mapActions, mapState } from 'pinia';

export default {
  name: 'UnexpectedErrorPage',
  computed: {
    ...mapState(useRootStore, ['unexpectedError'])
  },
  methods: {
    ...mapActions(useRootStore, ['SET_UNEXPECTED_ERROR']),
    sendBack() {
      this.$router.go(-1)
      this.SET_UNEXPECTED_ERROR('')
    }
  }
}
</script>
