import theme from './theme'

export default {
  product: {
    name: 'Bid4it',
    version: '1.0.0'
  },

  theme,
}
