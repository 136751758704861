<template>
  <v-btn
    :class="btnClass"
    @click="onClickBtn"
    :loading="loading"
  >
    <template v-if="textBold">
      <span class="text-bold">{{ $t(btnText) }}</span>
    </template>
    <template v-else-if="icon">
      <v-icon>{{ icon }}</v-icon>
    </template>
    <template v-else>
      {{ btnText }}
    </template>
  </v-btn>
</template>
<script>

export default {
  name: 'AlInputFileBtn',
  props: {
    btnClass: String,
    btnText: String,
    acceptFile: String,
    icon: String,
    textBold: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    this.inputElem = document.createElement('INPUT');
    this.inputElem.type = 'file';
    this.inputElem.accept = this.acceptFile;
    this.inputElem.style.height = '0px';
    this.inputElem.style.width = '0px';
    this.inputElem.style.position = 'fixed';
    this.inputElem.style.left = '-999999px';
    this.inputElem.multiple = this.multiple;

    this.inputElem.addEventListener('change', this.onChange.bind(this))
    document.body.append(this.inputElem);
  },
  destroyed () {
    this.inputElem.remove();
  },
  methods: {
    onClickBtn() {
      this.inputElem.click();
    },
    onChange(e) {
      e.preventDefault();
      e.stopPropagation();
      this.$emit('fileChange', !this.multiple ? e.target.files[0] : e.target.files);
      this.$emit('click');
      this.inputElem.value = '';
    }
  }
}
</script>
