<template>
  <div id="app-user-auction-container" class="auction-container pt-0">
    <div class="user-profile-modal">
      <v-card class="user-profile-container user-catalogue">
        <div class="close-btn-wrapper" v-if="!modalPosition">
          <v-btn class="close-btn" @click="closeProfileModal()">
            <v-icon class="close-modal-icon">fa-times</v-icon>
          </v-btn>
        </div>
        <v-card-text class="main-card" v-if="auctions">
          <v-container>
            <div>
              <v-row align="center" class="form-container justify-center flex-column">
                <v-col sm="12" class="full-width">
                  <h2 class="page-title text-center">{{ $t('Knockdowns') }}</h2>
                  <v-col class="main-col-wrapper" v-if="!preselectedAuction || localySelected">
                    <v-col align="center" class="sub-col-wrapper">
                      <v-row>
                        <search-box
                          v-if="auctions && auctions.length > 0"
                          :currentAuction="selectedAuctionData"
                          :auctions="auctions"
                          hide-create-new
                          title=""
                          @onSelectAuction="getAuctionDetail"
                          show-auction-type
                        />
                      </v-row>
                    </v-col>
                  </v-col>
                </v-col>
                <v-progress-circular v-if="loading" class="loading-icon mt-6" indeterminate />
                <v-col sm="12" v-else-if="!_.isEmpty(selectedAuctionData) && !error" class="pb-16 pb-sm-0">
                  <v-data-table
                    :headers="disabled ? headers.slice(0, headers.length - 1) : headers"
                    :items="articlesView"
                    :items-per-page="itemPerPage"
                    class="knockdown-table pb-16 pb-sm-0"
                    :class="{disabled}"
                    item-class="edit-article-row"
                    sort-by="number"
                    must-sort
                    ref="table"
                    :key="key"
                    @click:row="article => openArticleDetailedModal(article)"
                    dense
                    :footer-props="{
                      'items-per-page-all-text': $t('All'),
                      'items-per-page-text': $t('Rows per page'),
                      'page-text': `{0} - {1} ${$t('of')} {2} ${$t('items')}`,
                      'items-per-page-options': []
                    }"
                  >
                    <template #[`item.number`]="{ item }">
                      <div class="d-flex align-center justify-space-between">
                        <span class="text-light">{{ item.number_optional || item.number }}</span>
                        <v-icon class="icon-search-circle icon-with-hover" small>fa-search</v-icon>
                      </div>
                    </template>
                    <template #[`item.type`]="{ item }">
                      <span class="text-light"> {{ $t(knockdownStatusNames[item.type].text) }}</span>
                    </template>
                    <template #[`item.knockdown_price`]="{ item }">
                      <div class="article-price-col">
                        <div class="article-price">
                          {{ currency }}{{ euNumbers(item.knockdown_price) }}
                          <span v-if="item.conditional_flag">{{ $t('conditional') }}</span>
                        </div>
                      </div>
                    </template>
                    <template #[`item.name`]="{ item }">
                      <div class="article-name-col">
                        <div class="article-name">{{ articleTitle(item) }}</div>
                      </div>
                    </template>
                    <template #[`item.limit`]="{ item }">
                      <div class="article-price-col">
                        <div class="article-price">
                          {{ currency }}{{ euNumbers(item.limit) }}
                        </div>
                      </div>
                    </template>

                    <template #top>
                      <v-text-field
                        v-model="search"
                        append-icon="fa-magnifying-glass"
                        :label="$t('Search')"
                        hide-details
                        class="mb-3"
                        @input="searchHandler"
                      />
                    </template>
                  </v-data-table>
                </v-col>
                <v-col sm="12" class="text-center" v-else>
                  <h3 class="no-data-text">{{ $t('No knockdowns available for this auction') }}</h3>
                </v-col>
                <v-col class="text-center" v-if="getAuctionError">
                  <div class="status__item status__item--error">
                    <div class="status__icon" />
                    <p class="status__text">{{ $t(`This auction doesn't exist`) }}</p>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-container>
        </v-card-text>
      </v-card>
    </div>
    <article-details-modal
      ref="articleDetailsModal"
      :article="selectedDetailedArticle"
      :selected-auction-data="selectedAuctionData"
      :prev-article="prevArticle"
      :next-article="nextArticle"
      @openArticleDetailedModal="openArticleDetailedModal"
    />
  </div>
</template>
<script>
import { mapActions, mapState } from 'pinia'
import SearchBox from '@/views/settings/articles/sections/search'
import articleDetailsModal from '@/components/modals/article-details-modal'
import articleDetails from '@/mixins/article-details'
import dispatcher from '@/api/dispatch';
import moment from 'moment';
import useRootStore from '@/stores/rootStore'
import { knockdownStatusNames } from '../../utils/constants';
import { euNumbers, getTranslatedText } from '@/services/i18n';

export default {
  name: 'KnockdownsModal',
  components: {
    SearchBox,
    articleDetailsModal
  },
  data: function () {
    return {
      renderComponent: true,
      showProfileModal: false,
      getAuctionError: false,
      modalPosition: false,
      profileLocalization: {},
      selectedAuctionData: {},
      search: '',
      headers: [
        {
          text: this.$t('Article #'),
          value: 'number',
          sortable: true,
          width: 130
        },
        {
          text: this.$t('Type'),
          value: 'type',
          align: 'left',
          sortable: false,
          width: 100
        },
        {
          text: this.$t('Knockdown'),
          value: 'knockdown_price',
          align: 'left',
          sortable: true,
          width: 120,
          filterable: false
        },
        {
          text: this.$t('Lot name'),
          value: 'name',
          align: 'left',
          sortable: false,
        },
        {
          text: this.$t('Limit'),
          value: 'limit',
          align: 'right',
          sortable: false,
          filterable: false,
          width: 100
        }
      ],
      itemPerPage: 10,
      page: 0,
      start: 0,
      end: 0,
      key: 0,
      error: '',
      tmpArticles: [],
      loading: false,
      searchResult: [],
      auctions: null,
      localySelected: true
    }
  },
  mixins: [
    articleDetails
  ],
  computed: {
    ...mapState(useRootStore, ['userProfile', 'appLocalization', 'appSettings', 'appStoreLang', 'auctionsFetchedArticles', 'preselectionData']),
    knockdownStatusNames() {
      return knockdownStatusNames
    },
    emptyFields() {
      return false
    },
    disabledSave() {
      return true
    },
    articlesView() {
      if (this.search) {
        this.searchResult = []
        const tableSort = [...this.headers]
        tableSort.splice(this.headers.length - 1, 1)
        this.tmpArticles.forEach(el => {
          tableSort.forEach(val => {
            if (el[val.value] && el[val.value].toString().toLowerCase().includes(this.search.toLowerCase()) && this.searchResult.map(({ id }) => id).indexOf(el.id) === -1) {
              this.searchResult.push(el)
            }
          })
        })
        return this.searchResult;
      } else {
        return this.tmpArticles;
      }
    },
    disabled () {
      return !this.selectedAuctionData
    },
    currency () {
      return this.appLocalization.currency
    },
    preselectedAuction() {
      return this.$route.params.id
    }
  },
  async created() {
    await this.getAuctions()
    if (this.preselectedAuction) {
      this.localySelected = false
      this.selectedAuctionData = this.auctions.find(el => el.code.toString() === this.preselectedAuction.toString())
      if (this.selectedAuctionData) {
        await this.getAuctionDetail(this.selectedAuctionData.id)
      } else {
        this.getAuctionError = true
      }
    }
    this.checkUrl()
  },
  async mounted() {
    // every time the view is shown i.e. on a route change
    // if no auction is preselected via the url and there is one preselected in the store, use this
    if (this.preselectionData.selectedAuctionId) {
      this.search = null
      await this.getAuctions()
      const targetAuction = this.auctions.find(el => el.id === this.preselectionData.selectedAuctionId);
      this.selectedAuctionData = targetAuction
      if (this.selectedAuctionData && this.selectedAuctionData.code !== this.$route.params.id) this.$router.push({ params: { id: this.selectedAuctionData.code } })
      await this.getAuctionDetail(this.selectedAuctionData.id)
    }
  },
  watch: {
    appStoreLang() {
      this.$nextTick(() => {
        this.headers = [
          {
            text: this.$t('Article #'),
            value: 'number',
            sortable: true,
            width: 140
          },
          {
            text: this.$t('Type'),
            value: 'type',
            sortable: false,
            filterable: false
          },
          {
            text: this.$t('Knockdown'),
            value: 'knockdown_price',
            align: 'right',
            sortable: true,
            width: 170,
            filterable: false
          },
          {
            text: this.$t('Article name'),
            value: 'name',
            align: 'left',
            sortable: false,
          },
          {
            text: this.$t('Limit'),
            value: 'limit',
            align: 'right',
            sortable: false,
            filterable: false
          }
        ]
      });
    }
  },
  methods: {
    ...mapActions(useRootStore, ['updateUserProfile']),
    euNumbers,
    changePage (el) {
      this.page = el
      this.$refs.table.$el.scrollIntoView({ behavior: 'smooth' })
    },
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;
      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    // Close profile modal without saving
    closeProfileModal () {
      this.$router.push({ name: 'dashboard' })
    },

    async getAuctionDetail(auctionID) {
      this.loading = true
      if (!this.$route.params.id) this.localySelected = true
      const auction = this.auctions.find(el => el.id.toString() === auctionID.toString())
      if (auction && this.$route.params.id !== auction.code) this.$router.push({ params: { id: auction.code } })
      this.page = 0
      let responseAuction;
      try {
        responseAuction = await dispatcher.getUserKnockdowns(auctionID, this.userProfile.id);
        if (responseAuction.Message) { //TODO have the backend return []
          this.error = responseAuction.Message
          this.loading = false
          return;
        }
      } catch (e) {
        this.error = this.$t('There was an error getting the knockdowns. Please try again later')
        this.loading = false
        return
      }

      this.error = null
      this.selectedAuctionData.id = auctionID
      this.articles = responseAuction.map(el => {
        if (!this.auctionsFetchedArticles[`auction_${auctionID}`]) return el
        const articleData = this.auctionsFetchedArticles[`auction_${auctionID}`].find(article => article.id === el.id)
        if (articleData) {
          return {
            ...articleData,
            ...el
          }
        }
        return el
      })
      this.tmpArticles = [...this.articles]
      this.key++
      this.loading = false
    },
    searchHandler () {
      if (this.search) {
        this.tmpArticles = this.articles.filter(el => el.number.toString().includes(this.search) || el.number_optional.includes(this.search) || el.name.toLowerCase().includes(this.search.toLowerCase()))
      } else {
        this.tmpArticles = this.articles
      }
    },
    articleTitle (article) {
      return getTranslatedText(article, 'name', this.appStoreLang)
    },
    articleDescription (article) {
      return getTranslatedText(article, 'description', this.appStoreLang)
    },

    /**
     * Get all auctions from api and filter according to conditionals
     * @return {any} - Modified auctions
     */
    async getAuctions() {
      try {
        const data = await dispatcher.getAllAuctions();
        this.auctions = data.filter(el => moment(el.active_until).diff(moment(), 'seconds') > 0)
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error getting the auctions. Please try again later') });
        this.error = true;
      }
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
