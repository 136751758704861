import useRootStore from '@/stores/rootStore';

/**
 * Get the cookie
 * @param {string} name - The cookie name
 * @return {string|undefined} - the cookie contents or undefined if not found
 */
function getCookie(name: string): string | undefined {
  let matches = document.cookie.match(new RegExp(
    '(?:^|; )' + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)'
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

/**
 * Set the cookie
 * @param {string} name - The cookie name
 * @param {any} value - The cookie content
 * @param {any} options - The cookie options
 * @return {void} - Nothing
 */
function setCookie(name: string, value: any, options: any): void {
  options = {
    path: '/',
    ...options
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += '; ' + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += '=' + optionValue;
    }
  }

  document.cookie = updatedCookie;
}

/**
 * Remove the cookie
 * @param {string} name - The cookie name
 * @return {void} - Nothing
 */
function deleteCookie(name: string): void {
  setCookie(name, '', {
    'max-age': -1
  })
}

/**
 * Compute if cookie is used instead of jwt
 * @return {boolean} - True if cookie should be used, false if jwt should be used
 */
export function useCookie(): boolean {
  const rootStore = useRootStore()
  let useCookie = false;
  if (rootStore.appFeatures.useCookie !== undefined) useCookie = rootStore.appFeatures.useCookie;
  return useCookie;
}

/**
 * Get the jwt or cookie
 * @return {string} - The jwt or cookie or undefined if it doesn't exist
 */
export function getAuthToken(): string {
  if (useCookie()) {
    return getCookie('session') || ''
  } else {
    return <string>window.localStorage.getItem('token')
  }
}

/**
 * Set the jwt or cookie
 * @param {string} token - The jwt or cookie content
 * @return {string} - The jwt or undefined if it doesn't exist
 */
export function setAuthToken(token: string): void {
  window.localStorage.setItem('token', token);
}

/**
 * Remove the jwt or cookie
 * @return {void} - Nothing
 */
export function removeAuthToken(): void {
  if (useCookie()) {
    deleteCookie('session');
  } else {
    window.localStorage.removeItem('token');
  }
}

/**
 * Check if the token exists
 * @param {string | null} token - jwt or cookie content
 * @return {boolean} - True if it is invalid, false otherwise
 */
export function isInvalidToken(token: string | null): boolean {
  return token === null || token === undefined || token === '';
}

/**
 * Check the jwt if it is expired. If so, removes it
 * @param {string} token - The jwt
 * @return {void} - Nothing
 */
export function removeTokenIfExpired(token: string): void {
  if (!useCookie()) {
    if (!checkIfTokenIsValid(token)) {
      console.log('old jwt found which was not deleted, deleting it');
      window.localStorage.removeItem('token');
    }
  }
}

/**
 * Check if the jwt is still valid
 * @param {string} token - The jwt
 * @return {boolean} - True if the jwt is still valid, false otherwise
 */
export function checkIfTokenIsValid(token: string): boolean {
  const jwtPayload = parseJwt(token);
  const expiry = jwtPayload.exp;
  // console.log(jwtPayload.exp, Date.now(), Math.round(Date.now() / 1000))
  if (jwtPayload.exp <= Math.round(Date.now() / 1000)) {
    console.log('jwt is expired');
    return false
  } else {
    console.log(`jwt is still valid, will expire at ${expiry}`)
    return true
  }
}

/**
 * Generate a jwt from a string
 * @param {string} token - The input string
 * @return {Object} - The jwt
 */
export function parseJwt(token: string) {
  if (!token) return
  let base64Url = token.split('.')[1];
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

/**
 * Get the user id of the current user
 * @return {any} - The user id
 */
export function getConnectedUser() {
  return parseJwt(getAuthToken()) && parseJwt(getAuthToken()).id
}
