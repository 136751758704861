<template>
  <div class="app-header" v-if="appFeatures.guestFunctionality">
    <div id="nav">
      <guest-nav />
    </div>
  </div>
  <div class="app-guest-header" v-else>
    <div class="logo-wrapper">
      <a :href="appFeatures.url_auction_house" target="_blank">BID4IT.</a>
    </div>
    <div class="header-lang-switcher">
      <v-row class="ma-0">
        <theme-switch class="mr-2" />
        <lang-siwtcher :key="appStoreLang" theme="light" />
      </v-row>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'pinia'
import GuestNav from '@/components/navbar/guest-nav'
import ThemeSwitch from '@/components/header/theme-switch'
import useRootStore from '@/stores/rootStore';

export default {
  name: 'GuestHeader',
  components: {
    GuestNav,
    ThemeSwitch
  },
  async mounted() {
    // Hide loading
    this.UPDATE_GLOBAL_STATE({
      key: 'appLoading',
      value: true,
    })
  },
  methods: {
    ...mapActions(useRootStore, ['UPDATE_GLOBAL_STATE']),
  },
  computed: {
    ...mapState(useRootStore, ['appFeatures', 'appStoreLang']),
  }
}
</script>
<style lang="scss">
.app-guest-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: inset 0px -1px 0px #e5e5e5;
  padding: 24px 28px 24px 40px;
  z-index: 1002;
  @media (max-width: 968px) {
    padding: 10px 16px;
  }
  .logo-wrapper {
    a {
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      color: #262626;
    }
  }
}
#nav {
  @media (max-width: 390px) {
    padding: 12px 5px !important;

    .logo {
      .v-image {
        width: 85px !important;
      }
    }
  }

  @media only screen and (min-width: 390px) and (max-width: 415px) {
    padding: 12px 10px !important;
  }
}
</style>
