<template>
  <v-switch
    class="ma-0 switch-theme"
    v-model="darkTheme"
    @change="changeTheme"
    inset
    hide-details
    v-if="features.enableThemeSwitcher"
  />
</template>

<script>
import { localFeatures } from '@/utils/constants';
import useRootStore from '@/stores/rootStore';
import { mapActions } from 'pinia';

export default {
  name: 'ThemeSwitch',
  data() {
    return {
      darkTheme: useRootStore().globalTheme === 'dark',
      features: localFeatures,
    }
  },
  methods: {
    ...mapActions(useRootStore, ['SET_GLOBAL_THEME']),
    changeTheme() {
      this.SET_GLOBAL_THEME(this.darkTheme ? 'dark' : 'light')
    }
  }
}
</script>

<style scoped>

</style>
