import { render, staticRenderFns } from "./imprint.vue?vue&type=template&id=3a8d4435&scoped=true"
import script from "./imprint.vue?vue&type=script&lang=js"
export * from "./imprint.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a8d4435",
  null
  
)

export default component.exports