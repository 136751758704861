<template>
  <v-row no-gutters>
    <v-col md="12">
      <v-select
        :label="$t('Language')"
        v-model="selectedLanguage"
        :items="availableLanguages"
        item-text="name"
        item-value="lang"
        hide-details
        @change="langChange"
        class="pa-0 ma-0"
      >
        <template #selection="{ item }">
          <span class="select-label">
            {{ item.name }}
          </span>
        </template>
        <template #item="slotProps">
          <span>
            {{ slotProps.item.name }}
          </span>
        </template>
      </v-select>
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from 'pinia'
import useRootStore from '@/stores/rootStore';
import { sortLanguages } from '@/utils';

export default {
  name: 'AppLangSwitcher',
  emits: ['on-changed'],
  data: () => ({
    isReady: false,
    selectedLanguage: {},
    availableLanguages: [],
    counter: 1
  }),
  computed: {
    ...mapState(useRootStore, ['appLocalization', 'appStoreLang']),
  },
  watch: {
    selectedLanguage(val) {
      if (!val) this.selectedLanguage = { ...this.appLocalization.i18n.defaultLanguage }
    }
  },
  methods: {
    langChange(itm) {
      this.selectedLanguage = this.availableLanguages.find(it => it.lang === itm)
      this.$emit('on-changed', itm)
    },
    resetLanguage() {
      this.selectedLanguage = { ...this.appLocalization.i18n.defaultLanguage }
      this.$emit('on-changed', this.selectedLanguage.lang)
    }
  },
  mounted() {
    if (this.appLocalization && this.appLocalization.i18n) {
      const sortedLanguages = [...this.appLocalization.i18n.availableLanguages];
      sortLanguages(sortedLanguages);
      this.availableLanguages = sortedLanguages;
      this.selectedLanguage = { ...this.appLocalization.i18n.defaultLanguage }
      this.resetLanguage()
      this.isReady = true
    }
  }
}
</script>
