<template>
  <v-dialog
    v-model="postAuctionSaleModal"
    width="700"
    @keydown.esc="closeModal"
    persistent
  >
    <v-card class="pa-5">
      <v-btn class="modal-close-btn icon-with-hover" icon @click="closeModal"><v-icon>fa-times</v-icon></v-btn>
      <h2 class="pt-2 text-center">{{ $t('Post auction sale') }}</h2>
      <p class="pt-2 mb-4"><span>{{ $t('Please enter your inquiry regarding the post auction sale of this lot') }}</span></p>
      <v-form ref="postAuctionSaleForm" v-model="isFormValid">
        <v-textarea
          v-if="!features.disablePostAuctionInquiryMessage"
          v-model="postAuctionSaleInquiry"
          :label="$t('Your question')"
          required
          class="input-field"
          rows="6"
          no-resize
          :counter="maxInputLength"
          :placeholder="$t('Your question')"
          :rules="[validationRequired(), validationLessThanMaximum(this.maxInputLength)]"
        >
          <template #message="{ message }">
            {{ $t(message) }}
          </template>
        </v-textarea>
        <div class="info-text full-width" v-if="!_.isEmpty(article)">
          <v-icon>fa-info-circle</v-icon>
          <p>{{ $t('post_auction_sale_modal_message1') }} {{ appLocalization.currency }} {{ euNumbers(article.limit) }} {{ $t('post_auction_sale_modal_message2') }}</p>
        </div>
        <div class="text-center mt-2">
          <v-btn
            class="btn primary-btn success full-width"
            :disabled="(!features.disablePostAuctionInquiryMessage && !isFormValid) || disableButton"
            @click="submitPostAuctionSaleRequest"
            :loading="loading"
          >
            <v-icon class="mr-2">fa-envelope</v-icon>
            {{ $t('Send message') }}
          </v-btn>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { localFeatures, timeouts } from '@/utils/constants'
import {mapActions, mapState} from 'pinia';
import dispatcher from '@/api/dispatch';
import frontendValidations from '@/utils/rules';
import useRootStore from '@/stores/rootStore';
import { euNumbers } from '@/services/i18n';

export default {
  name: 'PostAuctionSaleModal',
  props: {
    postAuctionSaleModal: {
      type: Boolean,
      required: true
    },
    article: {
      type: Object,
      default: null
    }
  },
  emits: ['closePostAuctionSaleModal'],
  data() {
    return {
      features: localFeatures,
      disableButton: false,
      loading: false,
      postAuctionSaleInquiry: '',
      maxInputLength: 500,
      isFormValid: false
    }
  },
  computed: {
    ...mapState(useRootStore, ['userProfile', 'appStoreLang', 'appLocalization'])
  },
  methods: {
    ...mapActions(useRootStore, ['SET_TEMP_ALERT']),
    validationRequired: frontendValidations.required,
    validationLessThanMaximum: frontendValidations.lessThanMaximum,
    euNumbers,
    /**
     * Close the modal
     * @return {void} - Nothing
     */
    closeModal() {
      this.$refs.postAuctionSaleForm.reset()
      this.disableButton = false
      this.$emit('closePostAuctionSaleModal')
    },
    /**
     * Submit the post auction sale request and close the modal on success
     * @return {void} - Nothing
     */
    async submitPostAuctionSaleRequest() {
      try {
        const data = {
          type: 'post-auction-sale',
          message: this.features.disablePostAuctionInquiryMessage ? '---' : this.postAuctionSaleInquiry,
          lang: this.appStoreLang,
          article_id: this.article.id,
          price: this.article.limit
        }
        this.loading = true
        await dispatcher.sendEmailInquiry(this.userProfile.id, data)
        this.SET_TEMP_ALERT({ flavor: 'success', content: this.$t('Your inquiry has been sent. We will get back to you as soon as possible') })
        setTimeout(() => {
          this.loading = false
          this.disableButton = true
          this.closeModal()
        }, timeouts.closeModal)
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error sending the inquiry. Please try again later') })
        this.disableButton = false
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.info-text {
  @media(max-width: 760px) {
    display: flex;
    p {
      font-size: 12px;
      line-height: 20px;
    }
  }
}
</style>
