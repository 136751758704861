<script>
import Vue from 'vue'
import {mapActions, mapState} from 'pinia'
import useRootStore from '@/stores/rootStore';
import { PushEntity, PushType } from '@/utils/constants';
export default {
  data() {
    return {
      articles: null,
      categories: []
    }
  },
  computed: {
    ...mapState(useRootStore, ['adminCategories', 'adminDataChange'])
  },
  watch: {
    adminDataChange: {
      deep: true,
      handler(val) {
        this.pushUpdate(val)
      }
    }
  },
  methods: {
    ...mapActions(useRootStore, ['UPDATE_AUCTIONS_FETCHED_ARTICLE', 'REMOVE_AUCTIONS_FETCHED_ARTICLE', 'ADD_AUCTIONS_FETCHED_ARTICLE', 'SET_ADMIN_CATEGORIES']),
    pushUpdate(data) {
      if (data.entity === PushEntity.article) {
        const cachedData = [...JSON.parse(localStorage.getItem(`${data.data.auction_id}ArticleCache`))]
        if (cachedData) {
          const auctionID = data.data.auction_id
          if (data.type === PushType.update) {
            const updatedData = cachedData.map(article => {
              if (article.id === data.data.id) {
                return {
                  ...article,
                  ...data.data
                }
              }
              return article
            })
            this.UPDATE_AUCTIONS_FETCHED_ARTICLE({
              auctionID,
              article: data.data
            })
            localStorage.setItem(`${auctionID}ArticleCache`, JSON.stringify(updatedData))
            Vue.$cookies.set(`${auctionID}ArticleCache`, JSON.stringify({ AuctionID: auctionID, articleCacheLastUpdated: new Date() }))
            this.articles = updatedData
          } else if (data.type === PushType.delete) {
            const idx = cachedData.findIndex(el => el.id === data.data.id)
            cachedData.splice(idx, 1)
            this.REMOVE_AUCTIONS_FETCHED_ARTICLE({
              auctionID,
              article: data.data
            })
            localStorage.setItem(`${auctionID}ArticleCache`, JSON.stringify(cachedData))
            Vue.$cookies.set(`${auctionID}ArticleCache`, JSON.stringify({ AuctionID: auctionID, articleCacheLastUpdated: new Date() }))
            this.articles = cachedData
          } else if (data.type === PushType.create) {
            cachedData.push(data.data)
            this.ADD_AUCTIONS_FETCHED_ARTICLE({
              auctionID,
              article: data.data
            })
            localStorage.setItem(`${auctionID}ArticleCache`, JSON.stringify(cachedData))
            Vue.$cookies.set(`${auctionID}ArticleCache`, JSON.stringify({ AuctionID: auctionID, articleCacheLastUpdated: new Date() }))
            this.articles = cachedData
          }
        }
      } else if (data.entity === PushEntity.category) {
        const cachedData = [...this.adminCategories]
        if (data.type === PushType.update) {
          const categories = cachedData.map(category => {
            if (category.id === data.data.id) {
              return {
                ...category,
                ...data.data
              }
            }
            return category
          })
          this.SET_ADMIN_CATEGORIES(categories)
          Vue.$cookies.set('categories', JSON.stringify({ categoriesCacheLastUpdated: new Date() }))
          localStorage.setItem('categories', JSON.stringify(categories))
          this.categories = [...categories]
        } else if (data.type === PushType.delete) {
          const idx = cachedData.findIndex(el => el.id === data.data.id)
          cachedData.splice(idx, 1)
          this.SET_ADMIN_CATEGORIES(cachedData)
          Vue.$cookies.set('categories', JSON.stringify({ categoriesCacheLastUpdated: new Date() }))
          localStorage.setItem('categories', JSON.stringify(cachedData))
          this.categories = [...cachedData]
        } else if (data.type === PushType.create) {
          cachedData.push(data.data)
          this.SET_ADMIN_CATEGORIES(cachedData)
          Vue.$cookies.set('categories', JSON.stringify({ categoriesCacheLastUpdated: new Date() }))
          localStorage.setItem('categories', JSON.stringify(cachedData))
          this.categories = [...cachedData]
        }
      } else if (data.entity === PushEntity.user) {
        const cachedData = [...this.users]
        if (cachedData) {
          if (data.type === PushType.update) {
            const users = cachedData.map(user => {
              if (user.id === data.data.id) {
                return {
                  ...user,
                  ...data.data
                }
              }
              return user
            })
            this.SET_USERS(users)
            Vue.$cookies.set('users', JSON.stringify({ userCacheLastUpdated: new Date() }))
            localStorage.setItem('users', JSON.stringify(users))
          } else if (data.type === PushType.delete) {
            const idx = cachedData.findIndex(el => el.id === data.data.id)
            cachedData.splice(idx, 1)
            this.SET_USERS(cachedData)
            Vue.$cookies.set('users', JSON.stringify({ userCacheLastUpdated: new Date() }))
            localStorage.setItem('users', JSON.stringify(cachedData))
          } else if (data.type === PushType.create) {
            cachedData.push(data.data)
            this.SET_USERS(cachedData)
            Vue.$cookies.set('users', JSON.stringify({ userCacheLastUpdated: new Date() }))
            localStorage.setItem('users', JSON.stringify(cachedData))
          }
        }
      }
    }
  }
}
</script>
