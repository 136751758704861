<template>
  <div id="app-user-auction-container" class="auction-container pt-0">
    <div class="user-profile-modal">
      <v-card class="user-profile-container">
        <div class="close-btn-wrapper" v-if="!modalPosition">
          <v-btn class="close-btn" @click="closeProfileModal()">
            <v-icon class="close-modal-icon">fa-times</v-icon>
          </v-btn>
        </div>
        <v-card-text class="main-card">
          <v-container>
            <div>
              <v-row align="center" class="form-container">
                <v-col align="center">
                  <v-tabs
                    v-model="tab"
                    background-color="transparent"
                    class="user-tabs"
                    :vertical="isMobile"
                    @change="tabChange"
                  >
                    <v-tab class="tab-title">
                      {{ $t('Personal data') }}
                    </v-tab>
                    <v-tab class="tab-title">
                      {{ $t('Files') }}
                    </v-tab>
                    <v-tab class="tab-title">
                      {{ $t('Bidding data') }}
                    </v-tab>
                    <v-tab class="tab-title" v-if="userProfile.current_auth_provider === 'local'">
                      {{ $t('Change password') }}
                    </v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="tab" class="pb-14">
                    <v-tab-item>
                      <v-col class="main-col-wrapper">
                        <v-col align="center" class="sub-col-wrapper">
                          <v-row>
                            <v-col cols="12" xs="12" md="6">
                              <div key="langu" class="v-input input-field v-input--hide-details v-text-field v-text-field--is-booted v-text-field--placeholder no-padding-top">
                                <div class="v-input__control mt-4">
                                  <lang-siwtcher v-if="renderComponent" @on-changed="langSwitcherValue" :selected="profileData.language" :synced="true" :title="$t('Language')" />
                                </div>
                              </div>
                              <v-text-field hide-details type="text" :rules="[validationRequired()]" v-model="profileData.first_name" :label="$t('First name')" placeholder=" " class="input-field" />
                              <v-text-field hide-details type="text" :rules="[validationRequired()]" v-model="profileData.last_name" :label="$t('Last name')" placeholder=" " class="input-field" />
                              <v-text-field hide-details type="text" v-model="profileData.company_name" :label="$t('Company name')" placeholder=" " class="input-field"></v-text-field>
                              <v-text-field hide-details="true" type='text' v-model="profileData.tax_id" :label="$t('Tax ID')" placeholder=" " class="input-field"></v-text-field>
                              <v-checkbox
                                hide-details
                                class="input-field mt-11"
                                v-model="profileData.allow_advertisements"
                                :label="$t('Allow advertisements')"
                                v-if="!isMobile"
                              />
                              <v-checkbox
                                hide-details
                                class="input-field mt-11"
                                v-model="profileData.enable_wishlist_notifications"
                                :label="$t('Enable notifications for articles on the wishlist during the auction')"
                                v-if="!isMobile"
                              />
                              <v-alert
                                v-if="!isMobile && profileData.current_auth_provider !== 'local'"
                                class="show-here"
                                border="left"
                                dense
                                text
                                type="info"
                              >
                                {{ $t('You are currently logged in using', {tAuthProvider: this.profileData.current_auth_provider}) }}
                              </v-alert>
                              <div class="mt-10" v-if="!isMobile">
                                <v-alert
                                  v-if="!this.profileData.profile_complete"
                                  border="left"
                                  dense
                                  text
                                  type="warning"
                                >
                                  {{ $t('Please complete your profile to be able to signup for a live auction or place commission bids') }}
                                </v-alert>
                              </div>
                            </v-col>
                            <v-col sm="12" md="6">
                              <div class="v-input input-field v-input--hide-details v-text-field v-text-field--is-booted v-text-field--placeholder no-padding-top">
                                <div class="v-input__control">
                                  <v-select
                                    class="select-input profile-select-countries"
                                    hide-details
                                    v-model="profileData.country"
                                    :rules="[validationRequired()]"
                                    :items="countries" append-icon="fa-angle-down"
                                    :label="$t('Country')" item-text="[1]" item-value="[0]"
                                  />
                                </div>
                              </div>

                              <v-text-field hide-details type="text" :rules="[validationRequired()]" v-model="profileData.street" :label="$t('Street')" placeholder=" " class="input-field" />
                              <v-text-field hide-details type="text" :rules="[validationRequired()]" v-model="profileData.house_number" :label="$t('Number')" placeholder=" " class="input-field" />
                              <v-text-field hide-details type="text" :rules="[validationRequired()]" @keydown="inputZipHandler" v-model="profileData.zipcode" :label="$t('Zipcode')" placeholder=" " class="input-field" />
                              <v-text-field hide-details type="text" :rules="[validationRequired()]" v-model="profileData.city" :label="$t('City')" placeholder=" " class="input-field" />
                              <v-text-field hide-details type="text" :rules="[validationRequired()]" @keydown="inputPhoneHandler" v-model="profileData.telephone1" :label="$t('Telephone1')" placeholder=" " class="input-field" />
                              <v-text-field hide-details type="text" @keydown="inputPhoneHandler" v-model="profileData.telephone2" :label="$t('Telephone2')" placeholder=" " class="input-field">
                                <template slot="append-outer">
                                  <span>{{ $t('Optional') }}</span>
                                </template>
                              </v-text-field>
                              <v-checkbox
                                class="input-field mt-11"
                                v-model="profileData.allow_advertisements"
                                :label="$t('Allow advertisements')"
                                v-if="isMobile"
                              />
                              <v-checkbox
                                class="input-field mt-11"
                                v-model="profileData.enable_wishlist_notifications"
                                :label="$t('Enable notifications for articles on the wishlist during the auction')"
                                v-if="isMobile"
                              />
                              <div class="mt-4" v-if="isMobile">
                                <v-alert
                                  v-if="profileData.current_auth_provider !== 'local'"
                                  class="full-width show-here"
                                  border="left"
                                  dense
                                  text
                                  type="info"
                                >
                                  {{ $t('You are currently logged in using', {tAuthProvider: this.profileData.current_auth_provider}) }}
                                </v-alert>
                                <v-alert
                                  v-if="!this.profileData.profile_complete"
                                  class="full-width"
                                  border="left"
                                  dense
                                  text
                                  type="warning"
                                >
                                  {{ $t('Please complete your profile to be able to signup for a live auction or place commission bids') }}
                                </v-alert>
                              </div>
                              <div class="v-input input-field v-input--hide-details v-text-field v-text-field--is-booted v-text-field--placeholder no-padding-top">
                                <div class="v-input__control">
                                  <v-row class="save-wrapper no-gutters justify-end mt-6">
                                    <v-btn :class="`btn primary-btn save-btn ${$i18n.locale} full-width`" :disabled="disabledSaveData" @click="saveProfile()"><span>{{ $t('Save') }}</span></v-btn>
                                  </v-row>
                                </div>
                              </div>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-col>
                    </v-tab-item>
                    <v-tab-item style="width: 870px; max-width: 100%;" class="mx-auto">
                      <files-management :files="files" :loading="loading" :user-id="+userProfile.id" />
                    </v-tab-item>
                    <v-tab-item>
                      <v-col align="center" class="sub-col-wrapper mt-6" sm="12">
                        <v-data-table
                          :headers="headers"
                          :items="userToAuctionUser"
                          :items-per-page="perPage"
                          class="biddernumber-table"
                          mobile-breakpoint="600"
                          :no-data-text="noDataText"
                          style="max-width: 800px;"
                          dense
                          :footer-props="{
                            'items-per-page-all-text': $t('All'),
                            'items-per-page-text': $t('Rows per page'),
                            'page-text': `{0} - {1} ${$t('of')} {2} ${$t('items')}`,
                            'items-per-page-options': []
                          }"
                        >
                          <template #[`item.type`]="{ item }">
                            {{ $t(item.type) }}
                          </template>
                          <template #[`item.bidder_number`]="{ item }">
                            {{ item.bidder_number || '-' }}
                          </template>
                          <template #[`item.user_bid_limit`]="{ item }">
                            {{ item.user_bid_limit ? euNumbers(item.user_bid_limit) + ' ' + currency : '-' }}
                          </template>
                          <template #[`item.bid_limit`]="{ item }">
                            {{ item.bid_limit ? euNumbers(item.bid_limit) + ' ' + currency : '-' }}
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-tab-item>
                    <v-tab-item>
                      <v-col class="main-col-wrapper pb-5 mb-2">
                        <v-col align="center" class="sub-col-wrapper" sm="12" style="max-width: 472px;">
                          <v-text-field
                            v-model="oldPassword"
                            :rules="[validationRequired()]"
                            :append-icon="showOldPassword ? 'fa-eye' : 'fa-eye-slash'"
                            @click:append="showOldPassword = !showOldPassword"
                            @focus="$refs.passwordField.$el.getElementsByTagName('button')[0].tabIndex = -1"
                            :type="showOldPassword ? 'text' : 'password'"
                            :label="$t('Old Password')"
                            class="input-field password-field input-field-small-append-icon"
                            :class="{'error-border': !oldPassword}"
                          >
                            <template #message="{ message }">
                              {{ $t(message) }}
                            </template>
                          </v-text-field>

                          <div class="input-field password-strength-block">
                            <v-text-field
                              v-model="newPassword" :label="$t('Password')"
                              :append-icon="showPassword ? 'fa-eye' : 'fa-eye-slash'"
                              @click:append="showPassword = !showPassword"
                              @focus="$refs.passwordField.$el.getElementsByTagName('button')[0].tabIndex = -1"
                              :type="showPassword ? 'text' : 'password'"
                              class="input-field password-field input-field-small-append-icon"
                              ref="passwordField"
                              :rules="[validationRequired(), validationPassword()]"
                              :class="{'error-border': !oldPassword}"
                            >
                              <template #message="{ message }">
                                {{ $t(message) }}
                              </template>
                            </v-text-field>
                          </div>

                          <v-text-field
                            v-model="confirmationNewPassword"
                            :label="$t('Confirm Password')"
                            :append-icon="showConfirmPassword ? 'fa-eye' : 'fa-eye-slash'"
                            @click:append="showConfirmPassword = !showConfirmPassword"
                            @focus="$refs.passwordField.$el.getElementsByTagName('button')[0].tabIndex = -1"
                            :type="showConfirmPassword ? 'text' : 'password'"
                            class="input-field password-field input-field-small-append-icon"
                            :rules="[validationRequired(), validationPasswordConfirmation(newPassword, confirmationNewPassword)]"
                            :class="{'error-border': !oldPassword}"
                          >
                            <template #message="{ message }">
                              {{ $t(message) }}
                            </template>
                          </v-text-field>
                        </v-col>
                        <div style="max-width: 472px;">
                          <v-row class="save-wrapper no-gutters justify-end mt-6">
                            <v-btn :class="`btn primary-btn save-btn ${$i18n.locale} full-width`" :disabled="disabledSavePassword" @click="saveProfile()"><span>{{ $t('Change password') }}</span></v-btn>
                          </v-row>
                        </div>
                      </v-col>
                    </v-tab-item>
                  </v-tabs-items>
                </v-col>
              </v-row>
            </div>
          </v-container>
          <Footer />
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'pinia'
import Countries from 'i18n-iso-countries'
import Footer from '@/components/footer'
import FilesManagement from '@/components/blocks/files-management'
import apiRequests from '@/api/apiRequests'
import { validatePassword } from '@/utils'
import { getBidderNumbersFromUser } from '@/services/user';
import { i18n } from '@/main';
import frontendValidations from '@/utils/rules';
import en from 'i18n-iso-countries/langs/en.json'
import de from 'i18n-iso-countries/langs/de.json'
import inputHandlers from '@/utils/inputHandlers';
import _ from 'lodash'
import useRootStore from '@/stores/rootStore';
import { euNumbers } from '@/services/i18n';
import dispatcher from '@/api/dispatch';

Countries.registerLocale(en)
Countries.registerLocale(de)

export default {
  name: 'UserProfileModal',
  components: {
    Footer,
    FilesManagement
  },
  async beforeMount() {
    this.getCurrentProfileData()
    this.tab = this.tabs.indexOf(this.$route.query.tab)
    this.userToAuctionUser = await getBidderNumbersFromUser(this.profileData.id)
    this.countries = Object.entries(Countries.getNames(this.$i18n.locale, { select: 'official' }));
    this.profileLocalization = this.appLocalization
    if (this.tab === 1) {
      await this.getUserFiles()
    }
    this.clearPasswords()
  },
  data() {
    return {
      showPassword: false,
      showOldPassword: false,
      showConfirmPassword: false,
      renderComponent: true,
      newPassword: '',
      confirmationNewPassword: '',
      oldPassword: '',
      profileData: {},
      files: [],
      showProfileModal: false,
      modalPosition: false,
      countries: [],
      profileLocalization: {},
      headers: [
        {
          text: this.$t('Auction code'),
          align: 'left',
          sortable: false,
          value: 'code',
          width: 100
        },
        {
          text: this.$t('Type'),
          sortable: false,
          value: 'type',
          width: 100
        },
        {
          text: this.$t('Bidder number'),
          sortable: false,
          value: 'bidder_number',
          width: 150
        },
        {
          text: this.$t('Bid limit requested'),
          sortable: false,
          value: 'user_bid_limit',
          width: 160
        },
        {
          text: this.$t('Bid limit'),
          sortable: false,
          value: 'bid_limit'
        }
      ],
      noDataText: this.$t('No data available'),
      userToAuctionUser: [],
      tab: 'Personal data',
      perPage: 5,
      page: 1,
      start: 0,
      end: 0,
      pageCount: 0,
      tabs: ['personalData', 'files', 'bidData', 'password'],
      selectedFile: null,
      loading: true
    }
  },
  watch: {
    $route: {
      deep: true,
      handler(to, from) {
        this.tab = this.tabs.indexOf(to.query.tab)
      }
    }
  },
  computed: {
    ...mapState(useRootStore, ['userProfile', 'appSettings', 'appStoreLang', 'appLocalization', 'isMobile']),
    currency () {
      return this.appLocalization.currency
    },
    emptyFields() {
      return false
    },
    /* score() {
      if(this.newPassword)
        return passwordStrength(this.newPassword)
      else
        return {color:'red',score:0}
    }, */
    disabledSavePassword() {
      return (!this.oldPassword || !this.newPassword || !this.confirmationNewPassword || !validatePassword(this.newPassword) || this.newPassword !== this.confirmationNewPassword)
    },
    disabledSaveData() {
      return (this.profileData.first_name === '' ||
        this.profileData.last_name === '' ||
        this.profileData.street === '' ||
        this.profileData.house_number === '' ||
        this.profileData.zipcode === '' ||
        this.profileData.city === '' ||
        this.profileData.telephone1 === '' ||
        this.profileData.country === '')
    }
  },
  methods: {
    ...mapActions(useRootStore, ['updateUserProfile', 'SET_TEMP_ALERT', 'APP_GLOBAL_STORE_LANG']),
    validationRequired: frontendValidations.required,
    validationPassword: frontendValidations.password,
    validationPasswordConfirmation: frontendValidations.passwordConfirmation,
    euNumbers,
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;
      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    langSwitcherValue(value) {
      this.profileData.language = value
    },
    clearPasswords() {
      this.newPassword = ''
      this.oldPassword = ''
      this.confirmationNewPassword = ''
    },
    updateTable() {
      this.headers = []
      this.noDataText = ''
      this.$nextTick(() => {
        this.headers = [
          {
            text: this.$t('Auction Id'),
            align: 'left',
            sortable: false,
            value: 'auction_id'
          },
          {
            text: this.$t('Bidder number'),
            sortable: false,
            value: 'bidder_number'
          }
        ]
        this.noDataText = this.$t('No data available')
      })
    },
    // Checkbox prop
    updateProp (prop, value) {
      this.profileData = {
        ...this.profileData,
        [prop]: value
      }
    },
    getCurrentProfileData () {
      this.profileData = _.clone(this.userProfile)
    },
    // Close profile modal without saving
    closeProfileModal () {
      if (this.userProfile.language !== this.profileData.language) {
        if (this.userProfile.language) {
          this.$i18n.locale = this.userProfile.language
          i18n.locale = this.userProfile.language
          this.APP_GLOBAL_STORE_LANG(this.profileData.language)
          this.profileData.language = this.userProfile.language
          this.forceRerender()
          const entries = Object.entries(Countries.getNames(this.$i18n.locale, { select: 'official' }));
          this.countries = entries
          this.updateTable()
        }
      }
      this.$router.push({ name: 'dashboard' })
    },
    // Save and close profile
    async saveProfile() {
      try {
        if (this.emptyFields) {
          this.SET_TEMP_ALERT({ flavor: 'error', content: `${this.$t('All fields are required')}` })
          return
        }

        if (this.newPassword && this.newPassword.length > 0) {
          if (this.newPassword !== this.confirmationNewPassword || !validatePassword(this.newPassword)) {
            this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('Password error') })
            return
          }
          this.profileData = { ...this.profileData, new_password: this.newPassword, old_password: this.oldPassword }
        }
        delete this.profileData.password;
        // :key="appStoreLang"
        this.APP_GLOBAL_STORE_LANG(this.profileData.language)
        // Vue.config.language  = this.profileData.language
        await this.updateUserProfile(this.profileData)
        // Show bottom mobile navigation bar
        this.$router.push({ name: 'dashboard' })
        this.SET_TEMP_ALERT({ flavor: 'success', content: this.$t('Profile successfully updated') })
        this.clearPasswords()
      } catch (e) {
        this.clearPasswords()
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error updating the user profile. Please try again later') })
      }
    },
    inputZipHandler: inputHandlers.inputZipHandler,
    inputPhoneHandler: inputHandlers.inputPhoneHandler,
    tabChange() {
      if (this.tabs.indexOf(this.$route.query.tab) !== this.tab) {
        this.$router.push({ query: { tab: this.tabs[this.tab] } })
      }
      if (this.tab === 1) {
        this.getUserFiles()
      }
    },
    async getUserFiles() {
      try {
        this.loading = true
        const result = await dispatcher.getFiles(this.userProfile.id)
        this.files = result.files
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },
  }
}
</script>
<style lang="scss">
.user-profile-container .main-card .form-container .password-field.input-field.input-field-error {
  line-height: 13px;
  margin-bottom: 15px !important;
}
.user-tabs {
  .v-tabs-bar {
    height: auto;
    padding: 4px 0;
  }
  .v-tabs-slider-wrapper {
    display: none;
  }
  .v-slide-group__wrapper {
    display: flex;
    justify-content: center;
  }
  .v-slide-group__content {
    justify-content: center;
    flex: none;
    border-bottom: 1px solid #E5E5E5;
  }
  .tab-title {
    position: relative;
    flex: none !important;
    margin-right: 64px;
    padding: 0;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #7B7B7B;
    text-transform: none;
    transition: all .3s ease;
    @media (max-width: 968px) {
      width: 100%;
      margin-right: 0;
      font-size: 20px;
      line-height: 24px;
    }
    &.v-tab--active,
    &:hover {
      color: #262626 !important;
      &:after {
        opacity: 1;
      }
    }
    &:after {
      position: absolute;
      content: '';
      width: 100%;
      height: 1px;
      background: #262626;
      left: 0;
      bottom: -1px;
      opacity: 0;
      transition: all .3s ease;
    }
    &:last-child {
      margin-right: 0;
    }
    &:hover,
    &:active,
    &:focus {
      &:before {
        display: none;
      }
    }
  }
}
.user-profile-container {
  box-shadow: none !important;
  .biddernumber-table.v-data-table {
    th {
      padding: 4px 15px !important;
      font-weight: 600;
      font-size: 14px !important;
      line-height: 20px;
      letter-spacing: 0.02em;
      @media (max-width: 968px) {
        font-size: 12px !important;
        line-height: 20px;
      }
    }
    td {
      padding: 8px 16px !important;
      font-weight: 600;
      font-size: 14px !important;
      line-height: 20px;
      letter-spacing: 0.02em;
      @media (max-width: 968px) {
        font-size: 12px !important;
        line-height: 20px;
      }
    }
  }
}
.files-list {
  max-width: 100%;
  &__item {
    border-bottom: 1px solid #262626;
  }
  &__item-size {
    width: 63px;
    text-align: left;
  }
  &__item-name {
    width: 315px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }
  &__item-remove {
    color: #d62222 !important;
  }
}
#dropzone {
  display: flex;
  justify-content: center;
  align-items: center;
  .dz-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    .dz-image {
      width: 150px;
      height: 150px;
      > div {
        width: inherit;
        height: inherit;
      }
      > img {
        width: 100%;
      }
    }
    .dz-details {
      color: white;
      transition: opacity .2s linear;
      text-align: center;
    }
    .dz-success-mark {
      font-size: 24px;
      text-align: center;
    }
    .dz-progress {
      background: rgba(129, 178, 20, .5);
      .dz-upload {
        background: #81b214;
      }
    }
  }
}
.info-text {
  @media screen and (max-width: 760px) {
    display: flex !important;
  }
}
</style>
