<template>
  <v-dialog width="600" v-model="dialog" @click:outside="closeModal">
    <v-card class="py-8">
      <v-card-title class="justify-center">
        <span class="headline">{{ $t('Do you really want to disconnect the user?') }}</span>
      </v-card-title>
      <v-card-text class="justify-center text-center pb-0 pl-14">
        <v-checkbox
          :label="$t('Block the user from reconnecting')"
          v-model="block"
          class="px-5"
        />
      </v-card-text>

      <v-card-actions class="justify-center flex-wrap action-buttons">
        <v-btn class="btn secondary-btn ma-2" @click="closeModal">
          <span>{{ $t('No') }}</span>
        </v-btn>
        <v-btn class="btn primary-btn ma-2" @click="$emit('submit', block), closeModal()">
          <span>{{ $t('Yes') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AdminDisconnectUserModal',
  data() {
    return {
      dialog: false,
      block: false
    }
  },
  methods: {
    openModal() {
      this.dialog = true
    },
    closeModal() {
      this.dialog = false
      this.block = false
      this.$emit('resetUserId')
    }
  }
}
</script>

<style scoped lang="scss">
.btn {
  width: 140px;
}
</style>
