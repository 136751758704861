<template>
  <v-dialog
    :value="commissionBidDialog"
    width="700"
    content-class="bid-modal-content"
    @keydown.esc="closeModal"
    @keydown.enter="isFormValid ? sendBid() : {};"
    persistent
  >
    <v-card class="pa-3 pa-sm-5">
      <v-btn class="modal-close-btn icon-with-hover" icon @click="closeModal"><v-icon>fa-times</v-icon></v-btn>
      <h2 class="pt-2 text-center" v-if="editBid">{{ $t('Change bid') }}</h2>
      <h2 class="pt-2 text-center" v-else>{{ $t('Submit absentee bid') }}</h2>
      <p class="pt-2 mb-0 mb-sm-4 bid-modal-text" v-if="editBid">
        <span>
          {{ $t('absentee_bids_edit_label1') }}
        </span>
      </p>
      <p class="bid-modal-text mb-0 mb-sm-4">
        <span>
          {{ $t('absentee_bids_label1') }}<br>
          {{ $t('absentee_bids_label2', {tArticleLimit: selectedArticle.limit, tCurrency: appLocalization.currency}) }}
        </span>
      </p>
      <v-form ref="bidForm" v-model="isFormValid" @submit.prevent>
        <v-text-field
          type="number"
          v-model="bid"
          @input="inputBidHandler"
          :rules="[validationRequired(), validationHigherThanLimit(selectedArticle?.limit, 'validation.mustBeHigherThanLimit'), !appFeatures.validateCommissionBids ? validationDividedBy(appSettings.commissionBidMinimumBidStep, appSettings.commissionBidMinimumBidStep, appLocalization.currency) : true]"
          :error="validbids.error"
          :error-messages="validbids.msg"
          :label="$t('Your absentee bid')"
          placeholder=" "
          persistent-hint
          class="input-field pt-0 pt-sm-3"
          single-line
        >
          <template #message="{ message }">
            {{ translateValidationIfNeeded(message) }}
          </template>
        </v-text-field>
        <v-checkbox
          hide-details
          class="input-field"
          v-model="pickup"
          :label="$t(pickupText)"
          :disabled="pickupRequired"
        />
        <v-checkbox
          v-if="!this.selectedAuctionData?.no_telephone_bids"
          hide-details
          class="input-field"
          v-model="bidByPhoneActivated"
          :label="`${$t('Bid by telephone')} (${$t('available from')} ${ appLocalization.currency } ${ euNumbers(appSettings.minimum_bid_for_telephone_bidding) })`"
          :disabled="bidByPhoneDisabled || validbids.error"
        />
        <div class="info-text full-width mt-2">
          <v-icon>fa-circle-info</v-icon>
          <p>
            {{ $t('absentee_bids_info') }}
            <br>
            <span v-if="appSettings.commission && appSettings.commission.absentee !== 0">{{ $t('absentee_bids_info_commission', {tAuctionCommissionRate: selectedAuctionData?.surcharge, tCommissionBiddingRate: appSettings.commission.absentee}) }}</span>
            <span v-else>{{ $t('absentee_bids_info_commission_none', {tAuctionCommissionRate: selectedAuctionData?.surcharge}) }}</span><br/>
            <span v-if="showTotalCommissionCosts">{{totalCostsText}}</span>
          </p>
        </div>
        <div class="text-center mt-2">
          <v-btn
            class="btn primary-btn success full-width"
            :disabled="disabledBidButton || validbids.error"
            @click="sendBid"
            :loading="loading"
          >
            <v-icon class="mr-2">fa-envelope</v-icon>
            {{ $t('Submit bid') }}
          </v-btn>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions, mapState} from 'pinia'
import { localFeatures, timeouts } from '@/utils/constants'
import dispatcher from '@/api/dispatch';
import _ from 'lodash';
import frontendValidations from '@/utils/rules';
import useRootStore from '@/stores/rootStore';
import { euNumbers, translateValidationIfNeeded } from '@/services/i18n';

export default {
  name: 'ChangeBidModal',
  data () {
    return {
      bid: null,
      pickup: false,
      bidByPhoneActivated: false,
      bidByPhoneDisabled: true,
      disabledBidButton: true,
      isFormValid: false,
      validbids: { error: false, msg: '' },
      showTotalCommissionCosts: localFeatures.showTotalCommissionCosts
    }
  },
  props: {
    commissionBidDialog: {
      type: Boolean,
      required: true
    },
    editBid: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    selectedArticle: {
      type: Object,
      required: true
    },
    activePage: {
      type: Number,
      default: null
    },
    activeCategory: {
      type: Number,
      default: null
    },
    updateData: {
      type: Boolean,
      required: true
    },
    selectedAuctionData: {
      type: Object,
      default: null
    }
  },
  emits: ['getAuctionDetail', 'setLoading'],
  watch: {
    // evaluate every time the modal is opened
    commissionBidDialog(val) {
      if (val) {
        this.checkIfAuctionHasNoShipping()
      }
    },
  },
  methods: {
    ...mapActions(useRootStore, ['SET_TEMP_ALERT']),
    translateValidationIfNeeded,
    validationRequired: frontendValidations.required,
    validationHigherThanLimit: frontendValidations.higherThanLimit,
    validationDividedBy: frontendValidations.dividedBy,
    /**
     * Check if an auction has shipping disabled
     * @return {boolean} - True if shipping is disabled, otherwise false
     */
    checkIfAuctionHasNoShipping() {
      if (this.commissionBidDialog && ((this.selectedArticle && this.selectedArticle.no_shipping) || (this.selectedAuctionData && this.selectedAuctionData.no_shipping))) {
        this.pickup = true;
      }
    },
    euNumbers,
    /**
     * Close the modal and reset input data
     * @return {void} - Nothing
     */
    closeModal() {
      this.$refs.bidForm.reset()
      this.$emit('closeBidModal')
    },
    /**
     * Validate bids on every bid input after a debounce period. Sets the submit button to disabled and shows an error if the bid is incorrect
     * @return {void} - Nothing
     */
    inputBidHandler: _.debounce(function (e) {
      this.validbids.error = false;
      this.validbids.msg = '';
      if (+this.appSettings.minimum_bid_for_telephone_bidding > +e) {
        this.bidByPhoneDisabled = true
        this.bidByPhoneActivated = false
      } else {
        this.bidByPhoneDisabled = false
      }
      if (this.appFeatures.validateCommissionBids) {
        this.disabledBidButton = !this.selectedArticle.limit || this.selectedArticle.limit > +e;
      } else {
        this.disabledBidButton = !this.selectedArticle.limit || this.selectedArticle.limit > +e || +e % this.appSettings.commissionBidMinimumBidStep !== 0;
      }
      if (!this.disabledBidButton && this.appFeatures.validateCommissionBids) {
        this.validateBids(e);
      }
    }, timeouts.debounce),
    /**
     * Validate the bids via the backend
     * @param {any} bid - The bid to validate
     * @return {void} - Nothing
     */
    async validateBids(bid) {
      try {
        if (bid) {
          const data = {
            commission_bids: [
              {
                auction_id: this.selectedAuctionData.id,
                articles_id: this.selectedArticle.id,
                commission_bid: parseInt(bid),
                pickup: this.pickup,
                bid_by_telephone: this.bidByPhoneActivated
              }
            ]
          }
          const res = await dispatcher.validateCommissionBids(this.selectedAuctionData.id, data)
          if (res && !res[0].valid) {
            this.validbids.error = true;
            this.validbids.msg = this.$t('Invalid commission bid, the current bid step is') + ' ' + res[0].bid_step + '. ' + this.$t('The next valid bid would be') + ' ' + res[0].next_valid_commission_bid;
          } else {
            this.validbids.error = false;
            this.validbids.msg = '';
          }
        }
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error validating the commission bids. Please try again later') })
        this.validbids.error = true
      }
    },
    /**
     * Submit the bid, show a response toest and close the modal on success
     * @return {void} - Nothing
     */
    async sendBid() {
      try {
        const data = {
          commission_bids: [
            {
              auction_id: this.selectedAuctionData.id,
              articles_id: this.selectedArticle.id,
              commission_bid: +this.bid,
              pickup: this.pickup,
              bid_by_telephone: this.bidByPhoneActivated
            }
          ]
        }

        this.$emit('setLoading', true)
        await dispatcher.placeCommissionBids(this.selectedAuctionData.id, data)
        this.SET_TEMP_ALERT({ flavor: 'success', content: this.$t('We have received your absentee bids and will process it as soon as possible') })
        if (this.updateData) this.$emit('getAuctionDetail', this.selectedAuctionData.id, this.activePage, this.activeCategory, false, false)
        setTimeout(() => {
          this.$emit('setLoading', false)
          this.closeModal()
        }, timeouts.closeModal)
      } catch (e) {
        if (e.data?.error && e.data.data[0].errorShortText === 'user.commission.bid.exists') {
          this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('You already placed this absentee bid') })
        } else if (e.data?.error && e.data.data[0].errorShortText === 'user.profile.incomplete') {
          this.$swal.fire({
            position: 'center',
            icon: 'error',
            title: this.$t('An error has occured'),
            text: this.$t('Your profile is incomplete and is missing required information for this functionality. Please complete your profile and try again'),
            showConfirmButton: false,
            timer: timeouts.closeSweetalert
          })
        } else {
          this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('A technical error occured while trying to submit absentee bids. Please try again later.') })
        }
        this.$emit('setLoading', false)
      }
    },
  },
  computed: {
    ...mapState(useRootStore, ['appLocalization', 'appSettings', 'appFeatures']),
    pickupText() {
      if (this.selectedAuctionData && this.selectedAuctionData.no_shipping) {
        return 'Pick up this lot if bought (shipping is impossible for that auction)'
      } else if (this.selectedArticle && this.selectedArticle.no_shipping) {
        return 'Pick up this lot if bought (shipping is impossible for that article)';
      } else {
        return 'Pick up this lot if bought';
      }
    },
    totalCostsText() {
      const multiplier = (this.selectedAuctionData?.tax > 0) ? ((this.selectedAuctionData?.tax / 100) +1) : 1
      const costs = ((this.selectedAuctionData?.surcharge + this.appSettings?.commission.absentee) * multiplier).toFixed(2)
      return this.$t('absentee_bids_info_total_costs', {tCommissionBiddingTotalCosts: costs})
    },
    pickupRequired() {
      return (this.selectedArticle && this.selectedArticle.no_shipping) || (this.selectedAuctionData && this.selectedAuctionData.no_shipping)
    }
  }
}
</script>

<style scoped lang="scss">
.info-text {
  @media (max-width: 700px) {
    padding: 10px;
  }
  .v-icon {
    @media (max-width: 700px) {
      font-size: 18px;
    }
  }
  p {
    @media (max-width: 700px) {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: .02em;
      margin-left: 5px;
    }
  }
}
</style>
