import Papa from 'papaparse';

/**
 * parse a csv file and return the results
 * @param {any} file - The file
 * @return {<any>} - The json parsed contents or an exception if an error occured
 */
export function processCSVFile(file: any): Promise<any> {
  return new Promise((resolve, reject) => {

    Papa.parse(file, {
      header: true,
      dynamicTyping: true,
      complete: function(results) {
        resolve({
          data: results.data
        });
      },
      error: function (error, file) {
        reject(error)
      }
    });
  })
}

export function saveAsCSV(filename = 'my_csv.csv', rows: Array<any>) {
  let csvContent = 'data:text/csv;charset=utf-8,' +
    rows.map(e => e.join(',')).join('\n');

  let url = encodeURI(csvContent);
  let link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  document.body.appendChild(link); // Required for FF
  link.click();
  link.remove();
}
