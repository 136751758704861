<template>
  <v-card class="overflow-hidden auth-container-wrapper">
    <v-navigation-drawer
      :value="adminDrawer"
      app
      class="elevation-1"
      width="200"
    >
      <v-toolbar-title class="pl-3 mt-6 mb-9">Navigation</v-toolbar-title>
      <v-row class="main-header ma-0 mt-2 flex-nowrap">
        <ul class="nav-items full-width flex-column">
          <li
            class="nav-item my-2"
            v-for="item in navs"
            :key="item.url"
            :class="{'active': $route.path === item.url || (item.children && item.children.findIndex(el => el.route && el.route.name === $route.name) >= 0)}"
          >
            <v-list-group v-if="item.children && item.children.length" content-class="sub-menu">
              <template #activator>
                <p v-if="item.url" class="mb-0 px-2">
                  <router-link :to="item.url" :target="item.target">
                    {{ item.name }}
                    <v-icon>{{ item.icon }}</v-icon>
                  </router-link>
                </p>
                <a class="mb-0 px-2" v-else>
                  {{ item.name }}
                  <v-icon small>{{ item.icon }}</v-icon>
                </a>
              </template>
              <v-list>
                <v-list-item
                  v-for="(child, i) in item.children"
                  :key="`route-${i}`"
                >
                  <a href="javascript:void(0)" class="navigation-icon mx-0 py-2" v-if="child.callback" @click="child.callback">
                    <v-icon small>{{ child.icon }}</v-icon>
                    <span class="ml-2">{{ child.title }}</span>
                  </a>
                  <router-link :to="child.route" :target="item.target" class="navigation-icon mx-0 py-2" v-else-if="child.route">
                    <v-icon small>{{ child.icon }}</v-icon>
                    <span class="ml-2">{{ child.title }}</span>
                  </router-link>
                </v-list-item>
              </v-list>
            </v-list-group>
            <router-link class="px-2" :to="item.url" :target="item.target" v-else>
              {{ item.name }}
              <v-icon small>{{ item.icon }}</v-icon>
            </router-link>
          </li>
        </ul>
      </v-row>
    </v-navigation-drawer>
    <v-app-bar style="z-index:2;" class="main-header" elevation="0" height="88">
      <v-row class="ma-0 flex-nowrap" align="center">
        <a :href="appSettings.url_auction_house" target="_blank" class="logo">
          BID4IT.
        </a>

        <v-app-bar-nav-icon :disabled="$route.name === 'streaming'" class="icon-with-hover open-menu-icon mx-4" @click.stop="useRootStore().SET_ADMIN_DRAWER(!adminDrawer)" width="48px" height="48px" :class="{ active: adminDrawer }" />

        <div class="user-count">
          <div v-if="realtimeClientData"><span class="grey-text">{{ $t('Registered') }}</span>&nbsp;<span>{{ realtimeClientData.registered }}</span></div>
          <div v-if="clientList"><span class="grey-text">{{ $t('Logged in') }}</span>&nbsp;<span class="cursor-pointer" @click="openUsersCountModal">{{ loggedInUserCount }}</span></div>
          <div v-if="appFeatures.guestAuctionFunctionality && guestList"><span class="grey-text">{{ $t('Guests') }}</span>&nbsp;<span>{{ loggedInGuestCount }}</span></div>
          <div v-if="streamStatusEnabled"><span class="grey-text">{{ $t('Streaming') }}</span>&nbsp;<span>{{ clientsText }}</span></div>
        </div>

        <v-row class="ma-0 mx-3 align-center status-block flex-nowrap justify-space-between">
          <SocketStatusBar/>
          <v-icon class="ml-3 green--text blinking" small v-if="showUpdating">fa-download</v-icon>
        </v-row>
      </v-row>

      <v-row v-if="$route.name !== 'streaming'" class="ma-0" justify="end">
        <v-btn class="navigation-icon" width="48px" height="48px" icon small @click="$router.push('/auction-admin')" v-if="$route.name !== 'auctionAdmin' && $route.name !== 'streaming' && currentAuction.started_by_user_id === userProfile.id">
          <v-icon>fa-times</v-icon>
        </v-btn>

        <theme-switch />

        <v-btn
          v-if="currentAuction.type === 'live' && (currentAuction.status === 'preparing' || currentAuction.status === 'stopped')"
          @click="startAuction({ auctionId: currentAuction.id})" class="navigation-icon" width="48px" height="48px" icon
        >
          Start an auction
          <v-icon class="start-auction-icon">fa-play</v-icon>
        </v-btn>

        <v-btn
          v-if="currentAuction.type === 'live' && (currentAuction.status === 'started' || currentAuction.status === 'paused')" @click="stopHandler"
          class="navigation-icon" width="48px" height="48px" icon
        >
          <v-icon class="stop-auction-icon">fa-stop</v-icon>
        </v-btn>

        <v-btn
          v-if="currentAuction.type === 'live' && currentAuction.status === 'started'" @click="doAction({type: 'pause',auctionId:currentAuction.id})" class="navigation-icon"
          width="48px" height="48px" icon
        >
          <v-icon class="pause-auction-icon auction-operation-pause">fa-pause</v-icon>
        </v-btn>

        <v-btn
          v-if="currentAuction.type === 'live' && currentAuction.status === 'paused'" @click="doAction({type: 'resume',auctionId:currentAuction.id})"
          class="navigation-icon" width="48px" height="48px" icon
        >
          <v-icon class="start-auction-icon">fa-play</v-icon>
        </v-btn>

        <v-btn @click="openSendMessageModal" class="settings-icon navigation-icon" width="48px" height="48px" icon>
          <v-icon small>fa-comment-alt</v-icon>
        </v-btn>

        <v-menu offset-y close-on-content-click open-on-hover>
          <template #activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="navigation-icon"
              width="48px"
              height="48px"
              icon
            >
              <v-icon style="font-weight: 400;">fa-question-circle</v-icon>
            </v-btn>
          </template>
          <v-list dense class="sub-menu">
            <v-list-item @click="$refs.aboutModal.open()">
              <v-list-item-title class="navigation-icon ma-0">{{ $t('About') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn
          class="navigation-icon"
          width="48px"
          height="48px"
          icon
          @click="logoutHandler"
        >
          <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 20C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H15C15.2652 0 15.5196 0.105357 15.7071 0.292893C15.8946 0.48043 16 0.734784 16 1V4H14V2H2V18H14V16H16V19C16 19.2652 15.8946 19.5196 15.7071 19.7071C15.5196 19.8946 15.2652 20 15 20H1ZM14 14V11H7V9H14V6L19 10L14 14Z" :fill="globalTheme === 'dark' ? '#ffffff' : '#000000'" />
          </svg>
        </v-btn>
      </v-row>
      <v-row v-if="($route.name === 'streaming' && userProfile.role === 'streamer')" class="ma-0" justify="end">
        <v-btn
          class="navigation-icon"
          width="48px"
          height="48px"
          icon
          @click="logoutHandler"
        >

          <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 20C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H15C15.2652 0 15.5196 0.105357 15.7071 0.292893C15.8946 0.48043 16 0.734784 16 1V4H14V2H2V18H14V16H16V19C16 19.2652 15.8946 19.5196 15.7071 19.7071C15.5196 19.8946 15.2652 20 15 20H1ZM14 14V11H7V9H14V6L19 10L14 14Z" :fill="globalTheme === 'dark' ? '#ffffff' : '#000000'"/>
          </svg>

        </v-btn>
      </v-row>
    </v-app-bar>
    <about-admin-modal ref="aboutModal" :versions="versions" />
    <stop-auction-modal ref="stopAuctionModal" @submit="submitStopAuction" :auction="currentAuction" />
    <confirmation-modal
      :title="$t('Do you really want to set the auction to completed?')"
      :text="$t('This will mark the auction as completed and enables the results display as well as the post-auction sale if configured for this auction')"
      @submit="submitStop"
      @cancel="cancelStop"
      ref="confirmationModal"
    />
    <connected-users-modal ref="connectedUsersModal" />
    <send-message-modal ref="sendMessageModal" />
  </v-card>
</template>
<script>
import { mapActions, mapState } from 'pinia'
import ConfirmationModal from '@/components/modals/confirmation-modal'
import AboutAdminModal from '@/components/modals/admin-about-modal'
import StopAuctionModal from '@/components/modals/admin-auction-stop-modal'
import ConnectedUsersModal from '@/components/modals/admin-show-connected-users-modal'
import SendMessageModal from '@/components/modals/admin-send-message-modal'
import SocketStatusBar from '@/components/status/socket'
import apiRequests from '@/api/apiRequests'
import ThemeSwitch from '@/components/header/theme-switch'
import { Role } from '@/utils/constants';
import _ from 'lodash'
import useRootStore from '@/stores/rootStore';
import useAuctionStore from '@/stores/auctionStore';
import dispatcher from '@/api/dispatch';

export default {
  name: 'AdminNav',
  components: {
    ConfirmationModal,
    AboutAdminModal,
    StopAuctionModal,
    ConnectedUsersModal,
    SendMessageModal,
    SocketStatusBar,
    ThemeSwitch
  },
  data () {
    return {
      streamStatus: 'no_connection',
      streamClients: 0,
      interval: 0,
      versions: null,
      navs: [
        {
          url: '/settings/auctions',
          name: this.$t('Auctions')
        },
        {
          url: '/settings/categories',
          name: this.$t('Categories')
        },
        {
          url: '/settings/users',
          name: this.$t('Users'),
          children: [
            {
              title: this.$t('Users'),
              route: {
                name: 'AdminUsers'
              },
              icon: 'fa-signal'
            },
            {
              title: this.$t('Active users'),
              callback: () => {
                this.openUsersCountModal()
              },
              icon: 'fa-signal'
            },
          ]
        },
        {
          url: '/streaming',
          name: this.$t('Live-Stream'),
          target: '_blank',
          icon: 'fa-external-link-alt'
        },
        {
          url: '/settings/system',
          name: this.$t('System')
        },
        {
          url: '/settings/mailing',
          name: this.$t('Mailing')
        },
        {
          name: this.$t('Reports'),
          children: [
            {
              title: this.$t('Bid transactions'),
              route: {
                name: 'bidTransactions'
              },
              icon: 'fa-history'
            },
            {
              title: this.$t('Statistics'),
              route: {
                name: 'statistics'
              },
              icon: 'fa-chart-line'
            },
            {
              title: this.$t('E-mails'),
              route: {
                name: 'emails'
              },
              icon: 'fa-envelope'
            },
            {
              title: this.$t('Inquiries'),
              route: {
                name: 'inquiries'
              },
              icon: 'fa-envelope'
            }
          ]
        }
      ],
      showUpdating: false
    }
  },
  computed: {
    ...mapState(useAuctionStore, ['currentAuction', 'currentAuctionArticles', 'usersData']),
    ...mapState(useRootStore, ['clientList', 'realtimeClientData', 'appSettings', 'userProfile', 'appFeatures', 'disconnectedTime', 'adminDataChange', 'adminDrawer', 'globalTheme', 'socket', 'guestList']),
    loggedInUserCount() {
      return this.clientList.filter((item) => item.role === Role.user).length
    },
    loggedInGuestCount() {
      return this.guestList.length
    },
    streamStatusEnabled() {
      return this.appSettings.url_stream_type !== 'stream_disabled'
    },
    clientsText() {
      if (this.streamStatus === 'no_connection') return this.$t('Connection error')
      if (this.streamStatus === 'stream_not_started') return this.$t('Stream stopped')
      return this.streamClients
    }
  },
  watch: {
    adminDataChange: {
      deep: true,
      handler() {
        this.showUpdating = true
        setTimeout(() => {
          this.showUpdating = false
        }, 1000)
      }
    }
  },
  methods: {
    useRootStore,
    ...mapActions(useRootStore, ['logout', 'UPDATE_GLOBAL_STATE', 'SET_TEMP_ALERT', 'SET_LOGOUT_CLICKED']),
    ...mapActions(useAuctionStore, ['startAuction', 'doAction', 'getCurrentActiveAuction', 'CHANGE_STATE']),
    async getNumberOfViewers() {
      const response = await dispatcher.getStreamStats()
      this.streamStatus = response.status
      this.streamClients = response.count_clients
    },
    stopHandler() {
      const { ongoingArticle } = this.currentAuction
      let index = _.findIndex(this.currentAuctionArticles, ['id', ongoingArticle.id]);
      if (index + 1 >= this.currentAuctionArticles.length) {
        this.$refs.confirmationModal.openModal()
      } else {
        this.$refs.stopAuctionModal.openModal()
      }
    },
    submitStop() {
      this.doAction({ type: 'complete', auctionId: this.currentAuction.id})
      this.$refs.confirmationModal.closeModal()
    },
    cancelStop() {
      this.$refs.confirmationModal.closeModal()
    },
    async submitStopAuction(force) {
      try {
        const payload = {
          auctionId: this.currentAuction.id,
          force
        }
        await dispatcher.stopAuction(payload)
        this.SET_TEMP_ALERT({ flavor: 'success', content: this.$t('The auction/sale has been stopped successfully') })
        this.$refs.stopAuctionModal.closeModal()
        this.CHANGE_STATE({ key: 'currentAuction', value: {} })
        this.UPDATE_GLOBAL_STATE({ root: true })
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error stopping the auction/sale') })
      }
    },
    openUsersCountModal() {
      this.$refs.connectedUsersModal.openModal()
    },
    openSendMessageModal() {
      this.$refs.sendMessageModal.openModal()
    },
    logoutHandler() {
      this.SET_LOGOUT_CLICKED(true)
      this.logout()
    }
  },
  async created() {
    if(this.userProfile.role === Role.admin) {
      if (_.isEmpty(this.currentAuction)) await this.getCurrentActiveAuction()
      this.versions = await dispatcher.getVersions()
      await this.getNumberOfViewers()
      this.interval = setInterval(() => {
        this.getNumberOfViewers()
      }, 60000)
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
}
</script>
<style lang="scss" scoped>
.logo {
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: #262626;
}
.versions {
  box-shadow: none !important;
  .v-card__text {
    color: #262626 !important;
  }
}
.status-block {
  padding: 0 10px;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  max-width: 225px;
  width: 225px;
}
.blinking {
  animation: blink-animation 0.5s infinite;
}
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
</style>
