import mitt from 'mitt';

export enum GlobalEventType {
  updateArticle = 'updateArticle'
}

export default class GlobalEvents {
  private static emitter: any;

  /**
   * Get the mitt emitter, construct it on first call
   * @return {any} - The emitter
   */
  static getEmitter(): any {
    if (GlobalEvents.emitter === undefined) {
      GlobalEvents.emitter = mitt()
    }

    return GlobalEvents.emitter;
  }

  /**
   * subscribe to an event
   * @param {GlobalEventType} event -  The event name to register
   * @param {Function} callback - The callback
   * @return {void} - Nothing
   */
  static subscribeEvent(event: GlobalEventType, callback: Function): void {
    const emitter = GlobalEvents.getEmitter();
    console.log(`subscribed with event name ${event}`);
    emitter.on(event, callback)
  }

  /**
   * unsubscribe from an event
   * @param {GlobalEventType} event -  The event name to register
   * @param {Function} callback - The callback
   * @return {void} - Nothing
   */
  static unsubscribeEvent(event: GlobalEventType, callback: Function): void {
    const emitter = GlobalEvents.getEmitter();
    console.log(`unsubscribed with event name ${event}`);
    emitter.off(event, callback);
  }

  /**
   * unsubscribe from all events
   * @return {void} - Nothing
   */
  static unsubscribeAllEvents(): void {
    const emitter = GlobalEvents.getEmitter();
    console.log("unsubscribed all events")
    emitter.all.clear();
  }

  /**
   * emit an event
   * @param {GlobalEventType} event -  The event name to emit
   * @param {any} [data] - The data
   * @return {void} - Nothing
   */
  static emitEvent(event: GlobalEventType, data?: any): void {
    const emitter = GlobalEvents.getEmitter();
    console.log(`event emitted with event name ${event}`)
    emitter.emit(event, data);
  }
}
