<script>

import moment from 'moment';

export default {
  name: 'CounterMixin',
  data() {
    return {
      redrawRemainingTime: moment().utc()
    }
  },
  mounted() {
    setInterval(() => {
      this.redrawRemainingTime = moment().utc()
    }, 1000)
  },
  methods: {
    getRemainingTime(until, current) {
      const remainingSeconds = moment(until).diff(current, 'seconds')
      if (remainingSeconds < 0) return false
      const days = Math.floor(remainingSeconds / 3600 / 24)
      const hours = Math.floor((remainingSeconds - days * 3600 * 24) / 3600)
      const minutes = Math.floor((remainingSeconds - days * 3600 * 24 - hours * 3600) / 60)
      const seconds = Math.floor(remainingSeconds - days * 3600 * 24 - hours * 3600 - minutes * 60)
      const hms = `${hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`
      if (days > 0) {
        return `${this.$tc('days', days)} ${hms}`
      } else {
        return hms;
      }
    }
  }
}
</script>
