<template>
  <div>
    <p
      class="catalogue__sidebar-item" :class="{ active: activeCategory === category.id }"
      v-for="category in sortedCategories" :key="`category-${category.id}`" @click.self="categoryAction(category.id)"
      v-show="!!category.articlesCount"
    >
      {{ translateCategoryName(category, appStoreLang) }} ({{ category.articlesCount }})
      <v-icon v-if="activeCategory === category.id" @click="categoryAction(null)">fa-close</v-icon>
    </p>
  </div>
</template>

<script>
import { sortCategories, translateCategoryName } from '@/services/category';
export default {
  name: 'CategoryFilter',
  emits: ['setCategory', 'closeModal'],
  data () {
    return {
      sortedCategories: []
    }
  },
  props: {
    categories: {
      type: Array,
      default: () => []
    },
    activeCategory: {
      type: Number,
      default: null
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    appStoreLang: {
      type: String,
      default: 'de'
    }
  },
  computed: {},
  mounted() {
    this.sortCategories()
  },
  watch: {
    categories() {
      this.sortCategories();
    },
    appStoreLang() {
      this.sortCategories();
    }
  },
  methods: {
    translateCategoryName,
    sortCategories() {
      this.sortedCategories = sortCategories(this.categories, this.appStoreLang);
    },
    categoryAction(action) {
      this.$emit('setCategory', action)
      this.$emit('closeModal')
    }
  },
}
</script>
