<template>
  <div id="app-user-auction-container" class="auction-container pt-0">
    <div class="inquiry-container">
      <div class="close-btn-wrapper">
        <v-btn class="close-btn" @click="$router.go(-1)">
          <v-icon class="close-modal-icon">fa-times</v-icon>
        </v-btn>
        <v-btn class="close-btn sync mt-2 icon-with-hover" @click="syncData">
          <v-icon class="close-modal-icon">fa-sync</v-icon>
        </v-btn>
      </div>
      <v-col sm="12" class="full-width">
        <v-row class="search-wrap">
          <v-col align="center">
            <div class="page-title my-3">{{ $t('Inquiries') }}</div>
          </v-col>
        </v-row>
      </v-col>
      <v-card class="main-card" flat>
        <v-container>
          <div>
            <v-row align="center" class="form-container justify-center pb-4">
              <v-row class="mx-0 align-end" v-if="auctions && tmpArticles.length">
                <v-col cols="6">
                  <v-text-field
                    v-model="search"
                    append-icon="fa-magnifying-glass"
                    :label="$t('Search')"
                    hide-details
                    @input="searchHandler"
                  />
                </v-col>
                <v-col cols="3">
                  <search-box
                    :currentAuction="selectedAuctionData"
                    :auctions="auctions"
                    hide-create-new
                    title="Auctions"
                    @onSelectAuction="selectAuction"
                    key="auction-search"
                    show-auction-type
                    hide-title
                  />
                </v-col>
                <v-col cols="3">
                  <v-select
                    class="select-input "
                    hide-details="true"
                    v-model="inquiryFilter"
                    :items="inquiryItems"
                    append-icon="fa-angle-down"
                    :label="$t('Inquiry type')"
                    @input="searchHandler"
                  />
                </v-col>
              </v-row>
              <v-progress-circular v-if="loading && !error" class="full-width loading-icon mt-6" indeterminate />
              <template v-else-if="!error">
                <v-col sm="12" v-if="tmpArticles.length" class="pb-16">
                  <v-data-table
                    :headers="headers"
                    :items="tmpArticles"
                    :items-per-page="itemPerPage"
                    class="pb-16 pb-sm-0"
                    item-class="edit-article-row"
                    sort-by="id"
                    must-sort
                    sort-desc
                    ref="table"
                    :no-data-text="$t('No bid transactions found')"
                    :key="key"
                    dense
                    :footer-props="{
                      'items-per-page-all-text': $t('All'),
                      'items-per-page-text': $t('Rows per page'),
                      'page-text': `{0} - {1} ${$t('of')} {2} ${$t('items')}`,
                      'items-per-page-options': [50, 100, 150, 200]
                    }"
                  >
                    <template #[`item.created_at`]="{ item }">
                      <span class="text-light">{{ getDate(item.created_at) }}</span>
                    </template>
                    <template #[`item.type`]="{ item }">
                      <span class="text-light">{{ getInquiryType(item) && getInquiryType(item).text }}</span>
                    </template>
                    <template #[`item.article_number`]="{ item }">
                      <span class="text-light" v-if="item.article_number_optional">{{ item.article_number_optional }} <v-icon small class="icon-with-hover" @click="clickArticle(item)">fa-external-link-alt</v-icon></span>
                      <span class="text-light" v-else>-</span>
                    </template>
                    <template #[`item.user_data`]="{ item }">
                      <span class="text-light"><span v-html="item.user_data" /> <v-icon small class="icon-with-hover" @click="clickUser(item)">fa-external-link-alt</v-icon></span>
                    </template>
                    <template #[`item.email_code`]="{ item }">
                      <v-icon v-if="item.email_code === 200" class="green-color-success">fa-check</v-icon>
                      <v-icon v-else class="red-color">fa-times</v-icon>
                    </template>
                  </v-data-table>
                </v-col>
                <div class="full-width mt-6" v-else>
                  <div class="info-text" style="max-width: 400px;">
                    <v-icon>fa-circle-info</v-icon>
                    <p>{{ $t('No inquiries found') }}</p>
                  </div>
                </div>
              </template>
              <v-col sm="12" class="text-center" v-else>
                <h3 class="no-data-text">{{ $t(error) }}</h3>
              </v-col>
            </v-row>
          </div>
        </v-container>
        <Footer />
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import Footer from '@/components/footer/index'
import SearchBox from '@/views/settings/articles/sections/search'
import apiRequests from '@/api/apiRequests'
import _ from 'lodash'
import { timeouts } from '@/utils/constants'
import dispatcher from '@/api/dispatch';
import moment from 'moment';
import useRootStore from "@/stores/rootStore";
import { euNumbers } from '@/services/i18n';

export default {
  name: 'BidTransactions',
  components: {
    Footer,
    SearchBox
  },
  data: function () {
    return {
      renderComponent: true,
      search: null,
      inquiryFilter: 'all',
      inquiryItems: [
        {
          value: 'all',
          text: this.$t('All')
        },
        {
          value: 'general',
          text: this.$t('General_inquiry')
        },
        {
          value: 'article',
          text: this.$t('Article')
        },
        {
          value: 'post-auction-sale',
          text: this.$t('Post auction sale')
        },
        {
          value: 'instant-buy',
          text: this.$t('Instant buy')
        },
      ],
      headers: [
        {
          text: this.$t('Created at'),
          value: 'created_at',
          sortable: false,
          width: 230
        },
        {
          text: this.$t('User'),
          value: 'user_data',
          sortable: false,
          width: 400
        },
        {
          text: this.$t('Article #'),
          value: 'article_number',
          width: 130
        },
        {
          text: this.$t('Inquiry type'),
          value: 'type',
          sortable: false,
          width: 130
        },
        {
          text: this.$t('Message'),
          value: 'message',
          sortable: false,
        }
      ],
      itemPerPage: 50,
      page: 0,
      start: 0,
      end: 0,
      key: 0,
      error: '',
      articles: [],
      inquiries: [],
      tmpArticles: [],
      loading: false,
      auctions: [],
      selectedAuctionData: {
        id: 'all'
      },
      currentAuctionId: 'all',
    }
  },
  computed: {
    ...mapState(useRootStore, ['appStoreLang', 'users', 'auctionsFetchedArticles', 'appLocalization']),
  },
  async created() {
    this.loading = true;
    await this.getAuctions()
    await this.getUsers()
    await this.getInquiries()
    this.loading = false
  },
  methods: {
    ...mapActions(useRootStore, ['getAdminArticles', 'fetchAdminUsers', 'fetchUsers']),
    euNumbers,
    changePage (el) {
      this.page = el
      this.$refs.table.$el.scrollIntoView({ behavior: 'smooth' })
    },
    forceRerender() {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },

    getInquiryType(item) {
      return this.inquiryItems.find(el => el.value === item.type)
    },

    async getInquiries() {
      try {
        const result = await dispatcher.getInquiries()
        const inquiries = result.map(inquiry => {
          const user = this.users.find(user => inquiry.users_id === user.id)
          const article = this.articles.find(article => inquiry.articles_id === article.id)
          return {
            ...inquiry,
            user_data: `${user.last_name} ${user.first_name} (<a href="mailto:${user.email}">${user.email}</a>)`,
            article_number_optional: article ? article.number_optional || article.number : null,
            article_number: article ? article.number : null
          }
        })
        this.inquiries = inquiries.sort((a, b) => b.id - a.id)
        this.tmpArticles = inquiries.sort((a, b) => b.id - a.id)
      } catch (e) {
        this.error = this.$t('There was an error fetching data')
      }
    },

    changePerPage(item) {
      this.itemPerPage = item
      this.page = 0
    },

    getDate(date) {
      if (!date) return
      const d = new Date(date);
      const ye = d.getFullYear()
      const mo = d.toLocaleString(this.appStoreLang || 'de', { month: 'long', timeZone: 'UTC' });
      const da = d.getDate()
      const h = d.getHours()
      const m = d.getMinutes()
      const s = d.getSeconds()
      const dayPrefix = da === 1 ? 'st' : da === 2 ? 'nd' : da === 3 ? 'rd' : 'th'
      return this.appStoreLang === 'en' ? `${mo} ${da}${dayPrefix}, ${ye} ${h}:${m <= 9 ? '0' + m : m}` : `${da}. ${mo}, ${ye} ${h}:${m <= 9 ? '0' + m : m}:${s <= 9 ? '0' + s : s}`
    },

    async syncData() {
      this.loading = true;
      await this.getUsers()
      await this.getInquiries()
      await this.selectAuction(this.currentAuctionId)
      this.loading = false
    },

    async getAuctions() {
      try {
        const data = await dispatcher.getAllAuctions();
        this.auctions = [
          {
            code: this.$t('All'),
            id: 'all'
          },
          ...data.sort((a, b) => b.id - a.id)
        ]
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error getting the auctions. Please try again later') });
        this.error = true;
      }
    },

    async getAuctionDetail(auctionID) {
      try {
        this.selectedAuctionData = this.auctions.find(auction => auction.id === auctionID)
        this.currentAuctionId = auctionID

        await this.getAdminArticles({ auctionID, auction: this.selectedAuctionData })
        let responseAuction = this.auctionsFetchedArticles[`auction_${auctionID}`]

        let auctionNumber = responseAuction.code
        let mediaServer = this.appLocalization.media_server_url

        responseAuction = responseAuction.map(itm => {
          let articleNumber = itm.number
          let imageUrl = `${mediaServer}/${auctionNumber}/${articleNumber}.jpg`
          return { ...itm, image: imageUrl }
        })

        this.articles = responseAuction
        this.key++
      } catch (e) {
        this.loading = false
        this.selectedAuctionData = null
        this.articles = []
        this.error = this.$t('There was an error fetching data')
      }
    },

    searchHandler: _.debounce(function () {
      let filteredByAuction
      if (this.currentAuctionId !== 'all') {
        filteredByAuction = this.inquiries.filter(el => el.auction_id === this.currentAuctionId)
      } else {
        filteredByAuction = this.inquiries
      }
      if (this.search) {
        this.tmpArticles = this.filterByInquiry(filteredByAuction).filter(el => (el.user_data && el.user_data.toLowerCase().includes(this.search.toLowerCase())) ||
          (el.message && el.message.toLowerCase().includes(this.search.toLowerCase())))
      } else {
        this.tmpArticles = [...this.filterByInquiry(filteredByAuction)]
      }
    }, timeouts.debounce),

    filterByInquiry(allArticles) {
      if (this.inquiryFilter !== 'all') {
        return allArticles.filter(el => el.type === this.inquiryFilter)
      }
      return allArticles
    },

    async getUsers() {
      try {
        const result = await dispatcher.getUsersLastUpdatedTimestamp()
        const cacheUpdateTime = this.$cookies.get('users') ? moment(this.$cookies.get('users').userCacheLastUpdated) : null
        const responseUpdateTime = result.updated_at ? moment(result.updated_at) : null
        await this.fetchUsers(cacheUpdateTime && responseUpdateTime && responseUpdateTime.diff(cacheUpdateTime, 'seconds') > 0)
      } catch (e) {
        this.error = this.$t('There was an error fetching data')
        this.loading = false;
      }
    },
    clickUser(user) {
      const routeData = this.$router.resolve({ name: 'AdminUserProfile', params: { id: user.id } });
      window.open(routeData.href, '_blank');
    },
    clickArticle(article) {
      const routeData = this.$router.resolve({ name: 'Articles', params: { id: this.currentAuctionId }, query: { articleNumber: article.article_number } });
      window.open(routeData.href, '_blank');
    },
    async selectAuction(auctionID) {
      this.inquiryFilter = 'all'
      this.search = ''
      let filteredByAuction = []
      if (auctionID !== 'all') {
        await this.getAuctionDetail(auctionID)
        filteredByAuction = this.inquiries.filter(el => el.auction_id === auctionID)
      } else {
        this.articles = []
        this.currentAuctionId = 'all'
        filteredByAuction = this.inquiries
      }
      const inquiries = filteredByAuction.map(inquiry => {
        const user = this.users.find(user => inquiry.users_id === user.id)
        const article = this.articles.find(article => inquiry.articles_id === article.id)
        return {
          ...inquiry,
          user_data: `${user.last_name} ${user.first_name} (<a href="mailto:${user.email}">${user.email}</a>)`,
          article_number_optional: article ? article.number_optional || article.number : null,
          article_number: article ? article.number : null
        }
      })
      this.tmpArticles = inquiries.sort((a, b) => b.id - a.id)
    }
  }
}
</script>
<style lang="scss" scoped>
.inquiry-container {
  .close-btn-wrapper {
    position: fixed;
    top: 110px;
    right: 40px;
    z-index: 1;

    .v-ripple__container {
      display: none !important;
    }

    @media(max-width: 968px) {
      top: 90px;
      right: 10px;
    }

    .close-text {
      @media(max-width: 968px) {
        display: none;
      }
    }

    .close-modal-icon {
      font-size: 24px;
      width: 24px;
      height: 24px;
      background: transparent;
      border-radius: 8px;
      border: 1px solid transparent;
      transition: all .3s ease;
    }

    button {
      text-transform: none;
      font-size: 12px;
      font-weight: normal;
      line-height: 20px;
      background-color: transparent !important;
      box-shadow: none;
      letter-spacing: unset !important;
      @media(max-width: 968px) {
        width: 40px;
        min-width: 40px !important;
        height: 40px;
        padding: 0 !important;
      }

      &:before, &:focus:before, &:hover:before {
        background-color: transparent !important;
      }

      i {
        @media(max-width: 968px) {
          font-size: 20px !important;
          margin-left: 0 !important;
        }
      }
    }
  }
}
.page-title {
  font-size: 24px;
  line-height: 25px;
  font-weight: 400;
}
</style>
