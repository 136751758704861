import moment from 'moment/moment';
import { validatePassword } from '@/utils/index';
import { i18n } from '@/main';

const frontendValidations = {
  required() {
    return value => !!value || 'validation.inputRequired'
  },
  email() {
    return value => {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(value) || 'validation.emailInvalid'
    }
  },
  password() {
    return value => {
      if (!!value && !validatePassword(value)) {
        return 'validation.passwordWeak'
      } else {
        return true
      }
    }
  },
  passwordConfirmation(newPassword: string, confirmationNewPassword: string) {
    return value => (newPassword === confirmationNewPassword) || 'validation.passwordDoesntMatch'
  },
  positiveNumber() {
    return value => value >= 0 || 'validation.mustBePositive';
  },
  // must be a whole number i.e. 50000 instead of 50.000. However when using a v-text-field, 50000,00 is passed on as 50000 so this will pass
  wholeNumber() {
    return value => {
      if (!value || value.length === 0) {
        return true;
      }
      if (!value.match(/^\d+$/)) {
        return 'validation.invalidNumberFormat';
      }
      return true
    }
  },
  lessThanMaximum(maxInputLength) {
    return value => (value && value.length <= maxInputLength) || 'validation.inputTooLong';
  },
  inFuture() {
    return value => {
      const date = moment(value, 'DD.MM.YYYY HH:mm')
      return moment().diff(date, 'seconds') < 0 || 'validation.mustBeInFuture'
    }
  },
  higherThanLimit(articleLimit: number, errorMessage: string) {
    return value => (value >= articleLimit) || errorMessage;
  },
  dividedBy(minimumBidStep: number, bidStep: string, currency: string) {
    return value => value % minimumBidStep === 0 || `${i18n.t('validation.dividedByError', { tBidStep: bidStep, tCurrency: currency })}`
  },
  itemRequired(item: any, value: any) {
    return () => !item.required || !!value[item.key] || 'validation.inputRequired'
  },
  olderThanEnd(entity: any) {
    return value => moment(entity.timedData.active_until).diff(moment(value, 'DD.MM.YYYY HH:mm'), 'minutes') >= 0 || 'validation.mustBeOlderThanEnd'
  },
  newerThanStart(entity: any) {
    return value => moment(entity.timedData.active_from).diff(moment(value, 'DD.MM.YYYY HH:mm'), 'minutes') <= 0 || 'validation.mustBeNewerThanStart'
  },
  requiredRoles(type: any) {
    return value => type === 'roles' ? value && value.length > 0 ? true : 'validation.roleRequired' : false
  },
  requiredUsers(type: any) {
    return value => type === 'users' ? value && value.length > 0 ? true : 'validation.userRequired' : false
  },
  estimatedPrice() {
    return value => {
      if (!value || value.length === 0) {
        return true;
      }
      if (!value.match(/\d+-\d+/)) {
        return 'validation.invalidEstimateFormat';
      }

      const [min, max] = value.split('-').map(i => parseFloat(i));

      if (max < min) {
        return 'validation.maxEstimateMustBeGreater';
      }

      return true;
    }
  }
}

export default frontendValidations;
