<template>
  <v-row justify="center">
    <v-dialog class="lighten-5" id="container" v-model="dialog" max-width="600" scrollable>
      <v-card class="px-8 py-4">
        <v-btn class="modal-close-btn icon-with-hover" icon @click="closeModal">
          <v-icon>fa-times</v-icon>
        </v-btn>
        <v-row>
          <v-col cols="6">
            <h3>{{ $t('Categories') }}</h3>
          </v-col>
        </v-row>
        <v-divider class="mb-2" />
        <v-card-text style="height: 300px;">
          <categories-filter
            :appStoreLang="appStoreLang"
            @closeModal="closeModal"
            :isMobile="isMobile"
            @setCategory="setCategory"
            :categories="sortedCategories"
            :activeCategory="activeCategory"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import CategoriesFilter from '@/components/blocks/categories-filter'
import { sortCategories } from '@/services/category';
export default {
  name: 'CategoryModal',
  components: {
    CategoriesFilter
  },
  props: {
    categories: {
      type: Array,
      default: () => []
    },
    activeCategory: {
      type: Number,
      default: null
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    appStoreLang: {
      type: String,
      default: 'de'
    },
    setCategory: { type: Function }
  },
  data() {
    return {
      dialog: false,
      sortedCategories: []
    }
  },
  watch: {
    categories() {
      this.sortCategories();
    },
    appStoreLang() {
      this.sortCategories();
    }
  },
  methods: {
    openModal() {
      this.dialog = true
      this.sortCategories()
    },
    closeModal() {
      this.dialog = false
    },
    sortCategories() {
      this.sortedCategories = sortCategories(this.categories, this.appStoreLang);
    }
  }
}
</script>

<style scoped lang="scss">
.btn {
  width: 140px;
}
</style>
