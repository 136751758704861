import { SocketConnectionStatus } from '@/utils/constants';
import socketRequests from '@/api/socketRequests';
import apiRequests from '@/api/apiRequests';
import useRootStore from '@/stores/rootStore';
import {IUserJoinedAuctions} from "@/api/types";
import httpClient from '@/utils/httpClient';
import { i18n } from '@/main';

const dispatcher = {
  /**
   * Search for articles
   * @return {Promise<any>} - All found articles
   */
  searchArticles: async (query: string, language: string): Promise<any> => {
    const rootStore = useRootStore();
    const isSocket = rootStore.appFeatures?.webSocketFunctionality && rootStore.socketConnectionStatus === SocketConnectionStatus.connected;
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("searchArticles", isSocket);
    try {
      let result;
      if (isSocket) {
        result = await socketRequests.searchArticles({ query, lang: language });
      } else {
        result = await apiRequests.searchArticles(query, language);
      }
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("searchArticles", timestamp);
    }
  },

  /**
   * Get all auctions
   * @return {Promise<any>} - All auctions
   */
  getAllAuctions: async (): Promise<any> => {
    const rootStore = useRootStore();
    const isSocket = rootStore.appFeatures?.webSocketFunctionality && rootStore.socketConnectionStatus === SocketConnectionStatus.connected;
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("getAllAuctions", isSocket);
    try {
      let result;
      if (isSocket) {
        result = await socketRequests.getAuctions();
      } else {
        result = await apiRequests.getAuctions();
      }
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("sendInquiry", timestamp);
    }
  },

  /**
   * Request auction signup
   * @param {number} auctionId - The auction id
   * @param {number} userId - The user id
   * @param {number} [bidLimit] - The bid limit
   * @param {boolean} [receiveStartReminderEmail] - Flag to control if the user wants a reminder email before the auction starts
   * @return {Promise<void>} - Nothing
   */
  requestAuctionSignup: async (auctionId: number, userId: number, bidLimit?: number, receiveStartReminderEmail?: boolean): Promise<void> => {
    const rootStore = useRootStore();
    const isSocket = rootStore.appFeatures?.webSocketFunctionality && rootStore.socketConnectionStatus === SocketConnectionStatus.connected;
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("requestAuctionSignup", isSocket);
    try {
      if (isSocket) {
        if (bidLimit !== undefined) {
          await socketRequests.requestSignup(auctionId, userId, bidLimit, receiveStartReminderEmail)
        } else {
          await socketRequests.requestSignup(auctionId, userId, null, receiveStartReminderEmail);
        }
      } else {
        if (bidLimit !== undefined) {
          await apiRequests.requestSignup(auctionId, userId, bidLimit, receiveStartReminderEmail);
        } else {
          await apiRequests.requestSignup(auctionId, userId, null, receiveStartReminderEmail);
        }
      }
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("requestAuctionSignup", timestamp);
    }
  },
  /**
   * Send an inquiry (general, article or post-auction-sale related)
   * @return {Promise<void>} - Nothing
   */
  sendEmailInquiry: async (userId: number, data: any): Promise<void> => {
    const rootStore = useRootStore();
    const isSocket = rootStore.appFeatures?.webSocketFunctionality && rootStore.socketConnectionStatus === SocketConnectionStatus.connected;
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("sendEmailInquiry", isSocket);
    try {
      if (isSocket) {
        await socketRequests.sendEmailInquiry(userId, data);
      } else {
        await apiRequests.sendEmailInquiry(userId, data);
      }
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("sendEmailInquiry", timestamp);
    }
  },
  /**
   * Validate commission bids
   * @param {number} auctionId - The auction id
   * @param {any} data - Commission bid data
   * @return {Promise<any>} - Promise containing the validation result
   */
  validateCommissionBids: async (auctionId: number, data: any): Promise<any> => {
    const rootStore = useRootStore();
    const isSocket = rootStore.appFeatures?.webSocketFunctionality && rootStore.socketConnectionStatus === SocketConnectionStatus.connected;
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("validateCommissionBids", isSocket);
    try {
      let result;
      if (isSocket) {
        result = await socketRequests.validateCommissionBids(data);
      } else {
        result = await apiRequests.validateCommissionBids(auctionId, data);
      }
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("validateCommissionBids", timestamp);
    }
  },
  /**
   * Get all wishlist entries for a user
   * @param {number} auctionId - The auction id
   * @param {number} userId - The user id
   * @return {Promise<any>} - Promise containing the wishlist entries
   */
  getWishlist: async (auctionId: number, userId: number): Promise<any> => {
    const rootStore = useRootStore();
    const isSocket = rootStore.appFeatures?.webSocketFunctionality && rootStore.socketConnectionStatus === SocketConnectionStatus.connected;
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("getWishlist", isSocket);
    try {
      let result;
      if (isSocket) {
        result = await socketRequests.getWishlist(auctionId, userId);
      } else {
        result = await apiRequests.getWishList(auctionId, userId);
      }
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("getWishlist", timestamp);
    }
  },
  /**
   * Add an article to the wishlist
   * @param {number} auctionId - The auction id
   * @param {number} userId - The user id
   * @param {number} articleId - The article id
   * @return {Promise<void>} - Promise containing nothing
   */
  addToWishlist: async (auctionId: number, userId: number, articleId: number): Promise<any> => {
    const rootStore = useRootStore();
    const isSocket = rootStore.appFeatures?.webSocketFunctionality && rootStore.socketConnectionStatus === SocketConnectionStatus.connected;
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("addToWishlist", isSocket);
    try {
      let result;
      if (isSocket) {
        result = await socketRequests.addToWishlist(auctionId, userId, articleId);
      } else {
        result = await apiRequests.addToWishList(auctionId, userId, articleId);
      }
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("addToWishlist", timestamp);
    }
  },
  /**
   * Remove an article from the wishlist
   * @param {number} auctionId - The auction id
   * @param {number} userId - The user id
   * @param {number} articleId - The article id
   * @return {Promise<void>} - Promise containing nothing
   */
  removeFromWishlist: async (auctionId: number, userId: number, articleId: number): Promise<any> => {
    const rootStore = useRootStore();
    const isSocket = rootStore.appFeatures?.webSocketFunctionality && rootStore.socketConnectionStatus === SocketConnectionStatus.connected;
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("removeFromWishlist", isSocket);
    try {
      let result;
      if (isSocket) {
        result = await socketRequests.removeFromWishlist(auctionId, userId, articleId);
      } else {
        result = await apiRequests.removeFromWishList(auctionId, userId, articleId);
      }
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("removeFromWishlist", timestamp);
    }
  },
  /**
   * Get all commission bids for a user
   * @param {number} auctionId - The auction id
   * @param {boolean} getUserData - Flag to control if user data should be retrieved
   * @return {Promise<any>} - Promise containing the commission bids
   */
  getAllCommissionBids: async (auctionId: number, getUserData: boolean): Promise<any> => {
    const rootStore = useRootStore();
    const isSocket = rootStore.appFeatures?.webSocketFunctionality && rootStore.socketConnectionStatus === SocketConnectionStatus.connected;
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("getAllCommissionBids", isSocket);
    try {
      let result;
      if (isSocket) {
        result = await socketRequests.getAllCommissionBids(auctionId, getUserData)
      } else {
        result = await apiRequests.getAllCommissionBids(auctionId, getUserData);
      }
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("getAllCommissionBids", timestamp);
    }
  },
  /**
   * Place one o more commission bids for a user
   * @param {number} auctionId - The auction id
   * @param {array} commissionBids - The array of commission bids
   * @return {Promise<any>} - Promise containing the placed commission bids
   */
  placeCommissionBids: async (auctionId: number, commissionBids: any): Promise<any> => {
    const rootStore = useRootStore();
    const isSocket = rootStore.appFeatures?.webSocketFunctionality && rootStore.socketConnectionStatus === SocketConnectionStatus.connected;
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("placeCommissionBids", isSocket);
    try {
      let result;
      if (isSocket) {
        result = await socketRequests.setCommissionBids(auctionId, commissionBids)
      } else {
        result = await apiRequests.setCommissionBids(auctionId, commissionBids);
      }
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("placeCommissionBids", timestamp);
    }
  },
  /**
   * Get all knockdowns for a user
   * @param {number} auctionId - The auction id
   * @param {number} userId - The user id
   * @return {Promise<any>} - Promise containing the knockdowns
   */
  getUserKnockdowns: async (auctionId: number, userId: number): Promise<any> => {
    const rootStore = useRootStore();
    const isSocket = rootStore.appFeatures?.webSocketFunctionality && rootStore.socketConnectionStatus === SocketConnectionStatus.connected;
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("getUserKnockdowns", isSocket);
    try {
      let result;
      if (isSocket) {
        result = await socketRequests.getUserKnockdowns(auctionId, userId)
      } else {
        result = await apiRequests.getUserKnockdowns(auctionId, userId);
      }
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("getUserKnockdowns", timestamp);
    }
  },
  /**
   * Get all knockdowns for the article slider for a user and the admin
   * @param {number} auctionId - The auction id
   * @return {Promise<any>} - Promise containing the knockdowns
   */
  getKnockdownsArticleSlider: async (auctionId: number): Promise<any> => {
    const rootStore = useRootStore();
    const isSocket = rootStore.appFeatures?.webSocketFunctionality && rootStore.socketConnectionStatus === SocketConnectionStatus.connected;
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("getKnockdownsArticleSlider", isSocket);
    try {
      let result;
      if (isSocket) {
        result = await socketRequests.getKnockdownsArticleSlider(auctionId)
      } else {
        result = await apiRequests.getKnockdownsArticleSlider(auctionId);
      }
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("getKnockdownsArticleSlider", timestamp);
    }
  },
  /**
   * Get biddernumbers for a user sorted by id ascending
   * @param {number} userId - The user id
   * @return {Promise<any>} - Promise containing the bidder numbers
   */
  getBidderNumbers: async (userId: number): Promise<any> => {
    const rootStore = useRootStore();
    const isSocket = rootStore.appFeatures?.webSocketFunctionality && rootStore.socketConnectionStatus === SocketConnectionStatus.connected;
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("getBidderNumbers", isSocket);
    try {
      let result;
      if (isSocket) {
        result = await socketRequests.getUserBidderNumbers(userId);
      } else {
        result = await apiRequests.getUserBidderNumbers(userId);
      }
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("getBidderNumbers", timestamp);
    }
  },
  /**
   * Get the current active live auction
   * @return {Promise<any>} - Promise containing the current active auction or an empty object if none is running
   */
  getCurrentActiveAuction: async (): Promise<any> => {
    const rootStore = useRootStore();
    const isSocket = rootStore.appFeatures?.webSocketFunctionality && rootStore.appFeatures.webSocketAuctionStateManagement && rootStore.socketConnectionStatus === SocketConnectionStatus.connected;
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("getCurrentActiveAuction", isSocket);
    try {
      let result;
      if (isSocket) {
        result = await socketRequests.getCurrentActiveAuction();
      } else {
        result = await apiRequests.getCurrentActiveAuction();
      }
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("getCurrentActiveAuction", timestamp);
    }
  },
  /**
   * Jump to an article in a live auction
   * @param {number} auctionId - The auction id
   * @param {number} articleId - The article id
   * @return {Promise<any>} - Promise containing the current active auction or an empty object if none is running
   */
  jumpToArticle: async (auctionId: number, articleId: number): Promise<any> => {
    const rootStore = useRootStore();
    const isSocket = rootStore.appFeatures?.webSocketFunctionality && rootStore.appFeatures.webSocketAuctionStateManagement && rootStore.socketConnectionStatus === SocketConnectionStatus.connected;
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("jumpToArticle", isSocket);
    try {
      let result;
      if (isSocket) {
        result = await socketRequests.jumpToArticle(auctionId, articleId);
      } else {
        result = await apiRequests.jumpToArticle(auctionId, articleId);
      }
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("jumpToArticle", timestamp);
    }
  },

  /**
   * Join a live auction
   * @param {number} auctionId - The auction id
   * @param {string} browserTabFingerprint - The browser tab fingerprint
   * @return {Promise<any>} - Promise containing the result
   */
  joinLiveAuction: async (auctionId: number, browserTabFingerprint: string): Promise<void> => {
    const rootStore = useRootStore();
    const isSocket = rootStore.appFeatures?.webSocketFunctionality && rootStore.socketConnectionStatus === SocketConnectionStatus.connected;
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("joinLiveAuction", isSocket);
    try {
      let result;
      if (isSocket) {
        result = await socketRequests.joinLiveAuction(auctionId, browserTabFingerprint);
      } else {
        result = await apiRequests.joinLiveAuction(auctionId, browserTabFingerprint);
      }
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("joinLiveAuction", timestamp);
    }
  },

  /**
   * Leave a live auction
   * @param {number} auctionId - The auction id
   * @param {string} browserTabFingerprint - The browser tab fingerprint
   * @return {Promise<any>} - Promise containing the result
   */
  leaveLiveAuction: async (auctionId: number, browserTabFingerprint: string): Promise<void> => {
    const rootStore = useRootStore();
    const isSocket = rootStore.appFeatures?.webSocketFunctionality && rootStore.socketConnectionStatus === SocketConnectionStatus.connected;
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("leaveLiveAuction", isSocket);
    try {
      let result;
      if (isSocket) {
        result = await socketRequests.leaveLiveAuction(auctionId, browserTabFingerprint);
      } else {
        result = await apiRequests.leaveLiveAuction(auctionId, browserTabFingerprint);
      }
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("leaveLiveAuction", timestamp);
    }
  },

  /**
   * Leave a live auction
   * @return {Promise<any>} - Promise containing the result
   */
  getAuctionJoinStatus: async (): Promise<IUserJoinedAuctions> => {
    const rootStore = useRootStore();
    const isSocket = rootStore.appFeatures?.webSocketFunctionality && rootStore.appFeatures.webSocketAuctionStateManagement && rootStore.socketConnectionStatus === SocketConnectionStatus.connected;
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("getAuctionJoinStatus", isSocket);
    try {
      let result;
      if (isSocket) {
        result = await socketRequests.getAuctionJoinStatus();
      } else {
        result = await apiRequests.getAuctionJoinStatus();
      }
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("getAuctionJoinStatus", timestamp);
    }
  },

  /**
   * Place a bid
   * @param {any} data - The payload
   * @return {Promise<any>} - Promise containing the result
   */
  doLiveBid: async (data): Promise<any> => {
    const rootStore = useRootStore();
    const isSocket = rootStore.appFeatures?.webSocketFunctionality && rootStore.appFeatures.webSocketAuctionStateManagement && rootStore.appFeatures.webSocketBidding && rootStore.socketConnectionStatus === SocketConnectionStatus.connected;
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("doBid", isSocket);
    try {
      let result;
      if (isSocket) {
        result = await socketRequests.doLiveBid(data);
      } else {
        result = await apiRequests.doLiveBid(data);
      }
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("doBid", timestamp);
    }
  },

  /**
   * Perform an auction action (i.e. stop)
   * @param {any} data - The auction payload
   * @return {Promise<any>} - Promise containing the result
   */
  action: async (data: any): Promise<any> => {
    const rootStore = useRootStore();
    const isSocket = rootStore.appFeatures?.webSocketFunctionality && rootStore.appFeatures.webSocketAuctionStateManagement && rootStore.socketConnectionStatus === SocketConnectionStatus.connected;
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("doAction", isSocket);
    try {
      let result;
      if (isSocket) {
        result = await socketRequests.action(data);
      } else {
        result = await apiRequests.action(data);
      }
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("doAction", timestamp);
    }
  },

  /**
   * Get all knockdowns for the article
   * @param {number} auctionId - The auction id
   * @param {boolean} csv - Flag to control if csv data should be fetched
   * @return {Promise<any>} - Promise containing the knockdowns
   */
  getAdminKnockdownsPerAuction: async (auctionId: number, csv: boolean): Promise<any> => {
    const rootStore = useRootStore();
    const isSocket = rootStore.appFeatures?.webSocketFunctionality && rootStore.socketConnectionStatus === SocketConnectionStatus.connected;
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("getKnockdowns", isSocket);
    try {
      let result;
      if (isSocket) {
        result = await socketRequests.getAdminKnockdownsPerAuction(auctionId, csv)
      } else {
        result = await apiRequests.getAdminKnockdownsPerAuction(auctionId, csv);
      }
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("getKnockdowns", timestamp);
    }
  },

  /**
   * Get the user profile
   * @return {Promise<any>} - Promise containing the user profile
   */
  getUserProfile: async (): Promise<any> => {
    const rootStore = useRootStore();
    const isSocket = rootStore.appFeatures?.webSocketFunctionality && rootStore.socketConnectionStatus === SocketConnectionStatus.connected;
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("getUserProfile", isSocket);
    try {
      let result;
      if (isSocket) {
        result = await socketRequests.getUserProfile();
      } else {
        result = await apiRequests.getUserProfile();
        // Change system language
        httpClient.setHeaderLang(result.language)
        i18n.locale = result.language
      }
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("getUserProfile", timestamp);
    }
  },

  /**
   * Updates the user profile
   * @param {any} data - The payload
   * @return {Promise<any>} - Promise containing the updated user profile
   */
  updateUserProfile: async (data: any): Promise<any> => {
    const rootStore = useRootStore();
    const isSocket = rootStore.appFeatures?.webSocketFunctionality && rootStore.socketConnectionStatus === SocketConnectionStatus.connected;
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("updateUserProfile", isSocket);
    try {
      let result;
      if (isSocket) {
        result = await socketRequests.updateUserProfile(data);
      } else {
        result = await apiRequests.updateUserProfile(data);
      }
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("updateUserProfile", timestamp);
    }
  },

  /**
   * Send a message over the websocket
   * @param {any} data - The payload
   * @return {Promise<void>} - Promise containing nothing
   */
  sendMessageOnSocket: async (data: any): Promise<void> => {
    const rootStore = useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("sendMessageOnSocket", true);
    try {
      await socketRequests.sendMessageOnSocket(data);
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("sendMessageOnSocket", timestamp);
    }
  },

  /**
   * Undo the last bid
   * @param {number} auctionId - The auction id
   * @return {Promise<void>} - Promise containing nothing
   */
  undoBid: async (auctionId: number): Promise<any> => {
    const rootStore = useRootStore();
    const isSocket = rootStore.appFeatures?.webSocketFunctionality && rootStore.appFeatures.webSocketAuctionStateManagement && rootStore.socketConnectionStatus === SocketConnectionStatus.connected;
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("undoBid", isSocket);
    try {
      let result;
      if (isSocket) {
        result = await socketRequests.undoBid(auctionId);
      } else {
        result = await apiRequests.action({ type: 'undo', auctionId});
      }
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("undoBid", timestamp);
    }
  },

  /**
   * Send a request for a custom ask price
   * @param {number} auctionId - The auction id
   * @param {number} value - The ask price
   * @return {Promise<void>} - Promise containing nothing
   */
  askBid: async (auctionId: number, value: number): Promise<any> => {
    const rootStore = useRootStore();
    const isSocket = rootStore.appFeatures?.webSocketFunctionality && rootStore.appFeatures.webSocketAuctionStateManagement && rootStore.socketConnectionStatus === SocketConnectionStatus.connected;
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("askBid", isSocket);
    try {
      let result;
      if (isSocket) {
        result = await socketRequests.askBid(auctionId, value);
      } else {
        result = await apiRequests.action({type: 'ask', value, auctionId});
      }
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("askBid", timestamp);
    }
  },

  /**
   * Send a bid warning
   * @param {number} auctionId - The auction id
   * @return {Promise<void>} - Promise containing nothing
   */
  bidWarning: async (auctionId: number): Promise<any> => {
    const rootStore = useRootStore();
    const isSocket = rootStore.appFeatures?.webSocketFunctionality && rootStore.appFeatures.webSocketAuctionStateManagement && rootStore.socketConnectionStatus === SocketConnectionStatus.connected;
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("bidWarning", isSocket);
    try {
      let result;
      if (isSocket) {
        result = await socketRequests.bidWarning(auctionId);
      } else {
        result = await apiRequests.action({ type: 'bidWarning', auctionId})
      }
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("bidWarning", timestamp);
    }
  },

  /**
   * Update the external bidder number after knockdown
   * @param {number} auctionId - The auction id
   * @param {number} bidderNumber - The bidder number
   * @return {Promise<void>} - Promise containing the bidder number
   */
  updateExternalBidderNumber: async (auctionId: number, bidderNumber: number): Promise<any> => {
    const rootStore = useRootStore();
    const isSocket = rootStore.appFeatures?.webSocketFunctionality && rootStore.appFeatures.webSocketAuctionStateManagement && rootStore.socketConnectionStatus === SocketConnectionStatus.connected;
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("updateExternalBidderNumber", isSocket);
    try {
      let result;
      if (isSocket) {
        result = await socketRequests.updateExternalBidderNumber(auctionId, bidderNumber);
      } else {
        result = await apiRequests.action({ type: 'updateExternalBidderNumber', bidderNumber, auctionId});
      }
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("updateExternalBidderNumber", timestamp);
    }
  },

  /**
   * Knockdown an article
   * @param {number} auctionId - The auction id
   * @param {number} bidderNumber - The bidder number
   * @param {boolean} conditional - Flag to control if a conditional knockdown should be done
   * @return {Promise<void>} - Promise containing the knockdown
   */
  sellItem: async (auctionId: number, bidderNumber: number, conditional: boolean): Promise<any> => {
    const rootStore = useRootStore();
    const isSocket = rootStore.appFeatures?.webSocketFunctionality && rootStore.appFeatures.webSocketAuctionStateManagement && rootStore.socketConnectionStatus === SocketConnectionStatus.connected;
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("sellItem", isSocket);
    try {
      let result;
      if (isSocket) {
        result = await socketRequests.sellItem(auctionId, bidderNumber, conditional);
      } else {
        result = await apiRequests.action({ type: 'sellItem', conditional, bidderNumber, auctionId});
      }
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("sellItem", timestamp);
    }
  },

  /**
   * Get all articles from an auction
   * @param {number} auctionId - The auction id
   * @return {Promise<any>} - Promise containing the articles
   */
  getAuctionArticles: async (auctionId: number): Promise<any> => {
    const rootStore = useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("getAuctionArticles", false);
    try {
      const result = await apiRequests.getAuctionArticles(auctionId);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("getAuctionArticles", timestamp);
    }
  },

  /**
   * Get all articles from an auction as a guest
   * @param {number} auctionId - The auction id
   * @return {Promise<any>} - Promise containing the articles
   */
  getAuctionArticlesGuest: async (auctionId: number): Promise<any> => {
    const rootStore = useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("getAuctionArticles", false);
    try {
      const result = await apiRequests.getAuctionArticlesGuest(auctionId);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("getAuctionArticles", timestamp);
    }
  },

  /**
   * Remove all commission bids from an auction
   * @param {number} auctionId - The auction id
   * @return {Promise<any>} - Promise containing the commission bids
   */
  removeCommissionBids: async (auctionId: number): Promise<any> => {
    const rootStore = useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("removeCommissionBids", false);
    try {
      const result = await apiRequests.removeCommissionBids(auctionId);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("removeCommissionBids", timestamp);
    }
  },

  /**
   * Update commission bids for an auction
   * @param {number} auctionId - The auction id
   * @param {any} data - The payload
   * @return {Promise<any>} - Promise containing the updated commission bids
   */
  updateCommissionBids: async (auctionId: number, data: any): Promise<any> => {
    const rootStore = useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("updateCommissionBids", false);
    try {
      const result = await apiRequests.updateCommissionBids(auctionId, data);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("updateCommissionBids", timestamp);
    }
  },

  /**
   * Signup for a user account
   * @param {any} data - The payload
   * @return {Promise<any>} - Promise containing the signup result
   */
  signup: async (data: any): Promise<any> => {
    const rootStore = useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("signup", false);
    try {
      const result = await apiRequests.signup(data);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("signup", timestamp);
    }
  },

  /**
   * Confirm the user registration (click on the doi email link)
   * @param {any} data - The payload
   * @return {Promise<any>} - Promise containing the result
   */
  confirmUserRegistration: async (data: any): Promise<any> => {
    const rootStore = useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("confirmUserRegistration", false);
    try {
      const result = await apiRequests.confirmUserRegistration(data);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("confirmUserRegistration", timestamp);
    }
  },

  /**
   * Submit a password reset request
   * @param {any} data - The payload
   * @return {Promise<any>} - Promise containing the result
   */
  resetPassword: async (data: any): Promise<any> => {
    const rootStore = useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("resetPassword", false);
    try {
      const result = await apiRequests.resetPassword(data);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("resetPassword", timestamp);
    }
  },

  /**
   * Submit a request to resend the activation email
   * @param {any} data - The payload
   * @return {Promise<any>} - Promise containing the result
   */
  resendActivationEmail: async (data: any): Promise<any> => {
    const rootStore = useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("resendActivationEmail", false);
    try {
      const result = await apiRequests.resendActivationEmail(data);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("resendActivationEmail", timestamp);
    }
  },

  /**
   * Confirm a password reset request after clicking on the email link (set a new password)
   * @param {any} data - The payload
   * @return {Promise<any>} - Promise containing the result
   */
  confirmResetPassword: async (data: any): Promise<any> => {
    const rootStore = useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("confirmResetPassword", false);
    try {
      const result = await apiRequests.confirmResetPassword(data);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("confirmResetPassword", timestamp);
    }
  },

  /**
   * Logout a user
   * @return {Promise<any>} - Promise containing the result
   */
  logout: async (): Promise<any> => {
    const rootStore = useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("logout", false);
    try {
      const result = await apiRequests.logout();
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("logout", timestamp);
    }
  },

  /**
   * Get all notifications
   * @param {number} userId - The user id
   * @return {Promise<any>} - Promise containing the notifications
   */
  getNotification: async (userId: number): Promise<any> => {
    const rootStore = useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("getNotification", false);
    try {
      const result = await apiRequests.getNotification(userId);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("getNotification", timestamp);
    }
  },

  /**
   * Mark notifications as read
   * @param {number} userId - The user id
   * @param {number} notificationId - The notification id
   * @return {Promise<any>} - Promise containing the result
   */
  markReadNotification: async (userId: number, notificationId: number): Promise<any> => {
    const rootStore = useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("markReadNotification", false);
    try {
      const result = await apiRequests.markReadNotification(userId, notificationId);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("markReadNotification", timestamp);
    }
  },

  /**
   * Get the user settings
   * @return {Promise<any>} - Promise containing the user settings
   */
  getUserSettings: async (): Promise<any> => {
    const rootStore = useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("getUserSettings", false);
    try {
      const result = await apiRequests.getUserSettings();
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("getUserSettings", timestamp);
    }
  },

  /**
   * Get the currencies
   * @return {Promise<any>} - Promise containing the currencies
   */
  getSystemCurrencies: async (): Promise<any> => {
    const rootStore = useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("getSystemCurrencies", false);
    try {
      const result = await apiRequests.getSystemCurrencies();
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("getSystemCurrencies", timestamp);
    }
  },

  /**
   * Get the application settings
   * @return {Promise<any>} - Promise containing the settings
   */
  getAppSettings: async (): Promise<any> => {
    const rootStore = useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("getAppSettings", false);
    try {
      const result = await apiRequests.getAppSettings();
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("getAppSettings", timestamp);
    }
  },

  /**
   * Check if auction articles have been updated
   * @param {number} auctionId - The auction id
   * @return {Promise<any>} - Promise containing the result
   */
  checkIfAuctionArticlesUpdated: async (auctionId: number): Promise<any> => {
    const rootStore = useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("checkIfAuctionArticlesUpdated", false);
    try {
      const result = await apiRequests.checkIfAuctionArticlesUpdated(auctionId);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("checkIfAuctionArticlesUpdated", timestamp);
    }
  },

  /**
   * Get statistics for an auction
   * @param {number} auctionId - The auction id
   * @return {Promise<any>} - Promise containing the statistics
   */
  getStatistics: async (auctionId: number): Promise<any> => {
    const rootStore = useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("getStatistics", false);
    try {
      const result = await apiRequests.getStatistics(auctionId);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("getStatistics", timestamp);
    }
  },

  /**
   * Get inquiries
   * @return {Promise<any>} - Promise containing the inquiries
   */
  getInquiries: async (): Promise<any> => {
    const rootStore = useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("getInquiries", false);
    try {
      const result = await apiRequests.getInquiries();
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("getInquiries", timestamp);
    }
  },

  /**
   * Get dynamic article data for an auction
   * @param {number} auctionId - The auction id
   * @return {Promise<any>} - Promise containing the result
   */
  getArticlesDynamic: async (auctionId: number): Promise<any> => {
    const rootStore = useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("getArticlesDynamic", false);
    try {
      const result = await apiRequests.getArticlesDynamic(auctionId);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("getArticlesDynamic", timestamp);
    }
  },

  /**
   * Get the timed status of an article
   * @param {number} auctionId - The auction id
   * @param {number} articleId - The article id
   * @return {Promise<any>} - Promise containing the result
   */
  getArticleTimedStatus: async (auctionId: number, articleId: number | string): Promise<any> => {
    const rootStore = useRootStore();
    const isSocket = rootStore.appFeatures?.webSocketFunctionality && rootStore.socketConnectionStatus === SocketConnectionStatus.connected;
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("getArticleTimedStatus", isSocket);
    try {
      let result;
      if (isSocket) {
        result = await socketRequests.getArticleTimedStatus(auctionId, articleId);
      } else {
        result = await apiRequests.getArticleTimedStatus(auctionId, articleId);
      }
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("getArticleTimedStatus", timestamp);
    }
  },

  /**
   * Place a timed bid
   * @param {number} auctionId - The auction id
   * @param {number} articleId - The article id
   * @param {number} bid - The bid
   * @return {Promise<any>} - Promise containing the result
   */
  placeTimedBid: async (auctionId: number, articleId: number, bid: number): Promise<any> => {
    const rootStore = useRootStore();
    const isSocket = rootStore.appFeatures?.webSocketFunctionality && rootStore.socketConnectionStatus === SocketConnectionStatus.connected;
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("placeTimedBid", isSocket);
    try {
      let result;
      if (isSocket) {
        result = await socketRequests.placeTimedBid(auctionId, articleId, bid);
      } else {
        result = await apiRequests.placeTimedBid(auctionId, articleId, bid);
      }
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("placeTimedBid", timestamp);
    }
  },

  /**
   * Get all categories
   * @param {boolean} getLastUpdatedTimestamp - Flag to control if the last timestamp should be fetched instead of the categories
   * @return {Promise<any>} - Promise containing the categories
   */
  getCategories: async (getLastUpdatedTimestamp: boolean): Promise<any> => {
    const rootStore = useRootStore();
    const isSocket = rootStore.appFeatures?.webSocketFunctionality && rootStore.socketConnectionStatus === SocketConnectionStatus.connected;
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("getCategories", isSocket);
    try {
      let result;
      if (isSocket) {
        result = await socketRequests.getCategories(getLastUpdatedTimestamp)
      } else {
        result = await apiRequests.getCategories(getLastUpdatedTimestamp);
      }
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("getCategories", timestamp);
    }
  },

  /**
   * Get all categories as a guest
   * @param {boolean} getLastUpdatedTimestamp - Flag to control if the last timestamp should be fetched instead of the categories
   * @return {Promise<any>} - Promise containing the categories
   */
  getCategoriesGuest: async (getLastUpdatedTimestamp: boolean): Promise<any> => {
    const rootStore = useRootStore();
    const isSocket = rootStore.appFeatures?.webSocketFunctionality && rootStore.socketConnectionStatus === SocketConnectionStatus.connected;
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("getCategoriesGuest", isSocket);
    try {
      let result;
      if (isSocket) {
        result = await socketRequests.getCategoriesGuest(getLastUpdatedTimestamp)
      } else {
        result = await apiRequests.getCategoriesGuest(getLastUpdatedTimestamp);
      }
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("getCategoriesGuest", timestamp);
    }
  },

  /**
   * Get dynamic article data for an auction
   * @param {number} auctionId - The auction id
   * @return {Promise<any>} - Promise containing the result
   */
  getBidTransactions: async (auctionId: number): Promise<any> => {
    const rootStore = useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("getBidTransactions", false);
    try {
      const result = await apiRequests.getBidTransactions(auctionId);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("getBidTransactions", timestamp);
    }
  },

  /**
   * Get parts of the config
   * @param {string} fields - The fields to get
   * @return {Promise<any>} - Promise containing the result
   */
  getConfigFields: async (fields: string): Promise<any> => {
    const rootStore = useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("getConfigFields", false);
    try {
      const result = await apiRequests.getConfigFields(fields);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("getConfigFields", timestamp);
    }
  },

  /**
   * Get an email template
   * @param {string} name - The template name
   * @param {string} lang - The 2-digit language code
   * @return {Promise<any>} - Promise containing the result
   */
  getEmailTemplate: async (name: string, lang: string): Promise<any> => {
    const rootStore = useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("getEmailTemplate", false);
    try {
      const result = await apiRequests.getEmailTemplate(name, lang);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("getEmailTemplate", timestamp);
    }
  },

  /**
   * Update the config
   * @param {any} data - The payload
   * @return {Promise<any>} - Promise containing the result
   */
  updateConfigs: async (data: any): Promise<any> => {
    const rootStore = useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("updateConfigs", false);
    try {
      const result = await apiRequests.updateConfigs(data);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("updateConfigs", timestamp);
    }
  },

  /**
   * Update an email template
   * @param {any} data - The payload
   * @return {Promise<any>} - Promise containing the result
   */
  updateEmailTemplate: async (data: any): Promise<any> => {
    const rootStore = useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("updateEmailTemplate", false);
    try {
      const result = await apiRequests.updateEmailTemplate(data);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("updateEmailTemplate", timestamp);
    }
  },

  /**
   * Place and instant buy
   * @param {number} auctionId - The auction id
   * @param {number} articleId - The article id
   * @return {Promise<any>} - Promise containing the response
   */
  instantBuy: async (auctionId: number, articleId: number): Promise<any> => {
    const rootStore = useRootStore();
    const isSocket = rootStore.appFeatures?.webSocketFunctionality && rootStore.socketConnectionStatus === SocketConnectionStatus.connected;
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("instantBuy", isSocket);
    try {
      let result;
      if (isSocket) {
        result = await socketRequests.instantBuy(auctionId, articleId)
      } else {
        result = await apiRequests.instantBuy(auctionId, articleId)
      }
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("instantBuy", timestamp);
    }
  },

  /**
   * Upload an image
   * @param {any} data - The payload
   * @param {any} headers - The headers
   * @return {Promise<any>} - Promise containing the result
   */
  uploadImage: async (data: any, headers: any): Promise<any> => {
    const rootStore = useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("uploadImage", false);
    try {
      const result = await apiRequests.uploadImage(data, headers);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("uploadImage", timestamp);
    }
  },

  /**
   * Get the stream statistics
   * @return {Promise<any>} - Promise containing the result
   */
  getStreamStats: async (): Promise<any> => {
    const rootStore = useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("getStreamStats", false);
    try {
      const result = await apiRequests.getStreamStats();
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("getStreamStats", timestamp);
    }
  },

  /**
   * Get the component versions
   * @return {Promise<any>} - Promise containing the result
   */
  getVersions: async (): Promise<any> => {
    const rootStore = useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("getVersions", false);
    try {
      const result = await apiRequests.getVersions();
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("getVersions", timestamp);
    }
  },

  /**
   * Get the status of the backend components
   * @return {Promise<any>} - Promise containing the result
   */
  getStatus: async (): Promise<any> => {
    const rootStore = useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("getStatus", false);
    try {
      const result = await apiRequests.getStatus();
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("getStatus", timestamp);
    }
  },

  /**
   * Get a static page (imprint, browser information etc.)
   * @param {string} pageName - The page name
   * @param {string} lang - The two digit language code
   * @return {Promise<any>} - Promise containing the result
   */
  getStaticPage: async (pageName: string, lang: string): Promise<any> => {
    const rootStore = useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("getStaticPage", false);
    try {
      const result = await apiRequests.getStaticPage(pageName, lang);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("getStaticPage", timestamp);
    }
  },

  /**
   * Sena a test email
   * @param {any} data - The payload
   * @return {Promise<any>} - Promise containing the result
   */
  sendTestEmail: async (data: any): Promise<any> => {
    const rootStore = useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("sendTestEmail", false);
    try {
      const result = await apiRequests.sendTestEmail(data);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("sendTestEmail", timestamp);
    }
  },

  /**
   * Get the logs from the backend
   * @param {number} itemPerPage - The items per page
   * @param {number} skip - The items per page
   * @return {Promise<any>} - Promise containing the result
   */
  getLogs: async (itemPerPage: number, skip: number): Promise<any> => { //TODO not supported by the backend
    const rootStore = useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("getLogs", false);
    try {
      const result = await apiRequests.getLogs(itemPerPage, skip);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("getLogs", timestamp);
    }
  },

  /**
   * Get the auction data
   * @param {number} auctionId - The auction id
   * @return {Promise<any>} - Promise containing the result
   */
  getAuctionData: async (auctionId: number): Promise<any> => {
    const rootStore = useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("getAuctionData", false);
    try {
      const result = await apiRequests.getAuctionData(auctionId);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("getAuctionData", timestamp);
    }
  },

  /**
   * Update the image cache for one auction
   * @param {number} auctionId - The auction id
   * @return {Promise<any>} - Promise containing the result
   */
  updateImageCache: async (auctionId: number): Promise<any> => {
    const rootStore = useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("updateImageCache", false);
    try {
      const result = await apiRequests.updateImageCache(auctionId);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("updateImageCache", timestamp);
    }
  },

  /**
   * Validate the images for one auction
   * @param {number} auctionId - The auction id
   * @return {Promise<any>} - Promise containing the result
   */
  validateImages: async (auctionId: number): Promise<any> => {
    const rootStore = useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("validateImages", false);
    try {
      const result = await apiRequests.validateImages(auctionId);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("validateImages", timestamp);
    }
  },

  /**
   * Test the media server
   * @param {any} data - The payload
   * @return {Promise<any>} - Promise containing the result
   */
  testMediaServer: async (data: any): Promise<any> => {
    const rootStore = useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("testMediaServer", false);
    try {
      const result = await apiRequests.testMediaServer(data);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("testMediaServer", timestamp);
    }
  },

  /**
   * Get all auctions as a guest
   * @return {Promise<any>} - Promise containing the result
   */
  getGuestAuctions: async (): Promise<any> => {
    const rootStore = useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("getGuestAuctions", false);
    try {
      const result = await apiRequests.getGuestAuctions();
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("getGuestAuctions", timestamp);
    }
  },

  /**
   * Get biddernumbers for one auction with a state
   * @param {number} auctionId - The auction id
   * @param {string} state - The state
   * @return {Promise<any>} - Promise containing the result
   */
  getAuctionBidderNumbersWithState: async (auctionId: number, state: string): Promise<any> => {
    const rootStore = useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("getAuctionBidderNumbersWithState", false);
    try {
      const result = await apiRequests.getAuctionBidderNumbersWithState(auctionId, state);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("getAuctionBidderNumbersWithState", timestamp);
    }
  },

  /**
   * Update bidder numbers
   * @param {any} data - The payload
   * @return {Promise<any>} - Promise containing the result
   */
  updateUserBidderNumbers: async (data: any): Promise<any> => {
    const rootStore= useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("updateUserBidderNumbers", false);
    try {
      const result = await apiRequests.updateUserBidderNumbers(data);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("updateUserBidderNumbers", timestamp);
    }
  },

  /**
   * Update a category
   * @param {any} data - The payload
   * @return {Promise<any>} - Promise containing the result
   */
  updateCategory: async (data: any): Promise<any> => {
    const rootStore= useRootStore();
    const isSocket = rootStore.appFeatures?.webSocketFunctionality && rootStore.socketConnectionStatus === SocketConnectionStatus.connected;
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("updateCategory", isSocket);
    try {
      let result;
      if (isSocket) {
        result = await socketRequests.updateCategory(data)
      } else {
        result = await apiRequests.updateCategory(data)
      }
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("updateCategory", timestamp);
    }
  },

  /**
   * Create a category
   * @param {any} data - The payload
   * @return {Promise<any>} - Promise containing the result
   */
  createCategory: async (data: any): Promise<any> => {
    const rootStore= useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("createCategory", false);
    try {
      const result = await apiRequests.createCategory(data);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("createCategory", timestamp);
    }
  },

  /**
   * Delete a category
   * @param {any} data - The payload
   * @return {Promise<any>} - Promise containing the result
   */
  deleteCategory: async (categoryId: number): Promise<any> => {
    const rootStore= useRootStore();
    const isSocket = rootStore.appFeatures?.webSocketFunctionality && rootStore.socketConnectionStatus === SocketConnectionStatus.connected;
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("deleteCategory", isSocket);
    try {
      let result;
      if (isSocket) {
        result = await socketRequests.deleteCategory(categoryId)
      } else {
        result = await apiRequests.deleteCategory(categoryId)
      }
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("deleteCategory", timestamp);
    }
  },

  /**
   * Remove a bid step
   * @param {number} auctionId - The auction id
   * @param {number} bidStepId - The bid step id
   * @return {Promise<any>} - Promise containing the result
   */
  removeBidStep: async (auctionId: number, bidStepId: number): Promise<any> => {
    const rootStore= useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("removeBidStep", false);
    try {
      const result = await apiRequests.removeBidStep(auctionId, bidStepId);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("removeBidStep", timestamp);
    }
  },

  /**
   * Update a bid step
   * @param {number} auctionId - The auction id
   * @param {any} bidSteps - The bid steps
   * @return {Promise<any>} - Promise containing the result
   */
  updateBidStep: async (auctionId: number, bidSteps: any): Promise<any> => {
    const rootStore= useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("updateBidStep", false);
    try {
      const result = await apiRequests.updateBidStep(auctionId, bidSteps);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("updateBidStep", timestamp);
    }
  },

  /**
   * Update an auction
   * @param {number} auctionId - The auction id
   * @param {any} data - The payload
   * @return {Promise<any>} - Promise containing the result
   */
  updateAuction: async (auctionId: number, data: any): Promise<any> => {
    const rootStore= useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("updateAuction", false);
    try {
      const result = await apiRequests.updateAuction(auctionId, data);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("updateAuction", timestamp);
    }
  },

  /**
   * Create an auction
   * @param {any} data - The payload
   * @return {Promise<any>} - Promise containing the result
   */
  createAuction: async (data: any): Promise<any> => {
    const rootStore= useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("createAuction", false);
    try {
      const result = await apiRequests.createAuction(data);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("createAuction", timestamp);
    }
  },

  /**
   * Update an article
   * @param {number} auctionId - The auction id
   * @param {number} articleId - The article id
   * @param {any} data - The payload
   * @return {Promise<any>} - Promise containing the result
   */
  updateArticle: async (auctionId: number, articleId: number, data: any): Promise<any> => {
    const rootStore= useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("updateArticle", false);
    try {
      const result = await apiRequests.updateArticle(auctionId, articleId, data);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("updateArticle", timestamp);
    }
  },

  /**
   * Create an article
   * @param {number} auctionId - The auction id
   * @param {any} data - The payload
   * @return {Promise<any>} - Promise containing the result
   */
  createArticle: async (auctionId: number, data: any): Promise<any> => {
    const rootStore= useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("createArticle", false);
    try {
      const result = await apiRequests.createArticle(auctionId, data);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("createArticle", timestamp);
    }
  },

  /**
   * Delete an article
   * @param {number} auctionId - The auction id
   * @param {number} articleId - The article id
   * @return {Promise<any>} - Promise containing the result
   */
  removeArticle: async (auctionId: number, articleId: number): Promise<any> => {
    const rootStore= useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("removeArticle", false);
    try {
      const result = await apiRequests.removeArticle(auctionId, articleId);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("removeArticle", timestamp);
    }
  },

  /**
   * Import articles
   * @param {number} auctionId - The auction id
   * @param {any} data - The payload
   * @return {Promise<any>} - Promise containing the result
   */
  importArticles: async (auctionId: number, data: any): Promise<any> => {
    const rootStore= useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("importArticles", false);
    try {
      const result = await apiRequests.importArticles(auctionId, data);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("importArticles", timestamp);
    }
  },

  /**
   * Get all images for an article
   * @param {number} auctionId - The auction id
   * @param {number} articleId - The article id
   * @return {Promise<any>} - Promise containing the result
   */
  getArticleImages: async (auctionId: number, articleId: number): Promise<any> => {
    const rootStore= useRootStore();
    const isSocket = rootStore.appFeatures?.webSocketFunctionality && rootStore.socketConnectionStatus === SocketConnectionStatus.connected;
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("getArticleImages", isSocket);
    try {
      let result;
      if (isSocket) {
        result = await socketRequests.getArticleImages(auctionId, articleId)
      } else {
        result = await apiRequests.getArticleImages(auctionId, articleId)
      }
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("getArticleImages", timestamp);
    }
  },

  /**
   * Check if users are updated
   * @return {Promise<any>} - Promise containing the result
   */
  getUsersLastUpdatedTimestamp: async (): Promise<any> => {
    const rootStore= useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("checkIfUsersUpdated", false);
    try {
      const result = await apiRequests.getUsersLastUpdatedTimestamp();
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("checkIfUsersUpdated", timestamp);
    }
  },

  /**
   * Get all users
   * @return {Promise<any>} - Promise containing the result
   */
  getUsers: async (): Promise<any> => {
    const rootStore= useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("getUsers", false);
    try {
      const result = await apiRequests.getUsers();
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("getUsers", timestamp);
    }
  },

  /**
   * Get all users
   * @return {Promise<any>} - Promise containing the result
   */
  removeUser: async (userId: number): Promise<any> => {
    const rootStore= useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("removeUser", false);
    try {
      const result = await apiRequests.removeUser(userId);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("removeUser", timestamp);
    }
  },

  /**
   * Get all users
   * @return {Promise<any>} - Promise containing the result
   */
  updateUser: async (userId: number, data: any): Promise<any> => {
    const rootStore= useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("updateUser", false);
    try {
      const result = await apiRequests.updateUser(userId, data);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("updateUser", timestamp);
    }
  },

  /**
   * Get all users
   * @return {Promise<any>} - Promise containing the result
   */
  createUser: async (data: any): Promise<any> => {
    const rootStore= useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("createUser", false);
    try {
      const result = await apiRequests.createUser(data);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("createUser", timestamp);
    }
  },

  /**
   * Get all users
   * @return {Promise<any>} - Promise containing the result
   */
  importUsers: async (data: any): Promise<any> => {
    const rootStore= useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("importUsers", false);
    try {
      const result = await apiRequests.importUsers(data);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("importUsers", timestamp);
    }
  },

  /**
   * Get all users
   * @return {Promise<any>} - Promise containing the result
   */
  stopArticle: async (data: any): Promise<any> => {
    const rootStore= useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("stopArticle", false);
    try {
      const result = await apiRequests.stopArticle(data);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("stopArticle", timestamp);
    }
  },

  /**
   * Get all users
   * @return {Promise<any>} - Promise containing the result
   */
  startArticle: async (data: any): Promise<any> => {
    const rootStore= useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("startArticle", false);
    try {
      const result = await apiRequests.startArticle(data);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("startArticle", timestamp);
    }
  },

  /**
   * Get all users
   * @return {Promise<any>} - Promise containing the result
   */
  stopAuction: async (data: any): Promise<any> => {
    const rootStore= useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("stopAuction", false);
    try {
      const result = await apiRequests.stopAuction(data);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("stopAuction", timestamp);
    }
  },

  /**
   * Get all users
   * @return {Promise<any>} - Promise containing the result
   */
  startAuction: async (auctionId: number): Promise<any> => {
    const rootStore= useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("startAuction", false);
    try {
      const result = await apiRequests.startAuction(auctionId);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("startAuction", timestamp);
    }
  },

  /**
   * Get all users
   * @return {Promise<any>} - Promise containing the result
   */
  getFiles: async (userId: number): Promise<any> => {
    const rootStore= useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("getFiles", false);
    try {
      const result = await apiRequests.getFiles(userId);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("stargetFilestAuction", timestamp);
    }
  },

  /**
   * Get all users
   * @return {Promise<any>} - Promise containing the result
   */
  getFilesBackup: async (): Promise<any> => {
    const rootStore= useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("getFilesBackup", false);
    try {
      const result = await apiRequests.getFilesBackup();
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("getFilesBackup", timestamp);
    }
  },

  /**
   * Get all users
   * @return {Promise<any>} - Promise containing the result
   */
  getFile: async (userId: number, filename: string): Promise<any> => {
    const rootStore= useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("getFile", false);
    try {
      const result = await apiRequests.getFile(userId, filename);
      return result.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("getFile", timestamp);
    }
  },

  /**
   * Get all users
   * @return {Promise<any>} - Promise containing the result
   */
  getAdminFile: async (userId: number, filename: string, dir: string): Promise<any> => {
    const rootStore= useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("getAdminFile", false);
    try {
      const result = await apiRequests.getAdminFile(userId, filename, dir);
      return result.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("getAdminFile", timestamp);
    }
  },

  /**
   * Get all users
   * @return {Promise<any>} - Promise containing the result
   */
  exportFiles: async (type: any): Promise<any> => {
    const rootStore= useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("exportFiles", false);
    try {
      const result = await apiRequests.exportFiles(type);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("exportFiles", timestamp);
    }
  },

  /**
   * Get all users
   * @return {Promise<any>} - Promise containing the result
   */
  importAdminFiles: async (type: string, file: any): Promise<any> => {
    const rootStore= useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("importAdminFiles", false);
    try {
      const result = await apiRequests.importAdminFiles(type, file);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("importAdminFiles", timestamp);
    }
  },

  /**
   * Get all users
   * @return {Promise<any>} - Promise containing the result
   */
  restoreAdminFile: async (filename: string): Promise<any> => {
    const rootStore= useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("restoreAdminFile", false);
    try {
      const result = await apiRequests.restoreAdminFile(filename);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("restoreAdminFile", timestamp);
    }
  },

  /**
   * Get all users
   * @return {Promise<any>} - Promise containing the result
   */
  removeFile: async (userId: number, filename: string): Promise<any> => {
    const rootStore= useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("removeFile", false);
    try {
      const result = await apiRequests.removeFile(userId, filename);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("removeFile", timestamp);
    }
  },

  /**
   * Get all users
   * @return {Promise<any>} - Promise containing the result
   */
  removeAdminFile: async (userId: number, filename: string, dir: string): Promise<any> => {
    const rootStore= useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("removeAdminFile", false);
    try {
      const result = await apiRequests.removeAdminFile(userId, filename, dir);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("removeAdminFile", timestamp);
    }
  },

  /**
   * Get all users
   * @return {Promise<any>} - Promise containing the result
   */
  disconnectUser: async (userId: number, block: boolean): Promise<any> => {
    const rootStore= useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("disconnectUser", false);
    try {
      const result = await apiRequests.disconnectUser(userId, block);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("disconnectUser", timestamp);
    }
  },

  /**
   * Get all users
   * @return {Promise<any>} - Promise containing the result
   */
  loginWithFacebook: async (): Promise<any> => {
    const rootStore= useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("loginWithFacebook", false);
    try {
      const result = await apiRequests.loginWithFacebook();
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("loginWithFacebook", timestamp);
    }
  },

  /**
   * Get all users
   * @return {Promise<any>} - Promise containing the result
   */
  signIn: async (email: string, password: string): Promise<any> => {
    const rootStore= useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("signIn", false);
    try {
      const result = await apiRequests.signIn(email, password);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("signIn", timestamp);
    }
  },

  /**
   * Get all users
   * @return {Promise<any>} - Promise containing the result
   */
  getArticleFiles: async (auctionId: number, articleId: number): Promise<any> => {
    const rootStore= useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("getArticleFiles", false);
    try {
      const result = await apiRequests.getArticleFiles(auctionId, articleId);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("getArticleFiles", timestamp);
    }
  },

  /**
   * Get all users
   * @return {Promise<any>} - Promise containing the result
   */
  uploadArticleFiles: async (data: any): Promise<any> => {
    const rootStore= useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("uploadArticleFiles", false);
    try {
      const result = await apiRequests.uploadArticleFiles(data);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("uploadArticleFiles", timestamp);
    }
  },

  /**
   * Get all users
   * @return {Promise<any>} - Promise containing the result
   */
  removeArticleFiles: async (auctionId: number, articleId: number): Promise<any> => {
    const rootStore= useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("removeArticleFiles", false);
    try {
      const result = await apiRequests.removeArticleFiles(auctionId, articleId);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("removeArticleFiles", timestamp);
    }
  },

  /**
   * Get all users
   * @return {Promise<any>} - Promise containing the result
   */
  getSingleImage: async (auctionId: number, articleId: number, imageIndex: number): Promise<any> => {
    const rootStore= useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("getSingleImage", false);
    try {
      const result = await apiRequests.getSingleImage(auctionId, articleId, imageIndex);
      return result.data.data;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("getSingleImage", timestamp);
    }
  },

  /**
   * Get all users
   * @return {Promise<any>} - Promise containing the result
   */
  exportUsers: async (filename: string, auctionCode?: number): Promise<any> => {
    const rootStore= useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("exportUsers", false);
    try {
      const result = await apiRequests.exportUsers(filename, auctionCode);
      return result;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("exportUsers", timestamp);
    }
  },

  /**
   * Get all users
   * @return {Promise<any>} - Promise containing the result
   */
  exportCommissionBids: async (filename: string, auctionCode: number): Promise<any> => {
    const rootStore= useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("exportCommissionBids", false);
    try {
      const result = await apiRequests.exportCommissionBids(filename, auctionCode);
      return result;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("exportCommissionBids", timestamp);
    }
  },

  /**
   * Get all users
   * @return {Promise<any>} - Promise containing the result
   */
  exportKnockdowns: async (filename: string, auctionCode: number): Promise<any> => {
    const rootStore= useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("exportKnockdowns", false);
    try {
      const result = await apiRequests.exportKnockdowns(filename, auctionCode);
      return result;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("exportKnockdowns", timestamp);
    }
  },

  /**
   * Get all users
   * @return {Promise<any>} - Promise containing the result
   */
  validateTfa: async (email: string, tfaToken: number): Promise<any> => {
    const rootStore= useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("validateTfa", false);
    try {
      const result = await apiRequests.validateTfa(email, tfaToken);
      return result;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("validateTfa", timestamp);
    }
  },

  /**
   * Get all users
   * @return {Promise<any>} - Promise containing the result
   */
  getEmailsStatus: async (): Promise<any> => {
    const rootStore= useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("getEmailsStatus", false);
    try {
      const result = await apiRequests.getEmailsStatus();
      return result;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("getEmailsStatus", timestamp);
    }
  },

  /**
   * Get all users
   * @return {Promise<any>} - Promise containing the result
   */
  getUserData: async (userId: number): Promise<any> => {
    const rootStore= useRootStore();
    const timestamp = rootStore.ADD_API_LOADING_ENTRY("getUserData", false);
    try {
      const result = await apiRequests.getUserData(userId);
      return result;
    } finally {
      rootStore.REMOVE_API_LOADING_ENTRY("getUserData", timestamp);
    }
  }
}

export default dispatcher;
